import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DeprecatedDeviceMessage } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';

class DeprecatedDevice extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <DeprecatedDeviceMessage
                message={"The DStv App is no longer supported on this device as it does not meet the technical requirements to run the app. For compatible devices, go to dstv.com"}
            />
        </React.Fragment>
    }
}

export default connect(null, null)(DeprecatedDevice);