import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LOGIN, ENTITLEMENT } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { TvLogin, TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import Pusher from '../../libs/pusher'
import { setTokenAndJWT } from '@connected-video-web/dstv-frontend-services/dist/Utils/authHelper'
import { PUSHER_KEY } from '@connected-video-web/dstv-frontend-services/dist/constants/environment';
import { GLOBALS } from '../../globals'
import { SMART_TV_APP_VERSION } from '@connected-video-web/dstv-frontend-services/dist/actions/index';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../helper';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { getDeviceName } from '@connected-video-web/dstv-frontend-services/dist/Utils/platform';
import SessionHelper from '@connected-video-web/dstv-frontend-services/dist/Utils/sessionHelper'
var ANDROID_JAVASCRIPT_INTERFACE = "android_javascript_interface"

let productType = localStorage.getItem('PRODUCT_TYPE')
class Login extends Component {
  constructor(props) {
    super(props);
    this.payload = {
      deviceId: localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY),
      appVersion: SMART_TV_APP_VERSION,
      userAgent: navigator.userAgent,
    }
    this.state = {
      showActivation: false,
      code: ''
    }
  }

  componentDidMount() {
    var self = this
    this.props.getLoginLabels()
    setTimeout(() => {
      self.getActivationCode()
      self.setState({ ...self.state, showActivation: true })
    }, GLOBALS.ACTIVATE_TIMER)
    this.startSocket()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.login && nextProps.login.error && nextProps.login.serverError) {
      postAPIErrorToSegment(nextProps.login.error, nextProps.login.serverError)
    }
  }
  componentWillUnmount() {
    if (this.pusher) {
      this.pusher.disconnect()
    }
  }

  getActivationCode = () => {
    if (internetIsConnected()) {
      this.props.getLoginCode(this.payload)
    } else {
      this.props.throwNetworkError(
        networkPayload({
          callback: () => { this.getActivationCode() },
          toggleText: this.props.login.isForgotPin ? false : true
        }))
    }

  }
  startSocket = () => {
    var self = this
    Pusher.logToConsole = false

    var pusher = new Pusher(PUSHER_KEY, {
      cluster: 'eu',
      encrypted: true
    })
    this.channel = pusher.subscribe(localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY))
    this.channel.bind('login-success', function (data) {
      self.loginSuccess(data)
    })
    this.channel.bind('login-error', function (data) {
      self.loginError(data)
    })

    this.channel.bind('login-update-usercode', function (data) {
      console.log('login-update-usercode: ' + data.message)
    })

    pusher.connection.bind('unavailable', function () {
      console.log('pusher unavailable')
    })

    pusher.connection.bind('connected', function (data) {
      console.log('pusher connected: ' + data.message)
    })

    pusher.connection.bind('error', function (err) {
      if (err.error.data) {
        console.error('Error: ' + err.error.data.message)

        // var callback = function () {
        //   self.pusher.connect()
        // }
        return (
          <TvError {...this.props.login.error} retryAction={{ type: LOGIN }} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
        )
      }
    })
    this.pusher = pusher
  }

  loginSuccess = (data) => {
    setTokenAndJWT(data)
    if(window.isAndroidTV){
      let androidUUID = localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY);
      localStorage.setItem(GLOBALS.DEVICE_ID_STORAGE_KEY, SessionHelper.GenerateDeviceId(androidUUID));  
    }
        
    this.props.history.push('/')
    this.props.connectLogin()
    if (window.isExplora)
      this.props.DoEntitlement({ 'deviceId': localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY) })

    //Android TV native 
    let loginData = JSON.stringify(data);
    if (window[ANDROID_JAVASCRIPT_INTERFACE]) {
      window[ANDROID_JAVASCRIPT_INTERFACE].onLoginSuccess(loginData);
    } else {
      console.error("Android TV native app interface not present.");
    }
  }

  loginError = (data) => {
    if (this.pusher) {
      this.pusher.unsubscribe(localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY))
      this.pusher.disconnect()
    }
  }


  onErrorActionSelected = (e) => {
    this.componentDidMount()
  }

  onErrorBackSelected = (e) => {
    this.props.clearError()
  }
  render() {

    let data = (this.props.login && this.props.login.data && this.props.login.data.length) ? this.props.login.data : [];
    let error
    if (this.props.login && this.props.login.error) {
      error = <TvError {...this.props.login.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} isLoginError={this.props.login.error ? true : false} />
    }
    return <React.Fragment>
      <TvLogin
        data={data}
        isLoading={this.props.login.isLoadingLogin}
        showActivation={this.state.showActivation}
        fromPinScreen={this.props.login.isForgotPin || this.props.login.isloggedOut}
        isError={this.props.login.error ? true : false}
        isExit={this.props.login.isExit ? true : false}
        isStreama={productType === 'STREAMA' ? true : false}
      />
      {error}
    </React.Fragment>
  }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    login: state.login
  }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  getLoginLabels: () => dispatch({ type: 'LOGIN_OPEN' }),
  getLoginCode: (payload) => dispatch({ type: LOGIN, payload }),
  connectLogin: (payload) => dispatch({ type: 'CONNECT', payload }),
  clearError: () => dispatch({ type: 'LOGIN_ERROR_CLEAR' }),
  throwNetworkError: (payload) => dispatch({ type: 'LOGIN_ERROR', payload }),
  DoEntitlement: (payload) => dispatch({ type: ENTITLEMENT, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);