import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GET_AVATARS_LIST, AVATAR_FOCUS, AVATAR_CLEAR, EDIT_PROFILE, AVATAR_CLICK, PROFILES_ERROR_CLEAR, GET_AVATARS_LIST_ERROR } from '@connected-video-web/dstv-frontend-services/dist/actions'
import TvProfileAvatarList from '@connected-video-web/dstv-frontend-components/dist/Components/Pages/TvProfiles/TvProfileAvatarList';
import { KeyCode } from '../../../keyCodeMapping';
import { styledEditorial } from '../../styledComponents'
import { profile } from '@connected-video-web/dstv-frontend-services/dist/constants/resource'
import * as helper from './helper'
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { scroll } from '../../../events';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../../helper';
import { exitApplication } from '@connected-video-web/dstv-frontend-services/dist/Utils/platform';
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { setCurrentFocusedComponent } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { pageType } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import dstvLogo from '@connected-video-web/dstv-frontend-components/dist/Assets/Images/dstv-logo.svg';

class ProfileAvatarList extends Component {
    constructor(props) {
        super(props)
        let path = this.props.history.location.pathname.split('/')
        setCurrentFocusedComponent({ key: pageType.avatar })
        this.state = {
            titleFocused: false,
            saveFocused: false,
            deleteFocused: false,
            isDefault: window.profileHistory && window.profileHistory[0].isDefault || false,
            isNewProfile: path[2] === 'new',
            selectedAvatar: window.profileHistory && window.profileHistory[0].avatar && window.profileHistory[0].avatar || '',
            prevState: {}
        }
        window.addEventListener('keydown', this.navigation);
        window.addEventListener('wheel', this.scroll);
    }

    componentWillMount() {
        this.getAvatarList(window.profileHistory && window.profileHistory[0])
    }

    getAvatarList = (payload) => {
        if (internetIsConnected()) {
            this.props.getAvatars(window.profileHistory && window.profileHistory[0])
        } else {
            this.props.throwNetworkError(
                networkPayload({
                    callback: () => { this.getAvatarList(payload) },
                    toggleText: payload && payload.isNewProfile && !payload.addButtonClicked
                }))
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.navigation);
        window.removeEventListener('wheel', this.scroll);
        this.props.clearAvatar();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.profiles && nextProps.profiles.saveProfileerror && (nextProps.profiles.serverError)) {
            postAPIErrorToSegment(nextProps.profiles.error, { errorMessage: nextProps.profiles.serverError })
        }
        if (nextProps.profiles.profileSaved) {
            helper.pushProfileListScreen(this.props.history)
        }
        if (!nextProps.avatars.data && !nextProps.avatars.error && !nextProps.avatars.isLoading) {
            if (window.profileHistory[0].isNewProfile && !window.profileHistory[0].addButtonClicked)
                exitApplication()
            else {
                helper.pushProfileListScreen(this.props.history)
            }
        }
        if (!this.props.avatars.error && nextProps.avatars.error) {
            this.setState({
                ...this.state,
                prevState: (this.props.avatars.data && this.state || {}),
                titleFocused: false,
                saveFocused: false,
                deleteFocused: false,
            })
        } else
            if ((helper.checkProfileErrors(nextProps) && !helper.checkProfileErrors(this.props))) {
                let state = { ...this.state }
                this.props.focusAvatar('DOWN')
                this.setState({
                    ...this.state,
                    prevState: state,
                    titleFocused: false,
                    saveFocused: false,
                    deleteFocused: false,
                })
            }
    }

    scroll = (e) => {
        scroll(this.navigation)(e)
    }

    navigation = (e) => {
        if (this.checkFocusRemoved()) {
            this.setState({ ...this.state.prevState })
        } else
            if (!helper.checkProfileErrors(this.props) && !this.props.avatars.error) {
                if (!this.state.isNewProfile) {
                    switch (KeyCode(e.keyCode)) {
                        case 'UP':
                            this.moveFocusUp()
                            break;
                        case 'DOWN':
                            this.moveFocusDown()
                            break;
                        case 'SELECT':
                            this.onSelect()
                            break;
                        case 'BACK':
                            this.onBack()
                    }
                } else if (KeyCode(e.keyCode) === 'SELECT') {
                    this.onSelect()
                } else if (KeyCode(e.keyCode) === 'BACK') {
                    this.onBack()
                }
            } else {
                switch (KeyCode(e.keyCode)) {
                    case 'SELECT':
                        this.errorOnSelect()
                        break
                    case 'BACK':
                        this.errorOnBack()
                        break;
                }
            }
    }

    checkFocusRemoved = () => {
        return (!this.props.avatars.error &&
            !this.props.profiles.saveProfileError &&
            !this.props.profiles.editProfileError &&
            !this.props.profiles.error &&
            !this.state.titleFocused &&
            !this.state.saveFocused &&
            !this.state.deleteFocused &&
            (this.props.avatars.data && this.props.avatars.data[0] && !this.props.avatars.data[0].isTvFocus) &&
            this.state.prevState.saveFocused !== undefined)
    }

    moveFocusUp = () => {
        if (this.state.saveFocused) {
            this.setState({ ...this.state, titleFocused: true, deleteFocused: false, saveFocused: false })
        } else if (this.state.titleFocused) {
            this.setState({ ...this.state, titleFocused: false })
            this.props.focusAvatar('UP')
        } else if (!this.state.isDefault && this.state.deleteFocused) {
            this.setState({ ...this.state, saveFocused: true, deleteFocused: false })
        }
    }

    moveFocusDown = () => {
        if (this.checkAvatarCarouselFocused()) {
            this.setState({ ...this.state, titleFocused: true })
        } else if (this.state.titleFocused) {
            this.setState({ ...this.state, titleFocused: false, saveFocused: true })
        } else if (!this.state.isDefault && this.state.saveFocused){
            this.setState({ ...this.state, saveFocused: false, deleteFocused: true })
        }
    }

    onAvatarClick = (item) => {
        this.props.clickAvatar({ ...item, isNewProfile: this.state.isNewProfile })
        if (this.state.isNewProfile) {
            this.onSelect()
        } else {
            this.setState({ ...this.state, titleFocused: true })
        }
    }

    onNameClick = (item) => {
        this.props.focusAvatar('DOWN')
        this.setState({ ...this.state, titleFocused: true }, this.onSelect)
    }

    onActionClick = (item) => {
        switch (item.type) {
            case 'SAVE':
                this.props.focusAvatar('DOWN')
                this.setState({ ...this.state, saveFocused: true }, this.onSelect)
                break
            case 'DELETE':
                this.setState({ ...this.state, deleteFocused: true }, this.onSelect)
                break
        }
    }

    onSelect = () => {
        let payload = {
            ...(window.profileHistory && window.profileHistory[0] || {}),
            title: (window.profileHistory && window.profileHistory[0]) ? window.profileHistory[0].title : '',
            avatar: this.state.selectedAvatar,
            isDefault: this.state.isDefault,
            isNewProfile: this.state.isNewProfile
        }
        if (this.checkAvatarCarouselFocused()) {
            if (this.state.isNewProfile) {
                helper.pushProfileNameScreen(this.props.history, { ...payload, avatar: this.getSelectedAvatar() })
            } else {
                this.setState({ ...this.state, titleFocused: true, selectedAvatar: this.getSelectedAvatar() })
                this.props.focusAvatar('DOWN')
            }
        } else if (this.state.titleFocused) {
            helper.pushProfileNameScreen(this.props.history, payload)
        } else if (this.state.saveFocused && !this.props.profiles.savingProfileEdits) {
            this.setState({ ...this.state, titleFocused: false, selectedAvatar: this.getSelectedAvatar() })
            this.updateProfileDetails({
                alias: window.profileHistory[0].title,
                avatarId: this.state.selectedAvatar.id,
                profileId: window.profileHistory[0].id
            })
        } else if (this.state.deleteFocused) {
            postSegmentData(profile.profileConfirm.actionButton.delete[1], profile.profileAvatar.title.edit.delete)
            helper.pushDeleteScreen(this.props.history)
        }
    }

    updateProfileDetails = (payload) => {
        postSegmentData(profile.profileConfirm.actionButton.save[0], profile.profileAvatar.title.edit.save)
        if (internetIsConnected()) {
            this.props.updateProfile(payload)
        } else {
            this.props.throwNetworkError(
                networkPayload({
                    callback: () => { this.updateProfileDetails(payload) }
                }))
        }
    }

    onBack = () => {
        if (this.props.avatars.data && !(this.props.avatars.data[0].isTvFocus || this.state.titleFocused || this.state.saveFocused || this.state.deleteFocused)) {
            this.setState({ ...this.state.prevState })
        } else {
            if (!this.state.isDefault && !this.state.isNewProfile) { //non default edit
                helper.pushProfileListScreen(this.props.history, this.getSelectedAvatar())
            } else if (this.props.avatars.data || this.props.avatars.isLoading) {
                this.props.history.goBack()
            }
        }
    }

    getSelectedAvatar = () => {
        if (this.props.avatars.data &&
            this.props.avatars.data[0] &&
            this.props.avatars.data[0].items &&
            this.props.avatars.data[0].items.length) {
            return this.props.avatars.data[0].items.filter(item => item.isTvFocus).pop()
        }
        return {}
    }

    checkAvatarCarouselFocused = () => {
        return (!this.state.titleFocused &&
            !this.state.deleteFocused &&
            !this.state.saveFocused &&
            this.props.avatars.data &&
            this.props.avatars.data[0] &&
            this.props.avatars.data[0].items &&
            this.props.avatars.data[0].items.length)
    }

    getTitle = (title) => {
        if (this.state.isNewProfile || this.state.prevState.isNewProfile) return title.new.avatar
        if (this.state.titleFocused || this.state.prevState.titleFocused) return title.edit.name
        if (this.state.saveFocused || this.state.prevState.saveFocused) return title.edit.save
        if (this.state.deleteFocused || this.state.prevState.deleteFocused) return title.edit.delete
        return title.edit.avatar
    }
    errorOnSelect = () => {
        if (((this.props.profiles.saveProfileError && this.props.profiles.saveProfileError.isVisible) ||
            (this.props.profiles.editProfileError && this.props.profiles.editProfileError.isVisible)) &&
            helper.checkGenericError(this.props)) {
            this.props.hideGenericError()
            let error = this.props.profiles.saveProfileError || this.props.profiles.editProfileError
            if (error.key === 'NAME') {
                helper.pushProfileNameScreen(this.props.history, window.profileHistory[0])
            } else if (error.key === 'AVATAR') {
                this.props.focusAvatar('UP')
            } else {
                helper.pushProfileListScreen(this.props.history, window.profileHistory[0])
            }
        }
    }
    errorOnBack = () => {
        if (((this.props.profiles.saveProfileError && this.props.profiles.saveProfileError.isVisible) ||
            (this.props.profiles.editProfileError && this.props.profiles.editProfileError.isVisible)) &&
            helper.checkGenericError(this.props)) {
            this.props.hideGenericError()
            this.setState({ ...this.state.prevState })
        }
    }
    render() {
        let data = {}
        if (this.props.avatars.data) {
            data = this.props.avatars.data[0]
        }
        let path = this.props.location.pathname.split('/')
        let profileTitle = {
            value: window.profileHistory && window.profileHistory[0].title || '',
            isTvFocus: this.state.titleFocused
        }

        let actionButtons = [
            { value: profile.profileAvatar.actionButton[0], isTvFocus: this.state.saveFocused, type: profile.profileAvatar.actionButton[0].split(' ')[0].toUpperCase() },
            { value: profile.profileAvatar.actionButton[1], isTvFocus: this.state.deleteFocused, type: profile.profileAvatar.actionButton[1].split(' ')[0].toUpperCase() }
        ]
        let title = this.getTitle(profile.profileAvatar.title)

        let error = ''
        if (this.props.profiles.saveProfileError ||
            this.props.profiles.editProfileError ||
            (this.props.avatars.error && this.props.avatars.error.isVisible)) {
            error = <TvError {...this.props.avatars.error || this.props.profiles.saveProfileError || this.props.profiles.editProfileError} onSelected={[this.errorOnSelect, this.errorOnSelect]} />
        }
        return (<React.Fragment>
            <TvProfileAvatarList
                {...data}
                title={title}
                pageType={path[2]}
                shouldScroll={true}
                styledCarousel={styledEditorial}
                isDefault={this.state.isDefault}
                isNewProfile={this.state.isNewProfile} //change it to props or path
                profileTitle={profileTitle}
                actionButtons={this.state.isDefault ? [actionButtons[0]] : actionButtons}
                isLoading={this.props.profiles.savingProfileEdits || this.props.avatars.isLoading}
                onAvatarClick={this.onAvatarClick}
                onNameClick={this.onNameClick}
                onActionClick={this.onActionClick}
                logo={dstvLogo}
            />
            {error}
        </React.Fragment>)
    }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        avatars: state.avatars,
        profiles: state.profiles
    }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    getAvatars: (payload) => dispatch({ type: GET_AVATARS_LIST, payload }),
    focusAvatar: (move) => dispatch({ type: AVATAR_FOCUS, move }),
    clearAvatar: (move) => dispatch({ type: AVATAR_CLEAR, move }),
    updateProfile: (payload) => dispatch({ type: EDIT_PROFILE, payload }),
    clickAvatar: (payload) => dispatch({ type: AVATAR_CLICK, payload }),
    hideGenericError: () => dispatch({ type: PROFILES_ERROR_CLEAR }),
    throwNetworkError: (payload) => dispatch({ type: GET_AVATARS_LIST_ERROR, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAvatarList);