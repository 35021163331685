import React from 'react';
import { connect } from "react-redux";
import { TvCatchup, TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { myList } from '@connected-video-web/dstv-frontend-services/dist/constants/resource'
import { SHOWPAGE_SHOW, WATCH_LIST, WATCHLIST_ERROR_CLEAR, WATCH_LIST_CLEAR, MENU_FOCUS, WATCH_LIST_ERROR, STREAMA_BILLBOARD, CLEAR_STREAMA_BILLBOARD } from '@connected-video-web/dstv-frontend-services/dist/actions';
import { StreamaBillboard } from '@connected-video-web/dstv-frontend-components/dist/Components/Container';
import { getFocusedIndex, focusKey } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../helper';

class WatchList extends React.Component {

    componentWillMount() {
        this.getWatchList()
    }

    componentWillUnmount() {
        this.props.clearWatchList()
    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.watchlist && nextProps.watchlist.data && nextProps.watchlist.data.length && nextProps.watchlist.data[0]){
            const focusedIndex = getFocusedIndex(nextProps.watchlist.data[0].items, focusKey.isTvFocus);
            if(focusedIndex !== -1){
                const focusedCard = nextProps.watchlist.data[0].items[focusedIndex];
                this.props.streamaBillboard(focusedCard);
            } else {
                this.props.clearStreamaBillboard();
            }
        } else {
            this.props.clearStreamaBillboard();
        }

    }

    componentDidUpdate() {
        if ((this.props.watchlist.data && !this.props.watchlist.data.length) &&
            !this.props.isLoading &&
            !this.props.isError &&
            !this.props.menu.data.filter(item => item.isTvFocus).pop()) {
            this.props.menuFocus({ move: 'UP' })
        }
    }

    getWatchList = () => {
        if (internetIsConnected()) {
            this.props.getWatchList({ pageNo: 0 })
        } else {
            this.props.throwNetworkError({
                error:
                    networkPayload({
                        callback: () => { this.getWatchList() }
                    })
            })
        }
    }

    onCardSelected = (item) => {
        if (internetIsConnected()) {
            this.props.history.push({
                pathname: item.linkTo,
                payload: item
            });
            this.props.pushShowPage(item)
        } else {
            this.props.throwNetworkError({
                error:
                    networkPayload({
                        callback: () => { this.onCardSelected(item) },
                        isCardSelected: true
                    })
            })
        }
    }

    onErrorActionSelected = (item) => {
        if (item.callback) {
            let type = item.callback(internetIsConnected())
            if (type) this.props.retryWatchList(type)
        }
        else this.props.retryWatchList(item.retryAction)
    }

    onErrorBackSelected = () => {
        this.props.clearWatchListError(this.props.watchlist.error)
    }

    render = () => {
        let data = this.props.watchlist.data || []
        let error = ''
        if (this.props.watchlist.error && this.props.watchlist.error.isVisible)
            error = <TvError {...this.props.watchlist.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
        let isTvFocus = (data[0] && data[0].isTvFocus) || (!this.props.watchlist.isLoading && !this.props.watchlist.error && !data.length)
        
        return (
            <React.Fragment >
                <StreamaBillboard
                    isCatchupBillboard={true}
                    isPageContentTvFocus={isTvFocus}
                    showBillboard={true}
                />
                <TvCatchup
                    data={data}
                    isWatchList={true}
                    title={myList.title}
                    noContent={!this.props.watchlist.error && !this.props.watchlist.isAborted && myList.noContent}
                    isTvFocus={isTvFocus}
                    onCardSelected={this.onCardSelected}
                    isLoading={this.props.watchlist.isLoading} />
                {error}
            </React.Fragment >
        )
    }
}

const mapStateToProps = state => ({
    watchlist: state.watchlist,
    menu: state.menu
});

const mapDispatchToProps = dispatch => ({
    getWatchList: (payload) => dispatch({ type: WATCH_LIST, payload }),
    pushShowPage: (payload) => dispatch({ type: SHOWPAGE_SHOW, payload }),
    retryWatchList: (action) => dispatch(action),
    clearWatchListError: (payload) => dispatch({ type: WATCHLIST_ERROR_CLEAR, payload }),
    clearWatchList: () => dispatch({ type: WATCH_LIST_CLEAR }),
    menuFocus: (payload) => dispatch({ type: MENU_FOCUS, payload }),
    throwNetworkError: (payload) => dispatch({ type: WATCH_LIST_ERROR, payload }),
    streamaBillboard: (payload) => dispatch({ type: STREAMA_BILLBOARD, payload }),
    clearStreamaBillboard: () => dispatch({ type: CLEAR_STREAMA_BILLBOARD })
})

export default connect(mapStateToProps, mapDispatchToProps)(WatchList);
