import React, { Component } from "react";
import { SHOW_APP_RELOAD_MODAL } from "@connected-video-web/dstv-frontend-services/dist/constants/actionTypes";
import { connect } from "react-redux";
import { TvError } from "@connected-video-web/dstv-frontend-components/dist/Components/Pages/TvError";
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { withRouter } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.prevStateUpdate = null
  }

  componentDidUpdate(prevProps, prevState) {
    this.prevStateUpdate = prevProps
    if (prevState.error !== this.state.error) {
      
      postAPIErrorToSegment(
        {
          mainHeader: "Error caught by Error Boundary",
          subHeader: this.state.errorInfo.componentStack,
        },
        { errorMessage: "Error caught by Error Boundary" }
      );

      this.props.showAppReload();
    }
    if (prevProps.common.reload !== this.props.common.reload && this.props.common.reload) {
      this.props.clearShowpage();
      this.props.hideReload();
      this.props.history.push({
        pathname: '/profiles'
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.location.pathname !== '/profiles') {
      this.setState({
        error: error,
        errorInfo: errorInfo,
      });
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.    
    return { hasError: true };
  }

  onErrorActionSelected = () => {
    window.location.reload();
  };

  render() {
    if (this.props.common.error) {
      return (
        <div>
          <TvError
            {...this.props.common.error}
            onSelected={[this.onErrorActionSelected]}
          />
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const mapStateToProps = state => {
  return {
    common: state.common,
    showpage: state.showpage,
  }
};
const mapDispatchToProps = (dispatch) => ({
  showAppReload: () => dispatch({ type: SHOW_APP_RELOAD_MODAL }),
  hideReload: () => dispatch({ type: "HIDE_RELOAD" }),
  clearShowpage: () => dispatch({ type: 'SHOWPAGE_CLEAR_COMMON' }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary));