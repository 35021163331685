import React from 'react';
import { connect } from "react-redux";
import moment from "moment";
/**
 * Actions
 * Plugin: dstv-frontend-service.
 */
import {
    SECTIONS_LIVE, CHANNELS, CHANNELS_CLEAR, PLAY_CHANNEL, OPEN_PLAYER,
    SCHEDULER, SHOW_EXTENDED_INFO, SECTIONS_ERROR, SECTIONS_ERROR_CLEAR,
    CHANNELS_ERROR_CLEAR, SECTION_FOCUS, CHANNELS_ERROR, CHANNELS_ABORT,
    UPDATE_PROGRESS_BAR, RECORD_CHANNEL_POSITION, GET_EVENTS, STREAMA_BILLBOARD, CLEAR_STREAMA_BILLBOARD, CHANNELS_FOCUS
} from "@connected-video-web/dstv-frontend-services/dist/actions";
import StreamHelper from '@connected-video-web/dstv-frontend-services/dist/Utils/streamHelper';
import { clearScheduler } from '@connected-video-web/dstv-frontend-services/dist/Utils/schedulerHelper';
import { KeyCode } from '../../keyCodeMapping';
import { pageType } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';
import { ErrorList } from '@connected-video-web/dstv-frontend-services/dist/constants/errorMessages';
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { GLOBALS } from '../../globals'
import { clearTimer, checkSectionDataPresent, getFocusedMenuId } from '../helper';
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';


/**
 * UI Component
 * Plugin: dstv-frontend-components.
 * Type: Container.
 */
import { TvChannels } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { styledEditorial } from '../styledComponents';
import { ExtendedInfo, StreamaBillboard } from '@connected-video-web/dstv-frontend-components/dist/Components/Container';
import { getFocusedIndex, focusKey } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../helper';
import { getChannelEvents, getChannelShowTimes } from '@connected-video-web/dstv-frontend-services/dist/Utils/channelsHelper';
class Channel extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.onSelected = this.onSelected.bind(this);
        this.channelTags = []
        this.pageTimer = null;
        this.state = {
            hoursMode: 24,
        };
    }

    componentWillMount() {
        //only on first time loading
         document.addEventListener("keydown", this.onSelected, false);

        if ((this.props.sections.data_livetv && !this.props.sections.data_livetv.isLoading) &&
            (!this.props.sections.data_livetv ||
                (this.props.sections.data_livetv && !this.props.sections.data_livetv.data) ||
                (this.props.sections.data_livetv && this.props.sections.data_livetv.data && this.props.sections.data_livetv.data[0] && this.props.sections.data_livetv.data[0].items && !this.props.sections.data_livetv.data[0].items.length))) {
            this.throwSectionError(this.props)
        }
        else if (checkSectionDataPresent(this.props, 'data_livetv')) {
             let category = this.props.location.pathname.split("/").pop();
             this.getSelectedSectionChannels(category);
        }
    }

    throwSectionError = (props) => {
        let category = this.props.location.pathname.split("/").pop();
        this.props.throwSectionError({
            ...props.sections.data_livetv.error,
            retryAction: {
                type: SECTIONS_LIVE,
                payload: {
                    filter: { categoryId: category }
                }
            }
        })
    }

    shouldRefreshChannel = (props) => {
        return (!props.channels.data && !props.channels.isLoading && !props.channels.isAborted)
    }

    getProgressBar() {
        this.timer = setInterval(() => {
            this.props.updateProgressBar()
        }, GLOBALS.UPDATE_PROGRESS_BAR_INTERVAL)
    }

    componentWillUnmount = () => {
        // need to be changed once Iplate integrated
        document.removeEventListener('keydown', this.onSelected);

        if (
            this.props.channels &&
            this.props.channels.data &&
            this.props.channels.data.length
        ) {
            this.props.abortChannel();
        }

        clearScheduler();
        clearTimer(this.timer)
        clearTimeout(this.pageTimer);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            clearTimeout(this.pageTimer);
            this.pageTimer = setTimeout(() => {
                let category = nextProps.location.pathname.split("/").pop();
                this.getSelectedSectionChannels(category);
                this.setState({ hoursMode: 24 })
            }, 600)
        }

        try {
            const row = (nextProps.channels.focusEvent[0] === -1) ? 0 : nextProps.channels.focusEvent[0];
            const col = (nextProps.channels.focusEvent[1] === -1) ? 0 : nextProps.channels.focusEvent[1];
            const activeEvent = nextProps.channels.data[0].items[row].events[col];
            this.props.streamaBillboard(activeEvent);
        } catch (e) {
            this.props.clearStreamaBillboard();
        }
    }

    callDayEvents = () => {
        if (this.props.channels && this.props.channels.data && this.props.channels.data[0] && this.props.channels.data[0].items && this.props.channels.data[0].items.length) {
            const visibleItems = this.props.channels.data[0].items.filter(item => item.isVisible);
            visibleItems.forEach((item, i) => {
                if (!item.events) {
                    this.props.getEvents({
                        channelTag: item.channelTag,
                        date: moment(),
                        append: false,
                        index: i,
                    });
                }
            });
        }
    }

    callNextDayEvents = () => {
        if (this.props.channels && this.props.channels.data && this.props.channels.data[0] && this.props.channels.data[0].items && this.props.channels.data[0].items.length) {
            const visibleItems = this.props.channels.data[0].items.filter(item => item.isVisible);
            visibleItems.forEach((item, i) => {
                if (!item.append) {
                    this.props.getEvents({
                        channelTag: item.channelTag,
                        date: moment().add(1, "days"),
                        append: true,
                        index: i,
                    });
                }
            })
            this.setState({ hoursMode: 48 });
        }
    }

    refreshEvents = (refreshChannelTag) => {
        this.props.getEvents({
            channelTag: refreshChannelTag,
        });
    }

    componentDidUpdate = () => {
        const isFromPlayer = this.props.channels && this.props.channels.fromPlayer
        if (this.props.channelEvents && this.props.channelEvents.isLoaded === false && this.props.channels.isCalled) {
            this.callDayEvents();
        }
        if ((this.props.channels.data && this.props.channels.data[0] && this.props.channels.data[0].items && !this.props.channels.data[0].items.length && (this.props.channels.data[0].filter && this.props.channels.data[0].filter.forceFocus)) &&
            !this.props.channels.isLoading && this.checkMenuNotFocused() && !isFromPlayer) {
            this.props.sectionFocus({ page: "livetv" })
        } else if (getFocusedMenuId(this.props) === pageType.channels &&
            checkSectionDataPresent(this.props, 'data_livetv') && this.shouldRefreshChannel(this.props)) {
        }
        if (this.timer === null) {
            this.getProgressBar()
        }
    }

    componentDidCatch = (error, info) => {
        console.log(error, info, "!!!!!!!!!!! COMPONENT ERROR ********");
    }

    checkMenuNotFocused = () => {
        return (getFocusedIndex(this.props.sections.data_livetv.data[0].items, focusKey.isTvFocus) === -1 &&
            getFocusedIndex(this.props.menu.data, focusKey.isTvFocus) === -1)
    }

    getSelectedSectionChannels = (category, fromError = false) => {
        if (internetIsConnected()) {
            let selectedCategory;
            if (this.props.sections.data_livetv.data) {
                selectedCategory = this.props.sections.data_livetv.data[0].items.filter(item => {
                    let selectedSession = item.linkTo.split("/").pop();
                    return selectedSession === category;
                });
                if (selectedCategory.length === 0)
                    selectedCategory = this.props.sections.data_livetv.data[0].items.filter(item => {
                        return item.isActive;
                    });
            }
            let isOnPlayer = window.location.href.includes("/play/");
            const isFromPlayer = this.props.channels && this.props.channels.fromPlayer
            if ((!isFromPlayer) || (isFromPlayer && category === 'livetv' && selectedCategory[0].title === 'Recently watched channels')) {
                if ((category === 'All Channels' || category === 'livetv' || !isOnPlayer)) {
                    this.props.getChannel({
                        url: selectedCategory[0].url,
                        filter: {
                            genre: "ALL",
                            sortOrder: "ALL",
                            pageNo: 0,
                            title: selectedCategory[0].title,
                            forceFocus: this.checkMenuNotFocused()
                        },
                        from: (this.checkMenuNotFocused()) ? 'player' : 'menu'
                    }, this.props.scheduler);

                    if (isFromPlayer && category === 'livetv') {
                        let self = this
                        setTimeout(function () {
                            self.props.clearEvents();
                            self.callDayEvents();
                            self.props.setChannelsFocus()
                        }, 2000)
                    }
                } else {
                    this.props.clearEvents();
                    this.callDayEvents();
                }
            } else {
                this.props.clearEvents();
                this.callDayEvents();
            }
            if (isFromPlayer) {
                if (localStorage.getItem('PRODUCT_TYPE') === "XBOX") {
                    this.props.clearEvents();
                    this.callDayEvents();
                    this.props.history.push('/livetv')
                    this.props.sectionFocus({ page: "livetv" })
                } else {
                    this.props.clearEvents();
                    this.callDayEvents();
                    if (selectedCategory[0].title === 'Recently watched channels' &&
                        (this.props.channels.data && this.props.channels.data[0] && this.props.channels.data[0].items && this.props.channels.data[0].items.length === 0)) {
                        this.props.history.push('/livetv')
                    }
                }
            }
        } else {
            this.props.throwNetworkError({
                error:
                    networkPayload({
                        callback: () => { this.getSelectedSectionChannels(category, fromError) },
                        from: (this.checkMenuNotFocused()) ? 'player' : 'menu'
                    })
            })
        }
    }

    onClicked = (e) => {
        let clickedChannel = [];
        if (this.props.channels && this.props.channels.data && this.props.channels.data.length && this.props.channels.data[0].items && this.props.channels.data[0].items.length) {
            clickedChannel = this.props.channels.data[0].items.filter(item => e.channelTag);
        }

        if (e.eventIndex === 0 && clickedChannel.length > 0)
            this.onChannelCardSelected(clickedChannel[0]);
        else {
            // Extended info display logic
        }
    }

    onSelected = (e) => {
        switch (KeyCode(e.keyCode)) {
            case 'SELECT': {
                if (this.props.channels.focusEvent.length > 0) {
                    const row = this.props.channels.focusEvent[0];
                    const col = this.props.channels.focusEvent[1];
                    const activeEvent = this.props.channels && this.props.channels.data && this.props.channels.data[0] && this.props.channels.data[0].items[row] && this.props.channels.data[0].items[row].events && this.props.channels.data[0].items[row].events[col];
                    if (activeEvent && col === 0 && this.props.channels.data && this.props.channels.data[0] && this.props.channels.data[0].items[row].events) {
                        const activeChannel = this.props.channels.data[0].items[row];
                        this.onChannelCardSelected(activeChannel);
                    }
                }
                break;
            }
            default:
                break;
        }
    }

    onChannelCardSelected = (channel) => {
        let isOnPlayer = window.location.href.includes("/play/");
        if (!this.props.extendedInfo.eventType && !isOnPlayer) {
            let data = { ...channel }
            data.content_discovery_section = 'Live TV';
            data.content_discovery_category = channel.genre;
            data.key = 'channels';
            this.props.openPlayer({ type: PLAY_CHANNEL, payload: data });
            this.props.history.push({ pathname: StreamHelper.GetPayerUrl(pageType.channels, { id: channel.id }), state: { isFromCards: true } });
        }
        // NOTE: don't clearChannel from player to retain focused item
    }

    onNextSelected = (data) => {
        // postSegmentData('On Next', 'Live Tv', data.nextShow.title)
        // this.props.showExtendedInfo({ ...data, eventType: 'ON NEXT' })
    }

    render = () => {
        let channels = "";
        let isLoading = (this.props.sections.data_livetv && this.props.sections.data_livetv.isLoading) || this.props.channels.isLoading
        if (this.props.sections.error && this.props.sections.error.isVisible)
            return <TvError {...this.props.sections.error} onSelected={[this.onErrorActionSelected('sections'), this.onErrorBackSelected('sections')]} />

        let extendedInfo = this.props.extendedInfo && this.props.extendedInfo.eventType && <ExtendedInfo {...this.props.channels.synopsisData} eventType={this.props.extendedInfo.eventType} isOnNow={this.props.extendedInfo.isOnNow} getChannelEvents={getChannelEvents} getChannelShowTimes={getChannelShowTimes} />
        let isTvFocus = (this.props.channels.data && this.props.channels.data[0] && this.props.channels.data[0].isTvFocus) ||
            (this.props.channels.synopsisData && this.props.channels.synopsisData.isTvFocus) || false;
        let isRWC = (this.props.channels && this.props.channels.data_all && this.props.channels.data_all[0] && this.props.channels.data_all[0].title === 'Recently watched channels')
        let focusEvent = this.props.channels.focusEvent ? this.props.channels.focusEvent : [-1, -1];

        let path = this.props.location.pathname.split('/')
        let channelsVisible = true
        if (path[2] === 'play' || path[4] === 'play')
            channelsVisible = false

        channels = <TvChannels data={this.props.channels.data || []}
            synopsisData={this.props.channels.synopsisData}
            onSelected={this.onChannelCardSelected}
            onClick={this.onClicked}
            onNextSelected={this.onNextSelected}
            fromSmartTv={true}
            styledCarousel={styledEditorial}
            noContent={this.props.sections.data_livetv && this.props.sections.data_livetv.data && ErrorList.noContent}
            noRWCContent={this.props.sections.data_livetv && this.props.sections.data_livetv.data && (isRWC ? ErrorList.noRWCContent : ErrorList.noContent)}
            isLoading={isLoading}
            path={this.props.location.pathname.split("/").pop()}
            getDayEvents={this.getDayEvents}
            refreshEvents={this.refreshEvents}
            callNextDayEvents={this.callNextDayEvents}
            isTvFocus={isTvFocus}
            focusEvent={focusEvent}
            channelScroll={this.props.channels.channelScroll}
            hoursMode={this.state.hoursMode}
            getChannelEvents={getChannelEvents}
            getChannelShowTimes={getChannelShowTimes}
        />
        return <React.Fragment>
            <StreamaBillboard
                isCatchupBillboard={true}
                isPageContentTvFocus={isTvFocus}
            />
            {channelsVisible ? channels : ''}
            {extendedInfo}
        </React.Fragment>;
    }
}

/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => ({
    sections: state.sections,
    channels: state.channels,
    extendedInfo: state.extendedInfo,
    menu: state.menu,
    channelEvents: state.channelEvents
});

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    getSections: (payload) => dispatch({ type: SECTIONS_LIVE, payload }),
    getChannel: (payload, scheduler) => dispatch({ type: CHANNELS, payload, scheduler }),
    clearChannel: () => dispatch({ type: CHANNELS_CLEAR }),
    openPlayer: (payload) => dispatch({ type: OPEN_PLAYER, payload }),
    scheduler: (payload) => dispatch({ type: SCHEDULER, payload }),
    showExtendedInfo: (payload) => dispatch({ type: SHOW_EXTENDED_INFO, payload }),
    throwSectionError: (payload) => dispatch({ type: SECTIONS_ERROR, payload }),
    clearSectionError: () => dispatch({ type: SECTIONS_ERROR_CLEAR }),
    clearChannelError: (payload) => dispatch({ type: CHANNELS_ERROR_CLEAR, payload }),
    sectionFocus: (payload) => dispatch({ type: SECTION_FOCUS, payload }),
    throwNetworkError: (payload) => dispatch({ type: CHANNELS_ERROR, payload }),
    abortChannel: () => dispatch({ type: CHANNELS_ABORT }),
    retryChannel: (action) => dispatch(action),
    updateProgressBar: () => dispatch({ type: UPDATE_PROGRESS_BAR }),
    getEvents: (payload, index) => dispatch({ type: GET_EVENTS, payload, lastIndex: index }),
    streamaBillboard: (payload) => dispatch({ type: STREAMA_BILLBOARD, payload }),
    clearStreamaBillboard: () => dispatch({ type: CLEAR_STREAMA_BILLBOARD }),
    clearEvents: () => dispatch({ type: 'CLEAR_EVENTS' }),
    setChannelsFocus: () => dispatch({ type: 'SET_CHANNELS_FOCUS' }),
    loadChannels: (payload) => dispatch({ type: 'FILTER_CHANNELS', payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
