import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div.attrs(({ shouldScroll, scrollHeight }) => ({
  style: {
    top: `${shouldScroll ? scrollHeight : 0}vw`
  }
}))`position: relative`

const styledList = (props, list) => {
  return (<StyledWrapper shouldScroll={props.shouldScroll} scrollHeight={props.scrollHeight}>
    {list}
  </StyledWrapper>)
}
export default styledList