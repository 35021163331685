import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TvError, TvSettings } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { talkToChannel } from '@connected-video-web/streama-system-manager-service/dist/services/talkToChannel';
let productType = localStorage.getItem('PRODUCT_TYPE');
class Settings extends Component {
  constructor(props) {
    super(props);
    let payload;
    if (this.props.player.manageDeviceError) {
      payload = { 'manageDeviceError': true }
    }
    this.props.getSettingsData(payload)
    this.props.clearCatchup();
    
  }
    componentWillUnmount = () => {
      this.props.clearSettingData();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.settings.data[1]) {
            let selectedApp = nextProps.settings.data[1].items.filter(item => item.isTvFocus).pop()
            if (selectedApp && selectedApp.appName) {
              productType === 'STREAMA' ? talkToChannel(selectedApp): '';
            } else if (selectedApp && selectedApp.SMS) {
              productType === 'STREAMA' ? talkToChannel(selectedApp.SMS): '';
            }
                
        }
    if(nextProps && nextProps.settings && nextProps.settings.error && nextProps.settings.serverError){
       postAPIErrorToSegment(nextProps.settings.error,nextProps.settings.serverError)
    }
    if (nextProps.login.isloggedOut) {
      this.props.logout()
      this.props.history.push({ pathname: '/' })
    }
  }
  onErrorActionSelected = (item) => {
    this.props.retryCatchup(item.retryAction)
  }
  onErrorBackSelected = () => {
    this.props.clearSettingError()
    }
  render() {
    let error, settings
    if (this.props.settings && this.props.settings.error && this.props.settings.error.isVisible) {
      error = <TvError {...this.props.settings.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
    }
    if (this.props.settings.data) {
        settings = <TvSettings data={this.props.settings.data} onSelected={this.onSelected} isLoading={this.props.settings.isLoading} isFocussed={this.props.settings.error}/>
      return (<React.Fragment>{settings}{error}</React.Fragment>)
    }
    return null
  }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    settings: state.settings,
    menu: state.menu,
    pin: state.pin,
    login: state.login,
    player: state.player
  }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  getSettingsData: (props) => dispatch({ type: 'SETTING_INIT', payload: props }),
  clearSettingData: () => dispatch({ type: 'SETTINGS_CLEAR' }),
  logout: (from) => dispatch({ type: 'LOG_OUT', payload: from }),
  retryCatchup: (action) => dispatch(action),
  clearCatchup: () => dispatch({ type: CATCHUP_CLEAR }),
  clearSettingError: () => dispatch({ type: 'SETTINGS_ERROR_CLEAR' }),

})

export default connect(mapStateToProps, mapDispatchToProps)(Settings);