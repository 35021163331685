import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ProfileList, ProfileAvatarList, ProfileName, ProfileConfirm } from './'
import { GLOBALS } from '../../../globals';
import { pushProfileListScreen } from './helper';
import { REMOTE_CONFIG, FLAGR_ERROR, FLAGR_ERROR_CLEAR, PROFILE_FOCUS, UPDATE_MENU_ON_CHANGE, GET_TERMS_AND_CONDITIONS, SET_TERMS_AND_CONDITIONS } from '@connected-video-web/dstv-frontend-services/dist/actions';
import { networkPayload } from '../../helper';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { getFocusedIndex } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { checkDeeplinkPathCondition } from "../../helper"
import { Loader } from "@connected-video-web/dstv-frontend-components/dist/Components/Core"
import { OverlayMessageTandCs } from "@connected-video-web/dstv-frontend-components/dist/Components/Container/OverlayMessage"

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termsAccepted: false,
          };
    }

    componentDidMount() {
        // if (!this.props.terms.status && !this.props.terms.isLoading) {
        //   this.props.getTerms();
        // }
        
        if (this.props.page && this.props.page[1] === '') {
            this.getRemoteConfigFlagr()
        }
        let activeMenu;
        if (this.props.menu.data && this.props.menu.data.length)
            activeMenu = getFocusedIndex(this.props.menu.data, 'isTvFocus')
        if (checkDeeplinkPathCondition(this.props)) {
            pushProfileListScreen(this.props.history)
            this.props.focusProfile('UP')
            this.props.updateMenu(this.props && this.props.menu, activeMenu)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.profiles && nextProps.profiles.error && (nextProps.profiles.serverError)) {
            postAPIErrorToSegment(nextProps.profiles.error, { errorMessage: nextProps.profiles.serverError })
        }
        if ((!this.props.flagr.isProfile && nextProps.flagr.isProfile)) {
            pushProfileListScreen(this.props.history)
        }

        if(nextProps.terms.status && !this.state.termsAccepted){
            this.setState({
              termsAccepted: true,
            });
          }
    }

    getRemoteConfigFlagr = () => {
        if (internetIsConnected()) {
            this.props.getRemoteConfigFlagr({ APP_VERSION: GLOBALS.APP_VERSION })
        } else {
            this.props.throwNetworkError({
                error:
                    networkPayload({
                        callback: () => { this.getRemoteConfigFlagr() },
                        toggleText: true
                    })
            })
        }
    }

    getProfilePage = (page) => {
        switch (page[2]) {
            case 'new':
            case 'edit':
                if (page[3] === 'name')
                    return <ProfileName {...this.props} />
                return <ProfileAvatarList {...this.props} />
            case 'save':
            case 'delete':
                return <ProfileConfirm {...this.props} />
            default:
                return <ProfileList {...this.props} />  //who's watching page

        }
    }
    onErrorActionSelected = (e) => {
        this.getRemoteConfigFlagr()
    }
    onErrorBackSelected = () => {
        this.props.exitApplication()
    }

    setTerms = () => {
        this.props.setTerms();
        this.setState({
          termsAccepted: true,
        })
      }

    render() {
        let profile = this.getProfilePage(this.props.page)
        let error
        if (this.props.flagr.error && this.props.flagr.error.isNetworkError)
            error = <TvError {...this.props.flagr.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
        return (<React.Fragment>
            {profile}
            {error}
        </React.Fragment>)
        // Shhhhhhh, time to sleep
        // } else {
        //     return this.props.terms.isLoading ? <Loader fromSmartTv={true} isLoading={true} /> :  <OverlayMessageTandCs accept={this.setTerms}/>
        // }
    }
}
const mapStateToProps = state => {
    return {
        flagr: state.flagr,
        profiles: state.profiles,
        menu: state.menu,
        terms: state.terms,
    }
};

const mapDispatchToProps = dispatch => ({
    getRemoteConfigFlagr: (payload) => dispatch({ type: REMOTE_CONFIG, payload, forceUpdate: true }),
    throwNetworkError: (payload) => dispatch({ type: FLAGR_ERROR, payload }),
    exitApplication: () => dispatch({ type: FLAGR_ERROR_CLEAR }),
    focusProfile: (move) => dispatch({ type: PROFILE_FOCUS, move }),
    updateMenu: (payload, activeMenu) => dispatch({ type: UPDATE_MENU_ON_CHANGE, payload, menuIndex: activeMenu, path: 'profiles' }),
    getTerms: () => dispatch({ type: GET_TERMS_AND_CONDITIONS }),
    setTerms: () => dispatch({ type: SET_TERMS_AND_CONDITIONS })
})



export default connect(mapStateToProps, mapDispatchToProps)(Profile);