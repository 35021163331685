import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  SECTIONS_LIVE, CHANNELS, SWITCH_CHANNELS_GUIDE, CHANNELS_GUIDE_FOCUS, SHOW_EXTENDED_INFO, SECTION_HIDE, SWITCH_CHANNEL,
  PLAY, PAUSE, CHANNELS_RWC, CHANNELS_FOCUS, HIDE_CHANNELS_GUIDE, REMOVE_PLAYER_FOCUS, PLAYER_FOCUS, SECTION_PROPS, HIDE_EXTENDED_INFO, CACHED_CHANNELS_FOCUS, GET_EVENTS, SECTION_FOCUS,
  PLAYER_SETTINGS_FOCUS, PLAYER_SETTINGS_HIDE, PLAYER_AUDIO_TRACKS
} from '@connected-video-web/dstv-frontend-services/dist/actions';

import { GLOBALS } from '../../globals';
import styles from '../../base-styles/playerui.scss';
import { KeyCode } from '../../keyCodeMapping';
import PLAY_ICON from '../../Images/play_icon.png';
import PAUSE_ICON from '../../Images/pause_button.png';
import LIVE_INDICATOR from '../../Images/live_indicator.png';
import PREVIOUS_ICON from '../../Images/previous_icon.png';
import NEXT_ICON from '../../Images/next_icon.png';
import SETTINGS_ICON from '../../Images/settings_icon.png';
import SETTINGS_ICON_FOCUS from '../../Images/settings_icon_focus.png';
import { TvMenu } from '@connected-video-web/dstv-frontend-components/dist/Components/Collection';
import { Iplate } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { styledEditorial } from '../styledComponents';
import moment from "moment";
import {
  checkSectionIsvisible, getExtendedInfo, checkSectionIsHide, setPlayerSeason,
  setPlayerEpisode, setPlayerEpisodeTitle, findActiveEpisode, getSelectedSection,
  getLiveStates, filterAndSelectVariant, getLabel
} from './PlayerHelper'
import { ExtendedInfo } from '@connected-video-web/dstv-frontend-components/dist/Components/Container';
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { getChannelEvents, getChannelShowTimes } from '@connected-video-web/dstv-frontend-services/dist/Utils/channelsHelper';
import { alterantiveAudioEnabled } from '@connected-video-web/dstv-frontend-services/dist/Utils/flagrHelper';
import { TvPlayerSettings } from '@connected-video-web/dstv-frontend-components/dist/Components/Container/TvPlayerSettings';
import { saveToLocalStorage, retriveFromLocalStorage } from '@connected-video-web/dstv-frontend-services/dist/Utils/storageHelpers';
import { defaultBitrateData } from '@connected-video-web/dstv-frontend-services/dist/constants/defaultData';

class LivePlayerCtrl extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.displayHeading = ''
    this.displayEpisode = ''
    this.autoHideTimer = null
    this.requestTimer = null
    this.onClickActionFlag = false
    this.requestChannelLoading = false
    this.playerVariants = null
    this.setPlaybackDefault = false
    this.activeAudio = null
    this.state = getLiveStates()
    window.addEventListener('keydown', this.navigation);
    window.addEventListener('paused', this.handleRemoteEvents('PAUSE'));
    window.addEventListener('resumed', this.handleRemoteEvents('PLAY'));
    this.showMetadata()
  }
  componentDidMount = () => {
    this.refreshEvent = setInterval(() => {
      // this.showMetadata(this.props.channels.data_all[0].items[index])
    }, GLOBALS.CHANNEL_REFRESH_EVENT_TIMER);
  }
  componentWillMount = () => {
    if (!this.props.sections.data_livetv ||
      (this.props.sections.data_livetv && !this.props.sections.data_livetv.data) ||
      (this.props.sections.data_livetv && this.props.sections.data_livetv.data && !this.props.sections.data_livetv.data[0].items.length)) {
      this.props.getLiveSection();
    } else if (!this.props.player.error) {
      if (this.checkDataLength(this.props.sections, 'liveSections')) {
        let section = this.props.sections.data_livetv.data[0].items.filter(item => item.isActive)
        if (section && !section.length)
          this.props.sections.data_livetv.data[0].items[0].isActive = true
      }
      this.getGuideData('All Channels')
      this.props.setPlayerFocus()
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.player.isTvFocus && !nextProps.player.isTvFocus) {
      this.getGuideData('Recently watched channels')
      // call All channels API 
      if (!this.props.channels.data_all || (this.props.channels.data_all && !this.props.channels.data_all.length)) {
        let self = this
        setTimeout(function () {
          self.getGuideData('Recently watched channels')
        }, GLOBALS.GET_CHANNELS_RETRY_TIMER)
      }
    } else if (this.props.sections.data_livetv.data[0].isHide && Object.keys(this.props.extendedInfo).length && !Object.keys(nextProps.extendedInfo).length) {
      this.toggleControlsState(false, false, false)
      this.setState({ ...this.state, isPlaying: true })
      this.props.setPlayerFocus()
      this.hideSections()
    } else if (this.props.player.error === null && nextProps.player.error) {
      this.resetControls()
      this.props.setPlayerFocus()
      this.hideSections()
    } else if (Object.keys(this.props.extendedInfo).length && !Object.keys(nextProps.extendedInfo).length) {
      this.setState({ ...this.state, isPlaying: true })
    }
  }

  componentDidUpdate() {
    if (this.props.playbackStarted && !this.setPlaybackDefault && (localStorage.getItem('PRODUCT_TYPE') != 'HISENSE')) {
        this.playerVariants = window.player.getVariantTracks();
        if (this.playerVariants && this.playerVariants.length > 0) {
          this.playerVariants.map(function(variant){
            if (variant.language === 'und')
              variant.language = null
            if (!variant.label)
                variant.label = null
          });

          if (alterantiveAudioEnabled())
            this.props.updateAudioTracks({ tracks: this.playerVariants })

          filterAndSelectVariant( null, this.props.player, this.playerVariants)
          this.setPlaybackDefault = true
        }
    }
    if (this.props.player.shouldOnlySwitchStream && this.props.player.actionPayload && this.props.player.actionPayload.from === "Iplate") {
      this.props.notifySwitchChannel();
      const { actionPayload } = this.props.player
      this.switchChannel(actionPayload.payload)
    }
    let isFromLiveCard = this.props.location && this.props.location.state && this.props.location.state.isFromCards
    const hr = moment(new Date()).format("HH")
    if ((hr > 22) && this.state.hoursMode === 24 && isFromLiveCard) {
      if (this.props.channelEvents && this.props.channelEvents.events)
        this.callNextDayEvents()
    }
    if (this.checkDataLength(this.props.sections, 'sectionScrollWidth') && (this.myRef && this.myRef.current)) {
      this.props.setSectionScrollWidth({
        stopLimit: this.state.stopLimit,
        myRef: this.myRef,
        page: 'livetv'
      })
    }
    if (this.props.player && this.props.player.isOpen && this.props.channelEvents && this.props.channelEvents.isLoaded === false && this.props.channels.isCalled && isFromLiveCard) {
      this.callDayEvents();
    }
  }

  callDayEvents = () => {
    const visibleItems = this.props.channels.data[0].items.filter(item => item.isVisible);
    visibleItems.forEach((item, i) => {
      if (!item.events) {
        this.props.getEvents({
          channelTag: item.channelTag,
          date: moment(),
          append: false,
          index: i,
        });
      }
    });
  }

  callNextDayEvents = () => {
    const visibleItems = this.props.channels.data[0].items.filter(item => item.isVisible);
    visibleItems.forEach((item, i) => {
      if (!item.append) {
        this.props.getEvents({
          channelTag: item.channelTag,
          date: moment().add(1, "days"),
          append: true,
          index: i,
        });
      }
    })
    this.setState({ hoursMode: 48 });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.navigation);
    this.props.clearPlayerInactiveTimer() // Clear screen inactive dialogue timers
    clearTimeout(this.autoHideTimer)
    this.activeAudio = null //clear active audio variable
    this.playerVariants = null;
    this.setPlaybackDefault = false;
  }

  checkDataLength = (props, type) => {
    if (type === 'liveSections') {
      return props.data_livetv && props.data_livetv.data && props.data_livetv.data.length && props.data_livetv.data[0].items.length
    } else if (type === 'sectionScrollWidth') {
      return props.data_livetv && props.data_livetv.data && props.data_livetv.data[0] && props.data_livetv.data[0].isVisible && props.data_livetv.data[0].items && props.data_livetv.data[0].items[0].scrollWidth === undefined
    }
  }

  navigation = (e) => {
    if (!this.props.player.idleScreenDialogue) {
      switch (KeyCode(e.keyCode)) {
        case "UP":
        case "DOWN":
        case "RIGHT":
        case "LEFT": this.findFocus(KeyCode(e.keyCode))
          break
        case "SELECT": this.handleSelect(KeyCode(e.keyCode))
          break
        case "BACK": if (this.props.playbackStarted)
          this.goBack()
          break;
        case "RB":
        case "LB": this.handleRemoteEvents(KeyCode(e.keyCode))
        case "PLAY":
        case "PAUSE": this.handleRemoteEvents(KeyCode(e.keyCode))
          break;
        case "PLAY_PAUSE": this.requestPlayPause();
          break;
        case "STOP": if (this.props.playbackStarted)
          this.stopPlayer()
          break;
      }
    }
    if (this.props.playbackStarted) { //Reset Timer on user interaction
      this.props.resetPlayerInactiveTimer();
    }
  }

  stopPlayer = () => {
    this.toggleControlsState(false, false, false)
    this.props.setPlayerFocus()
    this.hideSections()
    this.props.hideExtendedInfo()
    this.props.stopPlayer()
  }

  getGuideData = (section) => {
    const path = window.location[window.isSmartTv ? 'hash' : 'pathname'].split("/");
    let getCategory = getSelectedSection(this.props.sections.data_livetv.data[0].items, section)
    this.callAPI(getCategory)
  }

  callAPI = (category) => {
    let self = this;
    if (category && category.length) {
      this.props.getChannel({
        url: category[0].url,
        filter: {
          genre: "ALL",
          sortOrder: "ALL",
          pageNo: 0,
          title: category[0].title,
          from: 'Player',
          channel: { 'channelNumber': self.props.player.channel.channelNumber, 'section': self.props.player.channel.section },
          section: '',
          playingSection: self.props.player.channel.playingSection,
          livetvData: self.props.channels && self.props.channels.data && self.props.channels.data.length ? self.props.channels.data : ''
        },
        type: category[0].title
      });
    }

  }

  getChannelCarousel = () => {
    let isLoading = (this.props.sections.data_livetv && this.props.sections.data_livetv.isLoading) || this.props.channels.isLoading
    return <Iplate data={this.props.channels.data || []}
      synopsisData={this.props.channels.synopsisData}
      onSelected={this.onChannelCardSelected}
      onNextSelected={this.onNextCardSelected}
      fromSmartTv={true}
      fromPlayer={true}
      hideLogoHeader={this.state.playPause.isTvFocus || this.state.prev.isTvFocus || this.state.next.isTvFocus || this.state.settings.isTvFocus}
      playingChannel={this.props.player.channel.channelNumber}
      styledCarousel={styledEditorial}
      isLoading={isLoading}
      getChannelEvents={getChannelEvents}
      getChannelShowTimes={getChannelShowTimes}
    />
  }
  getScrollWidth = (data) => {
    let ret = 0;

    if (data && data[0] && data[0].items) {
      const itm = (data && data[0] && data[0].items.filter((item, index) => {
        if (item.isActive) {
          return item.isActive;
        }
        return item.isActive;
      }).pop() || {});

      return itm.scrollWidth || 0;
    } else {
      return ret;
    }
  }
  getExtendedInfo = (props) => {
    //...props.channels.synopsisData
    if (props.extendedInfo && props.extendedInfo.eventType)
      return <ExtendedInfo onSelected={this.onExtendedInfoClick} {...props.extendedInfo} eventType={props.extendedInfo.eventType} isOnNow={props.extendedInfo.isOnNow} getChannelEvents={this.getChannelEvents} getChannelShowTimes={getChannelShowTimes} />
    return null
  }

  getSection = () => {
    if (this.checkDataLength(this.props.sections, 'liveSections')) {
      let section = this.props.sections.data_livetv.data[0].items.filter(item => item.isActive)
      if (section && !section.length)
        this.props.sections.data_livetv.data[0].items[0].isActive = true
    }
    return <TvMenu shouldHide={false}
      data={this.props.sections.data_livetv.data[0].items}
      onClick={this.onClick}
      isLoading={this.props.menu.isLoading}
      refProps={this.myRef}
      fromPlayer={true}
      isSection={true}
      scrollWidth={this.getScrollWidth(this.props && this.props.sections && this.props.sections.data_livetv && this.props.sections.data_livetv.data)}
    />
  }

  resetControls = () => {
    this.setState({ ...this.state, isVisible: false, playPause: { isTvFocus: false } })
  }

  onExtendedInfoClick = (e) => {
    let item
    if (this.props.channels && this.props.channels.data_all && this.props.channels.data_all.length
      && this.props.channels.data_all[0].items && this.props.channels.data_all[0].items.length
      && this.props.extendedInfo && this.props.extendedInfo.channelNumber
      && this.props.extendedInfo.eventType === 'ON NOW') {
      item = this.props.channels.data_all[0].items.filter(item => {
        return item.channelNumber === this.props.extendedInfo.channelNumber
      })
      if (item && item.length) {
        item[0].key = this.props.player.channel.key
        item[0].playingSection = this.props.sections.data_livetv.data[0].items.filter(item => item.isActive).pop().title
        let data = {
          "payload": item[0],
          "selectedCategory": item[0].section ? item[0].section : 'All Channels',
          'type': 'PLAY_CHANNEL'
        };
        this.props.switchChannel(data)
        this.setPlayerVariantDefault();
      }
    }
  }

  onClick = (e) => {
    clearTimeout(this.autoHideTimer)
    this.autoHide()
    this.props.sectionFocus({
      'page': 'livetv',
      'from': 'Player',
      activeSection: e,
      isOnClick: true,
      title: e.title,
      "filter": {
        channel: { 'channelNumber': this.props.player.channel.channelNumber, 'section': this.props.player.channel.section },
        section: e.title,
        playingSection: this.props.player.channel.playingSection
      }
    })
  }

  onNextCardSelected = (data) => {
    // postSegmentData('On Next', 'Player', data.nextShow.title)
    // this.props.showExtendedInfo({ ...data, eventType: 'ON NEXT', "from": 'Player' })
  }

  onChannelCardSelected = (data) => {
    // postSegmentData('On New', 'Player', data.title)
    // this.props.showExtendedInfo({ ...data, eventType: 'ON NOW', "from": 'Player' })
  }

  goBack = () => {
    if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
      this.handleSettingsNavigation('BACK') // Quality options - navigation
    } else if (this.state.playPause.isTvFocus || this.state.next.isTvFocus || this.state.prev.isTvFocus || this.state.settings.isTvFocus) {
      this.props.setPlayerFocus()
      this.hideSections()
      this.toggleControlsState(false, false, false)
      this.setState({ ...this.state, isVisible: false })
      this.setState({ ...this.state, settings: { isTvFocus: false } })
    } else if (this.props.sections.data_livetv.data[0].isVisible) {
      this.autoHide()
    }
  }

  clearAutoHideTimer = () => {
    if (this.autoHideTimer !== null) {
      clearTimeout(this.autoHideTimer)
    }
  }

  cancelRequestTimer = () => {
    if (this.requestTimer !== null) {
      clearTimeout(this.requestTimer)
    }
  }

  cancelonClickRequestPlaybackTimer = () => {
    if (this.onClickRequestPlaybackTimer !== null) {
      clearTimeout(this.onClickRequestPlaybackTimer)
    }
  }

  autoHide = () => {
    var self = this
    var duration = GLOBALS.LIVE_AUTO_HIDE_DURATION
    this.clearAutoHideTimer()
    this.autoHideTimer = setTimeout(function () {
      self.toggleVisibleState(false)
      self.toggleControlsState(false, false, false)
      self.props.setPlayerFocus()
      self.hideSections()
      self.hideControls()
      self.props.hidePlayerSettings()
    }, duration)
  }

  handleSelect = (keyCode) => {
    if (this.state.prev.isTvFocus === true && this.props.playbackStarted) {
      this.requestPrevious()
    } else if (this.state.playPause.isTvFocus === true) {
      this.requestPlayPause()
    } else if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
      //this.setVideoQuality()
      this.handleSettingsOptions(keyCode)
    } else if (this.state.settings.isTvFocus) {
      let payload = {
        clickedItem: 'settingsCog'
      };
      this.props.playerSettingsFocus(payload, null)
    } else if (this.state.next.isTvFocus === true && this.props.playbackStarted) {
      this.requestNext()
    } else if (this.props.sections.data_livetv.data.length > 0 && this.props.sections.data_livetv.data[0].isVisible && !this.props.sections.data_livetv.data[0].isHide) {
      this.goBack();
    } else if (Object.keys(this.props.extendedInfo).length && this.props.sections.data_livetv.data[0].isHide) {
      this.clearAutoHideTimer()
    } else {
      let sectionIsVisible = checkSectionIsvisible(this.props);
      if (!this.state.isVisible && !sectionIsVisible) {
        this.getIplateControls()
        this.showMetadata()
        this.updateNextPreviousChannelDetails(true, false, false)
      }
    }
  }

  requestPlayPause = () => {
    this.getIplateControls()
    clearTimeout(this.autoHideTimer)
    this.autoHide()
    if (this.state.isPlaying) {
      this.props.pause()
      this.setState({ ...this.state, isPlaying: false })
    } else {
      this.props.play()
      this.setState({ ...this.state, isPlaying: true })
    }
    this.updateNextPreviousChannelDetails(true, false, false)
  }

  handleSettingsOptions = (keycode) => {
    let mainIndex = this.props.player.settings.data.findIndex(item => item.isActive === true);
    let subIndex = -1
    if (mainIndex > -1) {
      subIndex = this.props.player.settings.data[mainIndex].items.findIndex(item => item.isTvFocus === true);
      if (this.props.player.settings.data[mainIndex].items[subIndex] && !this.props.player.settings.showHeading && keycode === 'SELECT') {
        if (this.props.player.settings.data[mainIndex].MainHeading === 'Streaming Quality')
          this.props.setVideoQuality({ currentItem: this.props.player.settings.data[mainIndex].items[subIndex], renderType: 'stream', fromPlayer: true })
        else if (this.props.player.settings.data[mainIndex].MainHeading === 'Audio Language')
          this.activeAudio = this.props.player.settings.data[mainIndex].items[subIndex].id;

        this.handleSettingsonSelect();
      }
    }
    this.handleSettingsNavigation(keycode, mainIndex, subIndex)
  }

  handleSettingsonSelect = () => {
    //Filter and select the variant by quality and audiotrack
    filterAndSelectVariant(this.activeAudio, this.props.player, this.playerVariants);

    //Reset controls
    this.setState({ ...this.state, settings: { isTvFocus: false } }) //remove settings focus
    this.props.setPlayerFocus() //Remove focus from UI and set to player
    this.hideSections() // hide the iplate sections
    this.toggleControlsState(false, false, false)
    this.setState({ ...this.state, isVisible: false })
  }

  handleSettingsNavigation = (keycode, mainIndex, subIndex) => {
    let quality = this.props.player.settings.data
      && this.props.player.settings.data[0]
      && this.props.player.settings.data[0].items ? this.props.player.settings.data[0].items.filter(item => item.value === parseInt(localStorage.getItem('QUALITY_STORAGE_BITRATE'))) : []
    let payload = {
      activeAudioLang: this.getLangFromActiveVariant(),
      activeQuality: quality.length && quality[0].classification,
      clickedSection: subIndex > -1 ? 'settingsSubList' : 'settingsMainList',
      clickedItem: mainIndex > -1 && this.props.player.settings.data[mainIndex].MainHeading ? this.props.player.settings.data[mainIndex].MainHeading : ''

    };
    this.props.playerSettingsFocus(payload, keycode)
  }

  getSettingsDefaultValues = () => {
    //Function to prepare the settings default values in main display list.
    let qualityData
    let bitratesData = sessionStorage.getItem('VIDEO_QUALITY_CONF') ? JSON.parse(sessionStorage.getItem('VIDEO_QUALITY_CONF')) : defaultBitrateData
    let defaultQuallity = bitratesData.filter(item => item.default === true)
    let qualityTitle = defaultQuallity[0].classification;  //Default value
    if (localStorage.getItem('QUALITY_STORAGE_BITRATE') && this.props.player.settings && this.props.player.settings.data && this.props.player.settings.data[0] && this.props.player.settings.data[0].items && this.props.player.settings.data[0].items.length) {
      qualityData = this.props.player.settings.data[0].items.filter(item => item.value === parseInt(localStorage.getItem('QUALITY_STORAGE_BITRATE')));
      if (qualityData.length === 0) {
        qualityTitle = this.props.player.settings.data[0].items[0].classification
      } else {
        qualityTitle = qualityData[0].classification
      }
    }

    return [{
      id: 'bitrate',
      value: qualityTitle
    }, {
      id: "audioLanguage",
      value: this.getLangFromActiveVariant()
    }]
  }

  getLangFromActiveVariant = () => {
    let activeAudioTitle;
    let variants = window.player.getVariantTracks();
    let activeVariantIndex = variants.findIndex(variant => variant.active === true);
    if (activeVariantIndex > -1) {
      activeAudioTitle = getLabel(variants[activeVariantIndex])
    }
    return activeAudioTitle
  }

  playerSettingsModal = () => {
    if (!this.props.player.error && !this.props.isLoading && this.props.player.settings && this.props.player.settings.data && this.props.player.settings.data.length) {
      let mainIndex = this.props.player.settings.data.findIndex(item => item.isActive === true)
      if (mainIndex === -1) mainIndex = 0
      let subData = {
        heading: this.props.player.settings.data[mainIndex].MainHeading,
        items: (!this.props.player.settings.showHeading) ? this.props.player.settings.data[mainIndex].items : ''
      }

      return <TvPlayerSettings isVisible={this.props.player.settings.isVisible}
        defaultVallues={this.getSettingsDefaultValues()}
        mainList={this.props.player.settings.showHeading ? this.props.player.settings.data : ''}
        subList={subData}>
      </TvPlayerSettings>
    } else
      return null
  }

  onClickControlActions = () => {
    this.getIplateControls()
    clearTimeout(this.autoHideTimer)
    this.autoHide()
    this.updateNextPreviousChannelDetails(true, false, false)
  }

  onClickPlayPause = (e) => {
    let self = this
    if (this.state.isPlaying) {
      this.setState({ ...this.state, isPlaying: false }, () => { self.props.pause(), self.onClickControlActions() })
    } else {
      this.setState({ ...this.state, isPlaying: true }, () => { self.props.play(), self.onClickControlActions() })
    }
  }

  hideSections = () => {
    if (this.props.channels && (this.props.channels.data && this.props.channels.data.length)) {
      let section = this.props.sections.data_livetv.data[0].items.filter(item => item.isActive);
      this.props.hideChannelSections({
        "filter": {
          channel: { 'channelNumber': this.props.player.channel.channelNumber, 'section': this.props.player.channel.section },
          section: '',
          playingSection: section[0].title
        }
      })
    }
  }

  hideControls = () => {
    return this.setState({ ...this.state, settings: { isTvFocus: false } })
  }

  showMetadata = (channelDetails) => {
    let currentEvent = getChannelEvents(this.props.player.channel);
    let activeEpisode = findActiveEpisode(this.props)

    if (currentEvent) {
      let season = currentEvent.seasonNumber || activeEpisode.seasonNumber || null;
      let episode = currentEvent.episodeNumber || activeEpisode.seasonEpisode || null;
      let episodeTitle = activeEpisode.episodeTitle || activeEpisode.title || null;
      this.displayHeading = currentEvent.title || this.props.player.channel.title || this.props.player.channel.channelName || "";
      this.displayEpisode = (season ? "S" + season : "") + (episode ? (season ? ", E" + episode : "E" + episode) : "") + (episodeTitle ? ": " + episodeTitle : "");
    } else {
      let showdetails = ''
      if (activeEpisode && !this.props.player.channel) {
        if (activeEpisode.seasonNumber) {
          showdetails = setPlayerSeason(activeEpisode.seasonNumber)
        }
        if (activeEpisode.seasonEpisode) {
          showdetails += setPlayerEpisode(activeEpisode.seasonEpisode, activeEpisode.seasonNumber) + ' '
        }

        if (activeEpisode.title) {
          showdetails += setPlayerEpisodeTitle(activeEpisode.title, activeEpisode.seasonNumber, activeEpisode.seasonEpisode)
        }
        this.displayHeading = activeEpisode.title;
        if (showdetails) {
          this.displayEpisode = showdetails
        }
      } else {
        this.displayHeading = channelDetails && channelDetails.title ? channelDetails.title : this.props.channel && this.props.channel.title ? this.props.channel.title : this.props.player.channel && this.props.player.channel.title ? this.props.player.channel.title : '';
        this.displayEpisode = channelDetails && channelDetails.episodeTitle ? channelDetails.episodeTitle : this.props.channel && this.props.channel.episodeTitle ? this.props.channel.episodeTitle : this.props.player.channel && this.props.player.channel.episodeTitle ? this.props.player.channel.episodeTitle : '';
      }
    }
  }

  handlePrevOnClick = (e) => {
    let self = this
    if (this.props.playbackStarted) {
      this.updateNextPreviousChannelDetails(false, true, false, 'previous');
      setTimeout(function () {
        self.requestPrevious()
      }, 100)
    }
  }

  handleNextOnClick = (e) => {
    let self = this
    if (this.props.playbackStarted) {
      this.updateNextPreviousChannelDetails(false, true, false, 'next');
      setTimeout(function () {
        self.requestNext()
      }, 100)
    }
  }

  requestPrevious = () => {
    this.requestChannelLoading = true
    this.props.destroyPlayer();
    clearTimeout(this.autoHideTimer)
    this.autoHide()
    let index = this.getPlayingContentIndex(this.state.prev.channelDetail.payload)
    let prevChannelDetail = {}
    if (index > -1 && this.props.channels.data_all[0].items[index]) {
      if (this.props.channels.data_all[0].items[index - 1]) {
        prevChannelDetail = this.props.channels.data_all[0].items[index - 1]
        prevChannelDetail.key = this.props.player.channel.key
        this.toggleControlsState(false, true, false, null, prevChannelDetail, this.props.channels.data_all[0].items[index + 2] ? true : false, true)
      } else {
        prevChannelDetail = ""
        this.toggleControlsState(true, false, false, null, null, this.props.channels.data_all[0].items[index + 2] ? true : false, false)
      }
      this.props.notifySwitchChannel();
      this.showMetadata(this.props.channels.data_all[0].items[index])
      this.switchChannel(this.props.channels.data_all[0].items[index], 'previous')
      this.props.setChannelFocus({ move: "UP" });
      this.switchChannelGuide(this.props.channels.data_all[0].items[index])
    }
  }

  requestNext = () => {
    this.requestChannelLoading = true
    this.props.destroyPlayer();
    clearTimeout(this.autoHideTimer)
    this.autoHide()
    let index = this.getPlayingContentIndex(this.state.next.channelDetail.payload)
    let nextChannelDetail = {}
    if (index > -1 && this.props.channels.data_all[0].items[index]) {
      if (this.props.channels.data_all[0].items[index + 1]) {
        nextChannelDetail = this.props.channels.data_all[0].items[index + 1]
        nextChannelDetail.key = this.props.player.channel.key
        this.toggleControlsState(false, false, true, nextChannelDetail, null, true, this.props.channels.data_all[0].items[index - 2] ? true : false)
      } else {
        this.toggleControlsState(true, false, false, null, null, false, this.props.channels.data_all[0].items[index - 2] ? true : false)
      }
      this.props.notifySwitchChannel();
      this.showMetadata(this.props.channels.data_all[0].items[index])
      this.switchChannel(this.props.channels.data_all[0].items[index], 'next')
      this.props.setChannelFocus({ move: "DOWN" });
      this.switchChannelGuide(this.props.channels.data_all[0].items[index], this.props.channels.data_all[0].title)
    }
  }

  switchChannelGuide = (channelDetail, title) => {
    this.props.switchChannelGuide(
      {
        "filter": {
          channel: { 'channelNumber': channelDetail.channelNumber, 'section': title },
          section: '',
          playingSection: (channelDetail.playingSection === 'All Channels') ? channelDetail.playingSection : channelDetail.section
        }
      })
  }

  switchChannel = (channelDetail, action) => {
    let self = this
    this.cancelRequestTimer();
    channelDetail.key = self.props.player.channel.from === "Iplate" ? self.props.player.channel.payload.key : self.props.player.channel.key
    channelDetail.playingSection = self.props.channels.data_all[0].title
    channelDetail.selectedCategory = []
    this.requestTimer = setTimeout(function () {
      self.props.switchChannel({ 'payload': channelDetail, 'selectedCategory': self.props.channels.data_all[0].title, type: 'PLAY_CHANNEL', 'action': action })
      self.setState({ ...self.state, isPlaying: true })
      self.updateNextPreviousChannelDetails(true, false, false, action)
      self.requestChannelLoading = false
      self.props.removePlayerFocus()
      self.activeAudio = null
      self.setPlayerVariantDefault();
    }, GLOBALS.PLAY_DURATION_TIMER);
  }

  setPlayerVariantDefault = () => {
    let self = this;
    setTimeout(function(){
      self.setPlaybackDefault = false;
    }, 1000)
  }

  handleRemoteEvents = (keycode) => {
    let sectionIsVisible = checkSectionIsvisible(this.props);
    let self = this
    if (keycode === "PLAY" || keycode === "PAUSE") {
      if (!Object.keys(this.props.extendedInfo).length) {
        this.getIplateControls()
        this.updateNextPreviousChannelDetails(true, false, false)
      }
      this.setState({ ...this.state, isPlaying: keycode === "PLAY" ? true : false })
      this.getAction(keycode)
    } else if (keycode === "RB" || keycode === "LB") {
      if (!this.state.isVisible && !sectionIsVisible && (this.props.channels.data_all || this.props.channels.data_rwc)) {
        this.getIplateControls()
        this.updateNextPreviousChannelDetails(false, (keycode === "LB") ? true : false, (keycode === "RB") ? true : false, (keycode === "RB") ? 'next' : 'previous')
      } else {
        if (keycode === "RB") {
          if ((this.state.playPause.isTvFocus || this.state.prev.isTvFocus)) {
            setTimeout(function () {
              self.updateNextPreviousChannelDetails(false, false, true, 'next')
            }, GLOBALS.RB_LB_SWITCH_DURATION);
          } else if (this.state.next.isTvFocus) {
            self.getAction(keycode)
          }
        } else if (keycode === "LB") {
          if ((this.state.playPause.isTvFocus || this.state.next.isTvFocus)) {
            setTimeout(function () {
              self.updateNextPreviousChannelDetails(false, true, false, 'previous')
            }, GLOBALS.RB_LB_SWITCH_DURATION);

          } else if (this.state.prev.isTvFocus) {
            this.getAction(keycode)
          }
        }
      }
    }
  }

  getAction = (keycode) => {
    if (keycode === "PLAY")
      return this.props.play()
    else if (keycode === "PAUSE")
      return this.props.pause()
    else if (keycode === "RB")
      return this.requestNext()
    else if (keycode === "LB")
      return this.requestPrevious()
  }

  findFocus = (keycode) => {
    let sectionIsVisible = checkSectionIsvisible(this.props);
    this.autoHide()
    if (keycode === "DOWN") {
      if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
        this.handleSettingsNavigation(keycode) // Quality options - navigation
      } else if (!this.state.isVisible && !sectionIsVisible) {
        this.getIplateControls()
        this.showMetadata()
        this.updateNextPreviousChannelDetails(true, false, false)
      } else if (this.state.isVisible && !this.requestChannelLoading && (this.props.channels.data_all && this.props.channels.data_all.length)) {
        this.toggleVisibleState(false)
        this.toggleControlsState(false, false, false)
        this.toggleSettingsState(keycode)
        if (this.props.channels && (this.props.channels.data && this.props.channels.data.length && !this.props.channels.isLoading)) { // Check Data is loaded
          this.props.sectionFocus({ 'page': 'livetv', 'from': 'Player', })
        }
      }
    } else if (keycode === "UP") {
      if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
        this.handleSettingsNavigation(keycode) // Quality options - navigation
      } else if (!this.state.isVisible && !sectionIsVisible && (this.props.channels.data_all || this.props.channels.data_rwc)) {
        this.getIplateControls()
        this.updateNextPreviousChannelDetails(true, false, false)
      }
    } else if (keycode === "RIGHT") {
      if (!this.state.isVisible && !sectionIsVisible && (this.props.channels.data_all || this.props.channels.data_rwc)) {
        this.getIplateControls()
      } else if (this.state.isVisible && this.state.playPause.isTvFocus) {
        this.updateNextPreviousChannelDetails(false, false, true, 'next')
      } else if (this.state.isVisible && this.state.prev.isTvFocus) {
        this.updateNextPreviousChannelDetails(true, false, false, 'next')
      } else if (this.state.isVisible && this.state.settings.isTvFocus && (!this.props.player.settings || (this.props.player.settings && !this.props.player.settings.isVisible))) {
        // Focus to settings icon
        this.updateNextPreviousChannelDetails(false, true, false, 'previous')
        this.toggleSettingsState(keycode)
      }
    } else if (keycode === "LEFT") {
      if (!this.state.isVisible && !sectionIsVisible && (this.props.channels.data_all || this.props.channels.data_rwc)) {
        this.getIplateControls()
      } else if (this.state.isVisible && this.state.playPause.isTvFocus) {
        let index = this.getPlayingContentIndex(this.props.player.channel)
        if (index === 0 && (localStorage.getItem('PRODUCT_TYPE') != 'HISENSE')) {
          this.updateNextPreviousChannelDetails(false, false, false, 'settings')
          this.toggleSettingsState(keycode)
        } else
          this.updateNextPreviousChannelDetails(false, true, false, 'previous')
      } else if (this.state.isVisible && this.state.next.isTvFocus) {
        this.updateNextPreviousChannelDetails(true, false, false, 'previous')
      } else if (this.state.isVisible && this.state.prev.isTvFocus && (localStorage.getItem('PRODUCT_TYPE') != 'HISENSE')) {
        // Focus to settings icon
        this.updateNextPreviousChannelDetails(false, false, false, 'settings')
        this.toggleSettingsState(keycode)
      } else if (this.props.player && this.props.player.settings && this.props.player.settings.isVisible) {
        this.handleSettingsNavigation(keycode) // settings options - navigation
      }
    }
  }

  updateNextPreviousChannelDetails = (playPause, prev, next, action) => {
    if (this.props.channels.data_all && this.props.channels.data_all.length) {
      let index = this.getPlayingContentIndex(this.props.player.channel)
      let detail;
      let i = (action === 'next') ? index + 1 : (action === 'previous') ? index - 1 : -1;
      if (index > -1 && this.props.channels.data_all[0].items[i]) {
        detail = this.props.channels.data_all[0].items[i]
      }

      if (action === 'settings') {
        this.toggleControlsState(playPause, prev, next, null, null, true, index > 0 ? true : false)
      } else if (action === 'next' && index < this.props.channels.data_all[0].items.length - 1) {
        this.toggleControlsState(playPause, prev, next, detail, null, this.props.channels.data_all[0].items[index + 1] ? true : false, this.props.channels.data_all[0].items[index - 1] ? true : false)
      } else if (action === 'previous' && index > 0) {
        this.toggleControlsState(playPause, prev, next, null, detail, this.props.channels.data_all[0].items[index + 1] ? true : false, this.props.channels.data_all[0].items[index - 1] ? true : false)
      } else {
        if (index === 0) {
          this.toggleControlsState(true, false, next, null, null, true, false)
        } else if (index === this.props.channels.data_all[0].items.length - 1) {
          this.toggleControlsState(true, prev, false, null, null, false, true)
        } else {
          this.toggleControlsState(playPause, prev, next, null, null, true, true)
        }
      }
    }
  }

  toggleSettingsState = (keycode) => {
    if (keycode === 'LEFT' || keycode === 'RIGHT' || keycode === 'DOWN')
      return this.setState({
        ...this.state,
        settings: { isTvFocus: (keycode === 'LEFT') ? true : false }
      })
    return true
  }

  getIplateControls = () => {
    if (this.props.playbackStarted) {
      this.props.removePlayerFocus()
      this.toggleVisibleState(true)
      this.toggleControlsState(true, false, false)
    }
  }

  getPlayingContentIndex = (currentChannel) => {
    let index = -1
    if (currentChannel && this.props.channels.data_all && this.props.channels.data_all[0] && this.props.channels.data_all[0].items) {
      index = this.props.channels.data_all[0].items.findIndex(item => item.channelNumber === currentChannel.channelNumber)
    }
    return index;
  }

  toggleVisibleState = (toggle) => {
    return this.setState({ ...this.state, isVisible: toggle })
  }

  toggleControlsState = (playPauseToggle, prevToggle, nextToggle, nextChannelDetail = "", prevChannelDetail = "", nextIsVisible = true, previousIsVisible = true,) => {
    return this.setState({
      ...this.state,
      playPause: { isTvFocus: playPauseToggle },
      prev: { isTvFocus: prevToggle, channelDetail: { payload: prevChannelDetail, selectedCategory: prevChannelDetail ? prevChannelDetail.section : '', type: 'PLAY_CHANNEL' } },
      next: { isTvFocus: nextToggle, channelDetail: { payload: nextChannelDetail, selectedCategory: nextChannelDetail ? nextChannelDetail.section : '', type: 'PLAY_CHANNEL' } },
      prevIsVisible: previousIsVisible,
      nextIsVisible: nextIsVisible
    })
  }

  setStyle = offset => {
    let offsetString = offset.toString()
    return {
      position: 'absolute',
      marginTop: `${offsetString}rem`,
      color: '#fff',
      zIndex: '999',
      fontSize: '1.5vw',
      background: '#000000'
    }
  }

  showLogs = ({ logs = [], playTimestamp = "", currentBitRate = 0, targetBitRate = 0 }) => {
    return (
      <div>
        <div className={styles.showLog}>
          {logs.map((log, index) => {
            return index === 0 ? <div style={this.setStyle(4)} key={index}>{log}</div> : <div style={this.setStyle(2 * (index + 2))} key={index}>{log}</div>
          })}
        </div>
        <div className={styles.timeStamp}>
          <p>Play timestamp : {playTimestamp || ""}</p>
          <p>Source Bitrate : {currentBitRate.toString()}</p>
          <p>Target Bitrate : {targetBitRate.toString()} </p>
        </div>
      </div>
    )
  }

  render() {
    let sectionVisible = checkSectionIsvisible(this.props);
    let isSectionHide = checkSectionIsHide(this.props);
    let channels = this.getChannelCarousel(this.props)
    let extendedInfo = this.getExtendedInfo(this.props);
    let section = this.getSection(this.props)
    let playerSettingsModal = (localStorage.getItem('PRODUCT_TYPE') != 'HISENSE') ? this.playerSettingsModal() :'';
    //if (!this.props.channels.data) return null
    if (!this.props.player.error) {
      return (
        <section>
          <div className={[(this.state.isVisible || sectionVisible) ? styles.playerOverlay : '', this.props.player && this.props.player.settings && this.props.player.settings.isVisible ? styles.fadeCtrls : ''].join(' ')}>
            <div className={[styles.playerUI, this.state.isVisible ? '' : styles.hide, this.props.player && this.props.player.settings && this.props.player.settings.isVisible ? styles.fadeUItext : ''].join(' ')}>
              <div className={styles.heading}>
                {this.displayHeading ? <div className={[styles.title, this.displayEpisode === '' ? styles.noEpisode : ''].join(' ')}> {this.displayHeading} </div> : ''}
                {this.displayEpisode ? <div className={styles.episode}> {this.displayEpisode} </div> : ''}
              </div>
              <div className={styles.liveIndicator}> <span> LIVE </span> <img className={styles.img} src={LIVE_INDICATOR} /> </div>
              <div className={styles.playerProgressBar}>
                <div className={[styles.playerProgressBarInner, styles.livePlayerProgressInnerWidth].join(' ')}> </div>
              </div>
              <div className={styles.playerControls}>

                <div className={[styles.settingsLive, !this.state.settings.isTvFocus ? styles.settingsCustomLive : '', (localStorage.getItem('PRODUCT_TYPE') === 'HISENSE') ? styles.visibilityHidden : ''].join(' ')} >
                  <div className={[this.state.settings.isTvFocus ? styles.settingsFocus : ''].join(' ')}>
                    {!this.state.settings.isTvFocus ? <img src={SETTINGS_ICON} /> : <img src={SETTINGS_ICON_FOCUS} />}
                  </div>
                </div>
                <div className={[styles.prev, !this.state.prevIsVisible ? styles.prevHide : "", this.state.settings.isTvFocus ? styles.manageNavWidth : ''].join(' ')}>
                  <div className={(this.state.prev.isTvFocus) ? styles.rectangle : ''}>
                    <div className={[(this.state.prev.isTvFocus) ? styles.focus : '', styles.info].join(' ')}>
                      {/*this.state.prev.isTvFocus ? <div className={styles.prevTitle}> Previous </div> : ''*/}
                      {this.state.prev.isTvFocus ? (
                        <div className={styles.details}>
                          <span className={styles.channelNumber}>
                            {this.state.prev.channelDetail && this.state.prev.channelDetail.payload && this.state.prev.channelDetail.payload.channelNumber ? this.state.prev.channelDetail.payload.channelNumber : ''}
                          </span>
                          <span className={styles.channelNumber}>
                            {this.state.prev.channelDetail && this.state.prev.channelDetail.payload && this.state.prev.channelDetail.payload.channelLogo ? <img className={styles.channelLogo} src={this.state.prev.channelDetail.payload.channelLogo} alt="" /> : ''}
                          </span>
                        </div>
                      ) : ""}
                    </div>
                    <img className={[(this.state.next.isTvFocus) ? [styles.focus] : '', styles.player_icon].join(' ')} src={PREVIOUS_ICON} />
                  </div>
                </div>
                <div className={styles.playPause}>
                  <div className={(this.state.playPause.isTvFocus) ? styles.circle : ''}>
                    <img className={(this.state.playPause.isTvFocus) ? styles.disabled_focus : ''} src={this.state.isPlaying ? PAUSE_ICON : PLAY_ICON} />
                  </div>
                </div>
                <div className={[styles.next, !this.state.nextIsVisible ? styles.nextHide : "", this.state.settings.isTvFocus ? styles.manageNavWidth : ''].join(' ')}>
                  <div className={(this.state.next.isTvFocus) ? styles.rectangle : ''}>
                    <img className={[(this.state.next.isTvFocus) ? [styles.focus] : '', styles.player_icon].join(' ')} src={NEXT_ICON} />
                    <div className={[(this.state.next.isTvFocus) ? styles.focus : '', styles.info].join(' ')}>
                      {this.state.next.isTvFocus ? (
                        <div className={styles.details}>
                          <span className={styles.channelNumber}>
                            {this.state.next.channelDetail && this.state.next.channelDetail.payload && this.state.next.channelDetail.payload.channelNumber ? this.state.next.channelDetail.payload.channelNumber : ''}
                          </span>
                          <span className={styles.channelNumber}>
                            {this.state.next.channelDetail && this.state.next.channelDetail.payload && this.state.next.channelDetail.payload.channelLogo ? <img className={styles.channelLogo} src={this.state.next.channelDetail.payload.channelLogo} alt="" /> : ''}
                          </span>
                        </div>
                      ) : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!this.props.channels.data ? '' :
              <div className={[(this.props.channels && this.props.channels.data && this.props.channels.data.length)
                && (this.props.channels.data_all && this.props.channels.data_all.length)
                && this.props.channels.data[0].isVisible
                && (this.state.isVisible || (sectionVisible && isSectionHide === false)) ? '' : styles.hide,
              this.state.isVisible ? styles.overlayDown : sectionVisible ? styles.overlayUp : ''].join(' ')}>

                <div className={styles.channelsGuide}>
                  <div className={(sectionVisible && !this.state.isVisible) ? '' : styles.hide}>
                    {section}
                  </div>
                </div>
                <div className={styles.channels}> {channels} </div>
              </div>
            }
            {extendedInfo}
            {playerSettingsModal}
          </div>
        </section>
      );
    } else
      return null
  }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    channels: state.channels,
    menu: state.menu,
    sections: state.sections,
    player: state.player,
    extendedInfo: state.extendedInfo,
    channelEvents: state.channelEvents,
    channels: state.channels
  }
}

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  getLiveSection: (payload) => dispatch({ type: SECTIONS_LIVE, payload }),
  getChannel: (payload, scheduler) => dispatch({ type: CHANNELS, payload, scheduler }),
  getRWC: (payload, scheduler) => dispatch({ type: CHANNELS_RWC, payload, scheduler }),
  scheduler: (payload) => dispatch({ type: SCHEDULER, payload }),
  sectionFocus: (payload) => dispatch({ type: SECTION_FOCUS, payload }),
  setChannelFocus: (payload) => dispatch({ type: CHANNELS_FOCUS, payload }),
  SectionHide: (payload) => dispatch({ type: SECTION_HIDE, payload }),
  showExtendedInfo: (payload) => dispatch({ type: SHOW_EXTENDED_INFO, payload }),
  switchChannelGuide: (payload) => dispatch({ type: SWITCH_CHANNELS_GUIDE, payload }),
  switchChannel: (payload) => dispatch({ type: SWITCH_CHANNEL, payload }),
  play: (payload) => dispatch({ type: PLAY, payload }),
  pause: (payload) => dispatch({ type: PAUSE, payload }),
  hideChannelSections: (payload) => dispatch({ type: HIDE_CHANNELS_GUIDE, payload }),
  hideExtendedInfo: (payload) => dispatch({ type: HIDE_EXTENDED_INFO, payload }),
  removePlayerFocus: (payload) => dispatch({ type: REMOVE_PLAYER_FOCUS, payload }),
  setPlayerFocus: (payload) => dispatch({ type: PLAYER_FOCUS, payload }),
  setSectionScrollWidth: (payload) => dispatch({ type: SECTION_PROPS, payload }),
  getEvents: (payload, index) => dispatch({ type: GET_EVENTS, payload, lastIndex: index }),
  setVideoQuality: (payload) => dispatch({ type: 'SET_VIDEO_QUALITY', payload }),
  playerSettingsFocus: (payload, move) => dispatch({ type: PLAYER_SETTINGS_FOCUS, payload, move }),
  hidePlayerSettings: (payload) => dispatch({ type: PLAYER_SETTINGS_HIDE, payload }),
  updateAudioTracks: (payload) => dispatch({ type: PLAYER_AUDIO_TRACKS, payload })
})

export default connect(mapStateToProps, mapDispatchToProps)(LivePlayerCtrl)