import { internetIsConnected } from "@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper";

let TRIAL = 0;
let LIMIT = 5;
let TIMER = 1000 * 5;
let HOSTED_PLATFORMS = ["HISENSE", "XBOX", "BROWSER"];

export const preLoadFonts = () => {
  if (window.FontFace) {
    var robotoMediumFontFace = new FontFace('Roboto Medium', 'url(src/fonts/Roboto-Medium.woff)');
    document.fonts.add(robotoMediumFontFace);
    var robotoBoldFontFace = new FontFace('Roboto Bold', 'url(src/fonts/Roboto-Bold.woff)');
    document.fonts.add(robotoBoldFontFace);
    var robotoRegularFontFace = new FontFace('Roboto Regular', 'url(src/fonts/Roboto-Regular.woff)');
    document.fonts.add(robotoRegularFontFace);
  }
};
try {
  document.fonts.onloadingdone = function (fontFaceSetEvent) {
    checkAndLoad(fontFaceSetEvent);
  };
}
catch (e) {
  console.error(e);
}

const checkAndLoad = fontFaceSetEvent => {
  if (window.FontFace) {
    if (
      !fontFaceSetEvent.fontfaces.length &&
      TRIAL < LIMIT &&
      HOSTED_PLATFORMS.includes(localStorage.PRODUCT_TYPE)
    ) {
      if (internetIsConnected()) {
        TRIAL++;
        preLoadFonts();
      } else {
        console.error('""Roboto"" font failed,--- Will fetch again in 5 sec');
        setTimeout(() => {
          checkAndLoad(fontFaceSetEvent);
        }, TIMER);
      }
    }
  }
};
