import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SEARCH, SEARCH_OPEN, SEARCH_SWITCH_KEYBOARD, EDITORIALS, SEARCH_ERROR, SEARCH_ERROR_CLEAR, MAIN_COMPONENT_FOCUS, SET_MENU_INDEX } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { TvSearch, TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { prepareSearchKey, SEARCH_KEY_LIMIT } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/helper';
import { KEY_CODE } from '../../keyCodeMapping';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../helper';
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { checkDeepLinkLogic } from "../helper"

class Search extends Component {
  constructor(props) {
    super(props);
    window.addEventListener('keydown', this.getRemoteKeys);
  }

  componentWillMount() {
    if ((!this.props.search.data || (this.props.search.data && !this.props.search.data.length))) {
      //if error occured or empty data
      this.props.getSearchKeyboard();
    }
    if (this.props.menu && this.props.menu.data && this.props.menu.data.length && !this.checkGridpresent()) {
        checkDeepLinkLogic(this.props, "search", "search")
    } 
  }
  checkGridpresent() {
    return (this.props.search && this.props.search.data && this.props.search.data.length && this.props.search.data.findIndex(e => e.renderType === "Poster") > -1)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.search && nextProps.search.error && nextProps.search.serverError) {
      postAPIErrorToSegment(nextProps.search.error, nextProps.search.serverError)
    }
  }

  getRemoteKeys = (e) => {
    let listBoardData = this.props.search && this.props.search.data && this.props.search.data.filter(item => (item && (item.renderType) && (item.renderType === 'listBoard'))).pop()
    if (e.keyCode >= KEY_CODE[0] && e.keyCode <= KEY_CODE[9] && (listBoardData.items[1].isActive === true)) {
      let searchKey = prepareSearchKey(e.key, this.props)
      postSegmentData(e.key, 'Search')
      if (internetIsConnected()) {
        if (searchKey.length <= SEARCH_KEY_LIMIT)
          this.props.getSearch(e, searchKey)
        else 
          return {}
      } else {
        this.props.throwNetworkError(
          networkPayload({
            callback: () => { this.getRemoteKeys(e) },
            page: 'search',
            value: searchKey,
            fromKeyboard: true
          }))
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.getRemoteKeys, false);
      if(window.event && (window.event.type === 'hashchange' || window.event.type === 'keydown')){
      this.props.clearSearch('clearAll')
    } 
  }

  searchOnclick = (value, renderType) => {
    let searchKey = prepareSearchKey(value === 'space' ? ' ' : value, this.props)
    let payload = {
      focusedButton: {
        'key': 'search',
        item: renderType === "listBoard" ? this.props.search.data[3] : renderType === "keyBoard" ? this.props.search.data[1] : renderType === "symbols" ? this.props.search.data[2] : []
      },
      value: value
    }

    if (internetIsConnected()) {
      this.props.getSearch(payload, searchKey)
      this.props.manageSearchFocusOnclick(value, renderType)
    } else {
      this.props.throwNetworkError(
        networkPayload({
          callback: () => {
              this.props.getSearch(payload, searchKey)
              this.props.manageSearchFocusOnclick(value, renderType)
            }
        }))
    }
  }

  onListBoardClick = (e) => {
    let value = e.currentTarget.getAttribute('value')
    let renderType = e.currentTarget.getAttribute('renderType')
    postSegmentData(value, 'Search')
    if (value === 'abc' || value === '#+-' ) {
      this.props.switchKeyboard(e, true)
      this.props.manageSearchFocusOnclick(value, renderType)
    } else if (value === 'space' || value === 'clear')
      this.searchOnclick(value, renderType)
  }

  onKeyboardClick = (e) => {
    let value = e.currentTarget.getAttribute('value')
    let renderType = e.currentTarget.getAttribute('renderType')
    postSegmentData(value, 'Search')
    window.prevFocusedIndex = value
    window.prevSearchkey = prepareSearchKey(value, this.props)
    this.searchOnclick(value, renderType)
  }

  onCardSelected = (item) => {
    if (internetIsConnected()) {
      this.props.history.push(item.linkTo)
      this.props.getShowPage({ key: 'search' })
    } else {
      this.props.throwNetworkError(
        networkPayload({
          callback: () => { this.onCardSelected(item) },
          page: 'search'
        }))
    }
  }

  onErrorActionSelected = (e) => {
    if (this.props.search.error.callback) {
      let type = this.props.search.error.callback(internetIsConnected())
      if (type) this.props.retrySearch(type)
    } else {
      this.props.retrySearch(e.retryAction)
      this.props.getSearch(e, window.prevSearchkey)
    }
  }

  onErrorBackSelected = (e) => {
    this.props.clearError(this.props.search.error)
  }

  render() {
    let data = (this.props.search.data && this.props.search.data.length) ? this.props.search.data : {};
    let error
    if (this.props.search && this.props.search.error)
      error = <TvError {...this.props.search.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
    return <React.Fragment>
      <TvSearch
        data={data}
        onKeyboardClick={this.onKeyboardClick}
        onListBoardClick={this.onListBoardClick}
        fromSmartTv={true}
        isLoading={this.props.search.isLoadingSearch || this.props.search.isLoading}
        onCardSelected={this.onCardSelected}
        isSearchError={this.props.search.error}
      />
      {error}
    </React.Fragment>
  }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    search: state.search,
    menu: state.menu

  }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  getSearch: (payload, searchKey) => dispatch({ type: SEARCH, payload, searchKey }),
  getSearchKeyboard: () => dispatch({ type: SEARCH_OPEN }),
  switchKeyboard: (payload, isClicked) => dispatch({ type: SEARCH_SWITCH_KEYBOARD, payload, isClicked }),
  clearError: (payload) => dispatch({ type: SEARCH_ERROR_CLEAR, payload }),
  getShowPage: (payload) => dispatch({ type: EDITORIALS, payload }),
  retrySearch: (action) => dispatch(action),
  throwNetworkError: (payload) => dispatch({ type: SEARCH_ERROR, payload }),
  checkMainComponentFocus: (page) => dispatch({type: MAIN_COMPONENT_FOCUS, payload:{key:'search'}}),
  setMenuIndex:(index) => dispatch({type: SET_MENU_INDEX, payload:{index: 0}}),
  clearSearch:(payload) => dispatch({type: 'CLEAR_SEARCH', payload}),
  onClickSearchKeyboard: (payload, searchKey) => dispatch({type: 'SEARCH', payload, searchKey}),
  manageSearchFocusOnclick: (searchKey, renderType) => dispatch({type: 'SEARCH_CLICK', searchKey, renderType })
})

export default connect(mapStateToProps, mapDispatchToProps)(Search);