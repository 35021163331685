export const NAME_MAX_LIMIT = 10;

export const pushProfileListScreen = (history, payload) => {
    history.push({ pathname: '/profiles', payload });
}

export const pushProfileNameScreen = (history, payload) => {
    window.profileHistory = window.profileHistory || []
    window.profileHistory.unshift(payload)
    history.push({ pathname: 'edit/name', payload });
}

export const pushSaveScreen = (history, payload) => {
    history.push({ pathname: '/profiles/save', payload });
}

export const pushDeleteScreen = (history, payload) => {
    history.push({ pathname: '/profiles/delete', payload });
}

export const pushProfileAvatarScreen = (history, payload = {}) => {
    window.profileHistory = window.profileHistory || []
    window.profileHistory.unshift(payload)
    history.push({ pathname: '/profiles/' + (payload.isNewProfile ? 'new' : 'edit'), payload: payload });
}

export const checkProfileErrors = (payload) => {
    return (payload.profiles.saveProfileError && payload.profiles.saveProfileError.isVisible) ||
        (payload.profiles.editProfileError && payload.profiles.editProfileError.isVisible) ||
        (payload.profiles.error)
}

export const checkGenericError = (payload) => {
    return (payload.profiles.saveProfileError && payload.profiles.saveProfileError.genericError) ||
        (payload.profiles.editProfileError && payload.profiles.editProfileError.genericError)
}