import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div.attrs(({ stopScroll, index, scrollWidth, scrollHeight }) => ({
    style: {
        left: !stopScroll && `${index && !scrollHeight ? scrollWidth : 0}vw`,
        top: !stopScroll && `${index && scrollHeight ? scrollHeight : 0}vw`
    }
})) `position: relative`

const getIndex = (props) => {
    let index = 1
    if (props.cards && props.cards.shouldScroll) {
        index = props.cards.findIndex(function (e, i) {
            return e['isTvFocus'] === true;
        });

        if (index === -1 || !props.cards.shouldScroll) {
            index = 0
        }
    }
    else if (props.cards) {
        index = props.cards.findIndex(function (e, i) {
            return e['isTvFocus'] === true;
        });

        if (index === -1 || !props.shouldScroll) {
            index = 0
        }
    }
    return index
}

const styledEditorial = (props, carousel) => {
    return (<StyledWrapper index={getIndex(props)} stopScroll={props.stopScroll} scrollWidth={props.cards && props.cards.scrollWidth ? props.cards.scrollWidth: props.scrollWidth} scrollHeight={props.scrollHeight}>
        {carousel}
    </StyledWrapper>)
}
export default styledEditorial