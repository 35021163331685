import React, { Component } from 'react'
import { connect } from 'react-redux'
import { StreamaAppList } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { talkToChannel } from '@connected-video-web/streama-system-manager-service/dist/services/talkToChannel';
import { APP_LIST, APP_LIST_CLEAR, MAIN_COMPONENT_FOCUS, SET_MENU_INDEX, CATCHUP_CLEAR } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { styledEditorial } from '../styledComponents'
import { checkDeepLinkLogic } from "../helper"

class StreamaAppLists extends Component {

    componentWillUnmount = () => {
        this.props.clearStreamaAppList();
    }

    componentWillMount = () => {
        if (!this.props.streamaapplist.isLoading) {
            this.props.getStreamaAppList()
            this.props.clearCatchup() //This thing is pesky, go away!
        }
        let path = this.props.location.pathname.split('/')
        if (this.props.menu && this.props.menu.data && this.props.menu.data.length && path[1] !== 'profiles') {
            checkDeepLinkLogic(this.props, "apps", "streamaapplist")
        }
    }

    componentWillReceiveProps(nextProps) {
        let path = this.props.location.pathname.split('/')
        if (this.props.menu && this.props.menu.data && this.props.menu.data.length && !nextProps.streamaapplist.menuFocused && path[1] !== 'profiles') {
            checkDeepLinkLogic(this.props, "apps", "streamaapplist")
        }
        if (nextProps.streamaapplist && nextProps.streamaapplist.isAppSelected) {
            let focusedItem = nextProps.streamaapplist.data.filter(item => item.isTvFocus).pop()
            if (focusedItem && localStorage.PRODUCT_TYPE === 'STREAMA'){ 
                focusedItem.appLocation = 'Apps';
                postSegmentData(focusedItem.appName, { eventCategory: "STREAMA" }, focusedItem.appLocation);
                talkToChannel(focusedItem);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <StreamaAppList
                    cards={this.props.streamaapplist.data}
                    styledCarousel={styledEditorial}
                    isLoading={this.props.streamaapplist.isLoading}
                    isGrid={this.props.streamaapplist.isGrid}
                    onSelected={this.onSelected}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        streamaapplist: state.streamaapplist,
        menu: state.menu
    }
};

const mapDispatchToProps = dispatch => ({
    clearCatchup: () => dispatch({ type: CATCHUP_CLEAR }),
    getStreamaAppList: () => dispatch({ type: APP_LIST }),
    clearStreamaAppList: () => dispatch({ type: APP_LIST_CLEAR }),
    checkMainComponentFocus: (page) => dispatch({ type: MAIN_COMPONENT_FOCUS, payload: { key: page } }),
    setMenuIndex: (index) => dispatch({ type: SET_MENU_INDEX, payload: { index } })
})

export default connect(mapStateToProps, mapDispatchToProps)(StreamaAppLists);
