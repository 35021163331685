import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import dstvLogo from '../Images/dstv_login_logo.png'
import { MENU, SECTIONS, SECTIONS_LIVE, MENU_CLICK, SECTION_PROPS, MENU_ERROR, MENU_ERROR_CLEAR, SECTION_CLICK, MENU_FOCUS, SECTIONS_CHANNELS_GROUPS, ENTITLEMENT, GET_USER_INFO} from '@connected-video-web/dstv-frontend-services/dist/actions'
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';

import { NavSideBar } from '@connected-video-web/dstv-frontend-components/dist/Components/Collection';
import { getFocusedIndex, focusKey, getCurrentFocusedComponent } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { pageType } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload, getFocusedMenuId } from './helper';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import {GLOBALS} from "../globals";
import SessionHelper from "@connected-video-web/dstv-frontend-services/dist/Utils/sessionHelper";
import { getDeviceName } from "@connected-video-web/dstv-frontend-services/dist/Utils/platform";
import { secureLocalStorage, secureSessionStorage } from "@connected-video-web/dstv-frontend-middleware/dist/Utils/secureStorage";
let load = true;
class Menu extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      scrollWidthSet: false,
      stopLimit: 4 //(n-4)
    }
  }

  componentWillMount = () => {
    if (!this.checkProfileEnabled(this.props) || this.checkProfileSwitched(this.props)) {
      this.loadAllMenus()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.checkProfileSwitched(nextProps) || this.checkAppRestarted(nextProps)) {
      this.loadAllMenus()
    }
    if (!(this.props.profile && this.props.profile.isSearchIconFocused) && (nextProps.profile && nextProps.profile.isSearchIconFocused)) {
    }
    if (this.isSection && !this.state.scrollWidthSet) {
      this.setState({ ...this.state, scrollWidthSet: true })
    }
    if (nextProps && nextProps.menu && nextProps.menu.error && nextProps.menu.serverError) {
      postAPIErrorToSegment(nextProps.menu.error, { errorMessage: nextProps.menu.serverError.reason || nextProps.menu.serverError || 'API ERROR' })
    }
  }

  componentDidUpdate() {
    if (secureLocalStorage.getItem("userPackage") && load) {
      this.props.getLiveSection();
      load = false;
  }
    if (this.isSection && this.state.scrollWidthSet) {
      if (this.props.sections.data_livetv &&
        this.props.sections.data_livetv.data &&
        this.props.sections.data_livetv.data[0] &&
        this.props.sections.data_livetv.data[0].items &&
        this.props.sections.data_livetv.data[0].items[0].scrollWidth === undefined) {
        this.props.setSectionScrollWidth({
          stopLimit: this.state.stopLimit,
          myRef: this.myRef
        })
      }
    }
  }

  loadAllMenus = () => {
    if (internetIsConnected()) {
      this.props.getMenu({user: this.props.user ? this.props.user : null});
      this.props.getLiveSection();
      this.props.getChannelGroups()
      this.props.getUserInfo();
      this.setState({ ...this.state, scrollWidthSet: false })
    } else {
      this.props.throwNetworkError({
        error:
          networkPayload({
            callback: () => { this.loadAllMenus() },
            toggleText: true
          })
      })
    }
  }

  checkProfileSwitched = (nextProps) => {
    return (this.checkProfileEnabled(nextProps) &&
      this.isEmptyObject(nextProps.menu) &&
      this.isEmptyObject(nextProps.sections))
  }

  checkAppRestarted = (nextProps) => {
    return !nextProps.menu.data && !nextProps.menu.isLoading && !nextProps.menu.error && !nextProps.menu.exited
  }

  checkProfileEnabled = (nextProps) => {
    return nextProps.profiles.activeProfile || null
  }

  isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0
  }

  onErrorActionSelected = (e) => {
    if (this.props.menu.error.callback) {
      let type = this.props.menu.error.callback(internetIsConnected())
      if (type) this.props.retryAction(type)
    }
    else this.props.retryAction(e.retryAction)
  }

  onErrorBackSelected = (e) => {
    this.props.clearMenuError(this.props.menu.error)
  }

  onClick = (data) => {
    let self = this
    let path = this.props.location.pathname.split('/')
    let componentKey = (data.id === 'sport' || data.id === 'movies' || data.id === 'kids' || data.id === 'mylist' || data.id === 'tvshows') ? 'catchUp' : data.id
    if (!path[1]) path[1] = pageType.home
    if (path[1] !== data.id) {
      if (this.props.menu.data.filter(item => item.id === data.id).pop() || (data.id === 'profiles')) {
        this.props.changeMenuFocus({ id: data.id })
        setTimeout(() => {
          self.manageOnclickComponentFocus(data)
        }, 500);
      }
    } else if (path[1] === data.id) {
      self.manageOnclickComponentFocus(data)
    }
  }

  manageOnclickComponentFocus = (data) => {
    let index = getFocusedIndex(this.props.menu.data, focusKey.isActive)
    if (data.id === 'search') {
      //this.props.setMainComponentFocus({key: 'search'})
    } else if (data.id === 'sport' || data.id === 'movies' || data.id === 'kids' || data.id === 'mylist' || data.id === 'tvshows') {
      if (this.props.sections && this.props.sections.data_catchup && this.props.sections.data_catchup.data && this.props.sections.data_catchup.data.length && this.props.sections.data_catchup.data[0].items && this.props.sections.data_catchup.data[0].items.length) {
        this.props.setMainComponentSectionFocus({page:'catchup'})
      }
    } else if (data.id === 'livetv') {
      if (this.props.sections && this.props.sections.data_livetv && this.props.sections.data_livetv.data && this.props.sections.data_livetv.data.length && this.props.sections.data_livetv.data[0].items && this.props.sections.data_livetv.data[0].items.length) {
        this.props.setMainComponentSectionFocus({page:'livetv'})
      }
    } else if (data.id === 'channelgroups') {
      if (this.props.sections && this.props.sections.data_channelgroups && this.props.sections.data_channelgroups.data && this.props.sections.data_channelgroups.data.length && this.props.sections.data_channelgroups.data[0].items && this.props.sections.data_channelgroups.data[0].items.length) {
        this.props.setMainComponentSectionFocus({page:'channelgroups'})
      }
    } else if (data.id === 'settings') {
      this.props.setMainComponentFocus({key: 'streamasettings'})
    } else if (data.id === 'home') {
      this.props.setMainComponentFocus({key: 'editorial'})
    } else if (data.id === 'profiles') {
      this.props.setMainComponentFocus({key: 'profiles'})
    }
  }

  checkErrorPresent = () => {
    return (((!this.props.editorial.data || this.props.editorial.data && !this.props.editorial.data.length) && (((this.props.editorial.error && this.props.editorial.error.isVisible) || (this.props.editorial.isLoadingEditorial || this.props.editorial.isLoading)) && getCurrentFocusedComponent().key === 'menu')
      && (getFocusedMenuId(this.props) !== 'search')) ||
      this.props.sections.error && this.props.sections.error.isVisible ||
      this.props.menu.data && (this.props.menu.error && this.props.menu.error.isVisible) ||
      (this.props.profiles.error && !this.props.profiles.saveProfileError && !this.props.profiles.editProfileError && getCurrentFocusedComponent().key === 'menu') ||
      this.props.channels.error && !this.props.channels.isAborted && (getCurrentFocusedComponent().key === 'menu' || getCurrentFocusedComponent().key === 'sections') ||
      (this.props.catchUp.error && getCurrentFocusedComponent().key !== 'catchUp' && !this.props.catchUp.error.isCardSelected) ||
      this.props.watchlist.error && getCurrentFocusedComponent().key !== pageType.watchlist && !this.props.watchlist.error.isCardSelected)
  }
  settingsFocused = () => {
    return (this.props.settings && this.props.settings.data && this.props.settings.data[0].isTvFocus)
  }
  getMenuData = () => {
    let index
    this.isSection = false
    if (this.props.menu.data && this.props.menu.data.length > 0) {
      index = getFocusedIndex(this.props.menu.data, focusKey.isActive)
      if (this.props.menu.data[index] &&
        this.props.sections['data_' + this.props.menu.data[index].title] &&
        this.props.sections['data_' + this.props.menu.data[index].title].data &&
        this.props.sections['data_' + this.props.menu.data[index].title].data.length &&
        this.props.sections['data_' + this.props.menu.data[index].title].data[0].items &&
        this.props.sections['data_' + this.props.menu.data[index].title].data[0].items.length &&
        getFocusedIndex(this.props.menu.data, focusKey.isTvFocus) === -1 &&
        (!this.props.channels.error || (this.props.channels.error && getCurrentFocusedComponent().key === 'sections')) &&
        (!this.props.catchUp.error || (this.props.catchUp.error && getCurrentFocusedComponent().key === 'sections'))
        && (this.props.profiles.activeProfile && !this.props.profiles.activeProfile.isTvFocus)) {
        this.isSection = this.props.menu.data[index].title === 'livetv'
        return this.props.sections['data_' + this.props.menu.data[index].title].data[0].items
      }
      return this.props.menu.data
    }
    return []
  }

  getProfileIcon = (data) => {
    if (this.props.menu.data &&
      this.props.menu.data.length &&
      data[0].id === this.props.menu.data[0].id &&
      this.props.profiles.activeProfile &&
      this.props.profiles.activeProfile.avatar &&
      this.props.profiles.activeProfile.avatar.uri
    ) {
      return {
        src: this.props.profiles.activeProfile.avatar.uri,
        isTvFocus: this.props.profiles.isSearchIconFocused ? false : this.props.profiles.activeProfile.isTvFocus
      }
    }
    return {}
  }
  checkSeachIsFocused = (props) =>{
     if(props.profiles && props.profiles.isSearchIconFocused){
      return {
        isTvFocus : props.profiles.isSearchIconFocused
      }
     }
  }

  getLogo = () => {
    if (this.props.menu.data && this.props.menu.data.length) {
      let index = getFocusedIndex(this.props.menu.data, focusKey.isActive)
      if (this.props.menu.data[index] && this.props.menu.data[index].id === pageType.home &&
        this.props.editorial.data && this.props.editorial.data.length) {
        return dstvLogo
      }
    }
    return null
  }

  getScrollWidth = (data) => {
    return (data.filter(item => item.isActive).pop() || {}).scrollWidth || 0
  }

  render() {
    let data = this.getMenuData()
    // let isMenuFocused = data && data.filter(item => item.isTvFocus).pop()
    let searchIcon = this.checkSeachIsFocused(this.props)
    let error = ''
    if (this.props.menu && this.props.menu.error) {
      error = <TvError {...this.props.menu.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
    }
    let profileData = this.getProfileIcon(data)
    return (
      <React.Fragment>
        <NavSideBar
          data={data}
          isLoading={this.props.menu.isLoading}
          isSection={this.isSection}
          logo={this.getLogo()}
          onClick={this.onClick}
          profileIcon={profileData}
          searchIcon = {searchIcon}
          refProps={this.myRef}
          scrollWidth={this.getScrollWidth(data)}
          hideOverlay={this.props.hideOverlay}
        />
        {error}
      </React.Fragment>)
  }
}
/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    channels: state.channels,
    catchUp: state.catchUp,
    editorial: state.editorial,
    sections: state.sections,
    profiles: state.profiles,
    settings: state.settings,
    menu: state.menu,
    flagr: state.flagr,
    watchlist: state.watchlist,
    pin: state.pin,
    user: state.user
  }
};

/**
 * 
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => ({
  getMenu: (payload) => dispatch({ type: MENU, payload }),
  getLiveSection: (payload) => dispatch({ type: SECTIONS_LIVE, payload }),
  getChannelGroups: (payload) => dispatch({ type: SECTIONS_CHANNELS_GROUPS, payload }),
  changeMenuFocus: (payload) => dispatch({ type: MENU_CLICK, payload }),
  changeSectionFocus: (payload) => dispatch({ type: SECTION_CLICK, payload }),
  retryAction: (action) => dispatch(action),
  setSectionScrollWidth: (payload) => dispatch({ type: SECTION_PROPS, payload }),
  clearMenuError: () => dispatch({ type: MENU_ERROR_CLEAR }),
  throwNetworkError: (payload) => dispatch({ type: MENU_ERROR, payload }),
  setMainComponentFocus: payload => dispatch({type: 'MAIN_COMPONENT_FOCUS', payload}),
  setMainComponentSectionFocus: payload => dispatch({type: 'MAIN_COMPONENT_SECTION_FOCUS', payload}),
  DoEntitlement: (payload) => dispatch({ type: ENTITLEMENT, payload: payload }),
  getUserInfo: () => dispatch({ type: GET_USER_INFO }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));