import React from 'react'
import { render } from 'react-dom'
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import App from './app'
import { store, history } from './store'
import "./base-styles/main.css"
import "./base-styles/main.scss"
import "./base-styles/skin64.css"
import { preLoadFonts } from './loadFont';
// import './libs/screenlog'
// screenLog.init()

window.isSmartTv = true;
//TODO:
//Refactor this
window.isExplora = localStorage.getItem('PRODUCT_TYPE') === 'EXPLORA_ULTRA';
window.isStreama = localStorage.getItem('PRODUCT_TYPE') === 'STREAMA';
window.isAndroidTV = localStorage.getItem('PRODUCT_TYPE') === 'ANDROID_TV';
preLoadFonts()

render(
  <Provider store={store}>
    <HashRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
)