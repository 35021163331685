import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SECTIONS, CATCHUP, CATCHUP_CLEAR, CATCHUP_ERROR_CLEAR, CATCHUP_PAGING, SECTIONS_ERROR_CLEAR, SECTIONS_ERROR, SHOW_FILTER_OVERLAY, SHOWPAGE_SHOW, CATCHUP_ERROR, STREAMA_BILLBOARD, CLEAR_STREAMA_BILLBOARD } from "@connected-video-web/dstv-frontend-services/dist/actions";
import { TvCatchup, TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { styledList } from '../styledComponents'
import { StreamaBillboard } from '@connected-video-web/dstv-frontend-components/dist/Components/Container';
import { ErrorList } from '@connected-video-web/dstv-frontend-services/dist/constants/errorMessages';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload, checkSectionDataPresent, getFocusedMenuId } from '../helper'
import { getFocusedIndex, focusKey } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { pageType } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';
import { GetBoxOfficeMovie, GetMovieStatus } from '@connected-video-web/dstv-frontend-services/dist/transformation/catchup.transform';

class Catchup extends Component {

  componentWillMount() {
    //only on first time loading
    if (this.props.menu.data)
      if (this.props.menu.data.length > 0)
        if (!this.props.menu.isLoading) {
          const focusMenu = this.props.menu.data && this.props.menu.data.filter(item => item.isTvFocus).pop()
          let category
          if (focusMenu)
            category = focusMenu.id;
          else
            category = this.props.location.pathname.split("/").pop();
          if (focusMenu)
            this.getSelectedSectionCatchup(category);
        }
  }

  throwSectionError = (props) => {
    let category = this.props.location.pathname.split("/").pop();
    this.props.throwSectionError({
      ...props.sections.data_catchup.error,
      retryAction: {
        type: SECTIONS,
        payload: {
          filter: { categoryId: category }
        }
      }
    })
  }

  componentWillUnmount = () => {
    this.props.clearCatchup();
  }

  componentWillReceiveProps = (nextProps) => {
    //on section change 
    if (nextProps && nextProps.catchUp && nextProps.catchUp.error && nextProps.catchUp.serverError) {
      postAPIErrorToSegment(nextProps.catchUp.error, nextProps.catchUp.serverError)
    }

    if (this.props.location.pathname !== nextProps.location.pathname &&
      nextProps.location.pathname.split('/')[2] !== 'play' &&
      this.props.location.pathname.split('/')[2] !== 'play' &&
      !nextProps.catchup.isPageLoading &&
      !nextProps.location.pathname.split('/')[3] &&
      !this.props.location.pathname.split('/')[3]) {
      let category = nextProps.location.pathname.split("/").pop();
      this.getSelectedSectionCatchup(category);
    }

    //throwing after retry on error
    if ((this.props.sections.error && !this.props.sections.error.isVisible) &&
      nextProps.sections.data_catchup && nextProps.sections.data_catchup.error && nextProps.sections.data_catchup.error.isVisible &&
      this.props.sections.data_catchup && !this.props.sections.data_catchup.error) {
      this.throwSectionError(nextProps)
    }

    let category = this.props.menu.data.filter(item => {
      return item.isActive === true;
    });
    if (category && category.length && category[0].id === 'channelgroups' && nextProps.catchup && nextProps.catchup.data && nextProps.catchup.data.length) {
      let data = nextProps.catchup.data.filter(item => {
        return item.menu === 'channelgroups'
      })
      if (data && data[0] && data[0].items) {
        const focusedIndex = getFocusedIndex(data[0].items, focusKey.isTvFocus);
        if (focusedIndex !== -1) {
          const focusedCard = data[0].items[focusedIndex];
          this.props.streamaBillboard(focusedCard);
        } else {
          this.props.clearStreamaBillboard();
        }
      }
    } else if (nextProps.catchup && nextProps.catchup.data && nextProps.catchup.data.length === 3) {
      if (nextProps.catchup.data[2] && nextProps.catchup.data[2].items.length) {
        const focusedIndex = getFocusedIndex(nextProps.catchup.data[2].items, focusKey.isTvFocus);
        if (focusedIndex !== -1) {
          const focusedCard = nextProps.catchup.data[2].items[focusedIndex];
          this.props.streamaBillboard(focusedCard);
        } else {
          this.props.clearStreamaBillboard();
        }
      }
    }
  }

  componentDidUpdate() {
    if (getFocusedMenuId(this.props) === pageType.catchup &&
      checkSectionDataPresent(this.props, 'data_catchup') && this.shouldfetchCatchup(this.props)) {
      // if section was not loaded initially, then retry of error message catchup also should load
      let category = this.props.location.pathname.split("/").pop();
      //this.getSelectedSectionCatchup(category);
    }
  }

  componentDidCatch = (error, info) => {
    console.log(error, info, "!!!!!!!!!!! COMPONENT ERROR ********");
  }

  shouldfetchCatchup = (props) => {
    return (!props.catchup.data && !props.catchup.isLoading && !props.catchup.error && !props.catchup.isAborted)
  }

  getSelectedSectionCatchup = (category) => {
    if (internetIsConnected()) {
      let selectedCategory = this.props.menu.data.filter(item => {
        return item.isActive === true;
      });

      let selectedSubSection = []
      if (selectedCategory && selectedCategory[0] && selectedCategory[0].id === "channelgroups") {
        selectedSubSection = this.props.sections.data_channelgroups && this.props.sections.data_channelgroups.data && this.props.sections.data_channelgroups.data.length && this.props.sections.data_channelgroups.data[0].items.filter(item => {
          return item.title === category && item.isActive === true;
        });

        if (selectedSubSection && selectedSubSection.length === 0)
          selectedSubSection.push(this.props.sections.data_channelgroups.data[0].items[0])
      } else if (selectedCategory && selectedCategory.length > 0) {
        selectedSubSection = this.props.sections.data_catchup && this.props.sections.data_catchup.data && this.props.sections.data_catchup.data.length && this.props.sections.data_catchup.data[0].items.filter(item => {
          return item.id === category;
        });
      }

      let error = "";
      if (!error && (selectedCategory.length > 0 || selectedSubSection.length > 0)) {
        this.props.getCatchup({
          url: selectedSubSection && selectedSubSection.length > 0 && selectedSubSection[0].url ? selectedSubSection[0].url : selectedCategory.length && selectedCategory[0].url && selectedCategory[0].url.listing && selectedCategory[0].url.listing.url && selectedCategory[0].id !== 'channelgroups' ? selectedCategory[0].url.listing.url : '',
          filter: {
            genre: "ALL",
            sortOrder: "ALL",
            pageNo: 0,
            menu: selectedCategory[0].id,
            title: selectedSubSection && selectedSubSection.length > 0 ? selectedSubSection[0].title : selectedCategory.length && selectedCategory[0].displayName
          },
          from: (this.checkMenuNotFocused() && !this.props.catchup.error) ? 'catchup' : 'menu'
        });
      }
    } else {
      this.props.throwNetworkError({
        error:
          networkPayload({
            callback: () => { this.getSelectedSectionCatchup(category) },
            from: (this.checkMenuNotFocused() && !this.props.catchup.error) ? 'catchup' : 'menu'
          })
      })
    }
  }

  checkMenuNotFocused = () => {
    return ((this.props.sections.data_catchup && this.props.sections.data_catchup.data && this.props.sections.data_catchup.data.length && getFocusedIndex(this.props.sections.data_catchup.data[0].items, focusKey.isTvFocus) === -1) &&
      getFocusedIndex(this.props.menu.data, focusKey.isTvFocus) === -1)
  }

  onErrorActionSelected = (errorType) => (item) => {
    if (errorType === 'sections') {
      let category = this.props.location.pathname.split("/").pop();
      this.props.getSections({
        filter: {
          categoryId: category
        }
      });
    } else {
      if (this.props.catchup.error.callback) {
        let type = this.props.catchup.error.callback(internetIsConnected())
        if (type) {
          this.props.retryCatchup(type)
        }
      }
      else this.props.retryCatchup(item.retryAction)
    }
  }

  onErrorBackSelected = (errorType) => (item) => {
    if (errorType === 'sections') {
      this.props.clearSectionError()
    }
    else {
      this.props.clearCatchupError(this.props.catchup.error)
    }
  }

  onFilterButtonSelected = (item) => {
    this.props.showOverlay({ title: item.filterTitle })
  }

  onFilterOverlaySelected = (item) => {
    console.log('e', item)
  }

  onCardSelected = (item) => {
    let activeMenu = this.props.menu.data.filter(item => { return item.isActive === true });
    if (activeMenu && activeMenu.length) {
      item = Object.assign({}, item, { from: activeMenu[0].id })
    }
    if (internetIsConnected()) {
      this.props.history.push({
        pathname: item.linkTo,
        payload: item
      });
      this.props.pushShowPage(item)
    } else {
      this.props.throwNetworkError({
        error:
          networkPayload({
            callback: () => {
              this.onCardSelected(item)
            },
            isCardSelected: true,
            from: 'catchup'
          })
      })
    }
  }

  onBoxOfficeMovieFocus = (item) => {
    if (internetIsConnected()) {
      this.props.streamaBillboard(item);
    }
  }

  render() {
    let data = (this.props.catchup.data || [])
    let channelsGridData;
    let activeMenu = this.props.menu.data.filter(item => { return item.isActive === true });
    let error
    let isTvFocus = data.filter(item => item.isTvFocus).pop()
    if (this.props.sections.error && this.props.sections.error.isVisible)
      error = <TvError {...this.props.sections.error} onSelected={[this.onErrorActionSelected('sections'), this.onErrorBackSelected('sections')]} />
    if (this.props.catchup.error && this.props.catchup.error.isVisible) {
      error = <TvError {...this.props.catchup.error} onSelected={[this.onErrorActionSelected('catchup'), this.onErrorBackSelected('catchup')]} />
    }
    isTvFocus = isTvFocus || (this.props.catchup.error && this.props.catchup.error.isCardSelected)

    if (data && data.length) {
      channelsGridData = data.filter(item => {
        return item.menu === 'channelgroups'
      })
    }
    if (this.props.showpage.data) isTvFocus = true
    return <React.Fragment>
      <StreamaBillboard
        isCatchupBillboard={true}
        isPageContentTvFocus={isTvFocus}
        showBillboard={activeMenu && activeMenu[0].id === 'channelgroups' && channelsGridData && channelsGridData.length && channelsGridData[0] && channelsGridData[0].isTvFocus ? true : activeMenu && activeMenu[0].id !== 'channelgroups' && data && data[2] ? true : false}
      />
      <TvCatchup data={data}
        styledList={styledList}
        onFilterButtonSelected={this.onFilterButtonSelected}
        onFilterOverlaySelected={this.onFilterOverlaySelected}
        noContent={this.props.sections.data_catchup && !this.props.catchup.isAborted && this.props.sections.data_catchup.data && !this.props.catchup.error && ErrorList.noContent}
        isLoading={this.props.catchup.isLoading || false}
        isPageLoading={this.props.catchup.isPageLoading}
        onCardSelected={this.onCardSelected}
        isTvFocus={isTvFocus}
        getMovie={GetBoxOfficeMovie}
        getMovieStatus={GetMovieStatus}
        onBoxOfficeMovieFocus={this.onBoxOfficeMovieFocus}
        type={this.props.catchup && this.props.catchup.data && this.props.catchup.data.length && this.props.catchup.data[0].type ? this.props.catchup.data[0].type : ""}
        isParentTvFocus={data && data.length && data[0].isTvFocus || false}
      />
      {error}
    </React.Fragment>
  }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    catchup: state.catchUp,
    sections: state.sections,
    showpage: state.showpage,
    menu: state.menu
  }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
  getCatchup: (payload) => dispatch({ type: CATCHUP, payload }),
  getSections: (payload) => dispatch({ type: SECTIONS, payload }),
  clearCatchup: () => dispatch({ type: CATCHUP_CLEAR }),
  catchUpPaging: (payload) => dispatch({ type: CATCHUP_PAGING, payload }),
  throwSectionError: (payload) => dispatch({ type: SECTIONS_ERROR, payload }),
  showOverlay: (payload) => dispatch({ type: SHOW_FILTER_OVERLAY, payload }),
  retryCatchup: (action) => dispatch(action),
  clearSectionError: () => dispatch({ type: SECTIONS_ERROR_CLEAR }),
  clearCatchupError: (payload) => dispatch({ type: CATCHUP_ERROR_CLEAR, payload }),
  pushShowPage: (payload) => dispatch({ type: SHOWPAGE_SHOW, payload }),
  throwNetworkError: (payload) => dispatch({ type: CATCHUP_ERROR, payload }),
  streamaBillboard: (payload) => dispatch({ type: STREAMA_BILLBOARD, payload }),
  clearStreamaBillboard: () => dispatch({ type: CLEAR_STREAMA_BILLBOARD })
})

export default connect(mapStateToProps, mapDispatchToProps)(Catchup);