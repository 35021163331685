import React, { Component } from 'react'
import { connect } from 'react-redux'
import {PLAY, PAUSE} from '@connected-video-web/dstv-frontend-services/dist/actions';
import styles from '../../base-styles/playerui.scss';
import { KeyCode } from '../../keyCodeMapping';
import { GLOBALS } from '../../globals';
import PLAY_ICON from '../../Images/play_icon.png';

class PlayerInactiveDlg extends Component {
  constructor(props) {
    super(props);
    this.callCounterTimerFlag = 0
    this.counterTimer = null;
    window.addEventListener('keydown', this.navigation);
    this.state= {
      counterTimer: GLOBALS.COUNTER_TIMER
    }
  }

  navigation = (e) => {
    switch (KeyCode(e.keyCode)) {
      case "SELECT":
          this.handleSelect()
        break;
    }
  }

  // Yes, Continue - Action
  handleSelect = () => {
    self = this
    if (this.props.player.idleScreenDialogue) {
        this.setState({...this.state, counterTimer: GLOBALS.COUNTER_TIMER});
        this.clearCounterTimer()
        setTimeout(function() {
          self.props.clearIdleScreenDlg(); // update player store - idleScreenDialogue
          self.props.play();
          self.callCounterTimerFlag = 0
        },200) // add 0.2 sec delay to avoid asynchronus calls
    }
  }

  // Clear Counter Timer
  clearCounterTimer = () => {
    if (this.counterTimer!==null)
      clearInterval(this.counterTimer)
  }

  manageCounterTimer = () => {
      let self = this
      if (this.props.player.idleScreenDialogue) {
          this.counterTimer = setInterval(function(){
            if (self.state.counterTimer > 0) {
              self.setState({...self.state, counterTimer: self.state.counterTimer-1});
              if (self.props.player && self.props.player.error && self.props.player.error.isVisible) {
                self.setState({...self.state, counterTimer: GLOBALS.COUNTER_TIMER});
                self.clearActions();
              }
            } else { // Destroy player - Go back - no action after 'X' seconds
                self.clearActions();
                self.props.stopPlayer();
            }
          }, GLOBALS.SCREEN_TIMEOUT_INTERVAL)
      } else {
        this.counterTimer = 0
        this.clearCounterTimer()
      }
  }

  clearActions = () => {
    // Clear counter timer
    self.setState({...self.state, counterTimer: GLOBALS.COUNTER_TIMER});
    this.clearCounterTimer();
    this.props.clearPlayerInactiveTimer();
    if (this.props.player.idleScreenDialogue)
        this.props.clearIdleScreenDlg() // update player store - idleScreenDialogue
  }

  onClickContinue = () => {
      this.handleSelect()
  }

  prepareDlg = () => {
    if(this.callCounterTimerFlag === 0 && this.props.player.idleScreenDialogue) {
      this.callCounterTimerFlag = 1
      this.props.pause() // Pause the player during dialogue pop up
      this.manageCounterTimer() // Initiate the timer
    }
  }

  render() {
    this.prepareDlg();
    let episodeTitle = this.props.player && this.props.player.channel && this.props.player.channel.episodeTitle ? this.props.player.channel.episodeTitle + "?" : ''
    let title = this.props.player && this.props.player.channel && this.props.player.channel.title ? this.props.player.channel.title + ( episodeTitle ? ", ": "?") : ''
    return (
      <div class={[styles.dialogue, !this.props.player.idleScreenDialogue ? styles.hide : ''].join('')}>
            <div class={[styles.popup].join()}>
                <div class={styles.label}>{GLOBALS.SCREEN_INACTIVE_MAIN_LABEL}</div>
                <div class={styles.showInfo}>{title}{episodeTitle}</div>
                <div class={styles.dlgbtn}><div class={[styles.action_btn, styles.focus].join(' ')} onClick={()=>this.onClickContinue()}> {<div className={styles.inactivePlayblk}><img className={styles.inactivePlayIcon} src={PLAY_ICON} /> </div>} <div className={styles.inactiveBtnLabel}>{GLOBALS.SCREEN_INACTIVE_BUTTON_LABEL} </div></div></div>
                <div class={styles.counter}><div> {this.state.counterTimer} </div></div>
            </div>
        </div>
    )
  }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = state => {
  return {
    player: state.player
  }
}

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => ({
  clearIdleScreenDlg: (payload) => dispatch({ type: 'CLEAR_IDLE_SCREEN', payload }),
  play: (payload) => dispatch({ type: PLAY, payload }),
  pause: (payload) => dispatch({ type: PAUSE, payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerInactiveDlg)