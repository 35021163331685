import { KeyCode, KEY_CODE, KEYS } from "../keyCodeMapping";
import { GLOBALS } from "../globals";
import { internetIsConnected } from "@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper";
import { ErrorMessages } from "@connected-video-web/dstv-frontend-services/dist/constants/errorMessages";

export const registerEvents = (props) => {
    window.addEventListener('keydown', navigation(props));
    window.addEventListener('keyup', () => { window.longKeyPressed = false });
    window.addEventListener('wheel', scroll(navigation(props)));
}

const navigation = (props) => (e) => {
    if (KeyCode(e.keyCode)) {
        e.preventDefault && e.preventDefault()
        props.TvFocus(KeyCode(e.keyCode), {
            history: props.history,
            internetIsConnected: internetIsConnected(),
            ErrorMessages
        });
        if (KEY_CODE[KEYS['UP']][0] === e.keyCode.toString() || KEY_CODE[KEYS['DOWN']][0] === e.keyCode.toString())
            window.longKeyPressed = true
    } else if (e.keyCode === 36){
        props.history.push("/");
        props.HomePressed();
    }
}

export const scroll = (callback) => (e) => {
    e.preventDefault()
    e = e || window.event
    if (e.deltaY > 0) {
        setTimeout(function () {
            callback({ keyCode: KEY_CODE[KEYS['DOWN']][0] })
        }, GLOBALS.SCROLL_DELAY)
    } else {
        setTimeout(function () {
            callback({ keyCode: KEY_CODE[KEYS['UP']][0] })
        }, GLOBALS.SCROLL_DELAY)
    }
}
