import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PIN } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { TvPin } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { KEY_CODE } from '../../keyCodeMapping';
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';

class Pin extends Component {
    constructor(props) {
        super(props);
        this.props.getPinDefaultLabels({ id: props.fromAction });
        window.addEventListener('keydown', this.getRemoteKeys);
    }
    getRemoteKeys = (e) => {
        if (e.keyCode >= KEY_CODE[0] && e.keyCode <= KEY_CODE[9]) {
            this.props.setKeysFocus(e.key)
            this.props.setPIN(e.key)
        }
    }
    makePinEncrypted = () => {
        this.props.hidePin()
    }
    onKeyboardClick = (e) => {
        if (e == 'clear') {
            this.props.deletePin(e)
        } else {
            this.props.setKeysFocus(e)
            this.props.setPIN(e)
        }
    }
    onActionButtonClick = (e) => {
        postSegmentData(e.actionButtonTitle, e)
        this.props.actionButton(e.actionButtonTitle)
    }
    onForgotButtonClick = (e) => {
        postSegmentData(e.forgotButtonTitle, e)
        this.props.forgotButton()
    }
    componentWillReceiveProps(nextProps) {
        let checkSuccess = nextProps.pin.isSuccess
        let onBack = nextProps.pin.isBack
        if (checkSuccess) {
            this.props.history.push({
                pathname: '/settings',
            })
            this.props.clearPin()
            this.props.setMainMenuFocus(nextProps.settings.data[1])
        }
        if (onBack) {
            nextProps.settings.data[1].isTvFocus = true
        }
        if (!onBack &&
            nextProps.pin &&
            nextProps.pin.data &&
            nextProps.pin.data[0] &&
            nextProps.pin.data[0].items &&
            nextProps.pin.data[0].items.hidePin &&
            nextProps.pin.data[0].items.hidePin.item) {
            let index = nextProps.pin.data[0].items.hidePin.item.findIndex(i => i !== '' && i !== '*')
            if (nextProps.pin.data[0].items.hidePin.item[index]) {
                setTimeout(this.makePinEncrypted, 300)
            }
        }

    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.getRemoteKeys, false);
    }
    render() {
        let data = (this.props.pin.data && this.props.pin.data.length) ? this.props.pin.data[0] : [];
        return <TvPin data={data} onKeyboardClick={this.onKeyboardClick} onActionButtonClick={this.onActionButtonClick} onForgotButtonClick={this.onForgotButtonClick} />
    }
}

/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        pin: state.pin,
        settings: state.settings,
        profile: state.profile,
        editorial: state.editorial
    }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    getPinDefaultLabels: (payload) => dispatch({ type: PIN, payload }),
    hidePin: () => dispatch({ type: 'HIDE_PIN' }),
    setPIN: (value) => dispatch({ type: 'SET_PIN', payload: value }),
    actionButton: (value) => dispatch({ type: 'SWITCH_SCREEN', payload: value }),
    forgotButton: (value) => dispatch({ type: 'FORGOTPIN_OPEN', payload: value }),
    deletePin: (value) => dispatch({ type: 'DELETE_PIN', payload: value }),
    clearPin: () => dispatch({ type: 'CLEAR_PIN_DATA' }),
    setMainMenuFocus: (value) => dispatch({ type: 'SETTING_MAIN_MENU_FOCUS', payload: value, fromPin: true }),
    setSettingFocus: () => dispatch({ type: 'SETTING_FOCUS' }),
    setKeysFocus: (key) => dispatch({ type: 'KEYS_FOCUS', payload: key }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pin);