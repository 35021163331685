import { ErrorMessages } from "@connected-video-web/dstv-frontend-services/dist/constants/errorMessages";
import { store } from "../store";
import { getNetworkPayload } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/selectHelper'
import { getFocusedIndex, focusKey } from "@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper";

export const networkPayload = (payload) => {
    return {
        ...getNetworkPayload(store,
            payload,
            ErrorMessages
        )
    }
}

export const clearTimer = (timer) => {
    clearInterval(timer)
}

export const checkSectionDataPresent = (props, key) => {
    return (props.sections[key] &&
        props.sections[key].data &&
        props.sections[key].data[0] &&
        props.sections[key].data[0].items &&
        props.sections[key].data[0].items.length)
}

export const getFocusedMenuId = (props) => {
    return (props.menu &&
        props.menu.data &&
        props.menu.data[getFocusedIndex(props.menu.data, focusKey.isActive)] &&
        props.menu.data[getFocusedIndex(props.menu.data, focusKey.isActive)].id)
}
export const checkDeeplinkPathCondition = (props) => {
    let oldURL = window.event && window.event.oldURL && window.event.oldURL.split('/')
    let newURL = window.event && window.event.newURL && window.event.newURL.split('/')
    let path = props.location.pathname.split('/')
    return (oldURL && oldURL[4] !== newURL && newURL[4] && newURL && newURL[4] === path[1])
}
export const checkDeepLinkLogic = (props, location, page) => {
    let activeIndex = props.menu.data.filter(item => item.isActive).pop()
    let findIndex = props.menu.data.findIndex(item => item.id === location)
    if ((activeIndex && activeIndex.isActive && !activeIndex.isTvFocus) || checkDeeplinkPathCondition(props)) {
        props.checkMainComponentFocus(page);
        props.setMenuIndex(findIndex);
    }
    return {}
}