import React from 'react';
import { connect } from "react-redux";
import { Route, Switch, Redirect } from 'react-router-dom';
import { Home, Channel, Catchup, WatchList, Search, StreamaSettings, StreamaAppList, Showpage } from './page';
import { createNavigatorSnapshot } from '@connected-video-web/dstv-frontend-services/dist/Utils/navHelper';
import { getFocusedIndex } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { Profile } from './page/profile';
import { MAIN_COMPONENT_FOCUS, UPDATE_MENU_ON_CHANGE, PROFILE_FOCUS, GET_USER_INFO } from '@connected-video-web/dstv-frontend-services/dist/actions';
import { ToastMessage } from "@connected-video-web/dstv-frontend-components/dist/Components/Container";
import { Loader } from "@connected-video-web/dstv-frontend-components/dist/Components/Core";

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { keyPressed: false, isLoading: false };
        this.getRemoteKeys = this.getRemoteKeys.bind(this);
        this.pageTimer = null;
    }
    componentWillUpdate(nextProps) {
        let path = this.props.location.pathname.split('/')
        let newState = this.getPageKey(path[1])
        if (!newState || newState === 'home') {
            newState = 'editorial'
        }
        if (this.props.menu.data && this.props.menu.data.length && (newState === 'streamaapplist' || newState === 'streamasettings' || path[1] == 'search') && this.getPageKey(path[1]) !== 'profiles') {
            if (!this.state.keyPressed && nextProps && nextProps[newState] && nextProps[newState].data && nextProps[newState].data.length && !nextProps[newState].menuFocused) {
                this.props.setMainComponentFocus(nextProps, newState)
            }
        }
    }
    componentDidMount() {
        window.addEventListener("keydown", this.getRemoteKeys, false);
    }
    getRemoteKeys(event) {
        if (event) {
            this.setState({ keyPressed: true });
            if(event.keyCode === 38 || event.keyCode === 40) {
                clearTimeout(this.pageTimer);
                let activeMenu = getFocusedIndex(this.props.menu.data, 'isTvFocus')
                if(activeMenu !== -1){
                    this.setState({ isLoading: true })
                }
            }
            this.pageTimer = setTimeout(() => {
                this.setState({ isLoading: false })
                this.checkAndChangeRoute();
            }, 600)
        } else {
            this.setState({ keyPressed: false });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.getRemoteKeys, false);
    }
    getPageKey = (path) => {
        if (path === 'settings') {
            return 'streamasettings'
        } else if (path === 'apps') {
            return 'streamaapplist'
        }
        return path
    }

    checkAndChangeRoute = () => {
        let catchupURL
        let freeUser = this.props.user && this.props.user.package === 'FREE' ? true : false
        //only for key navigation
        const keyEvent = window.event && window.event.type
        if (this.props.menu.data && this.props.menu.data.length) {
            let path = this.props.location.pathname.split('/')
            if (!path[1]) path[1] = 'home'
            let activeMenu = getFocusedIndex(this.props.menu.data, 'isTvFocus')
            if (!this.props.search.fromSearch && !this.state.keyPressed && (keyEvent !== 'keydown' && keyEvent !== 'click') && this.props.menu.data.length > 0 && (path[1] !== 'home' || path[1] !== '') && path[1] !== 'profiles' && !freeUser) {
                if (activeMenu === 0 && path[1] !== 'home' && ((path[1] === 'search' && !freeUser) || path[1] === 'apps' || path[1] === 'settings')) {
                    this.props.updateMenu(this.props.menu, activeMenu, path[1])
                }
            } else if (path[1] !== 'profiles' && this.props.profiles.activeProfile && this.props.profiles.activeProfile.isTvFocus) {
                if(this.props.history.location.pathname !== "/profiles") {
                    this.props.history.push({
                        pathname: '/profiles'
                    })
                }
            } else if (freeUser && this.props.profiles && !this.props.profiles.activeProfile
                && (path[1] === 'home' || path[1] === 'search')) {
                // Free user - Redirect to Profiles if home is not available from api response
                if(this.props.history.location.pathname !== "/profiles") {
                    this.props.history.push({
                        pathname: '/profiles'
                    })
                }
            } else if (this.props.menu.data[activeMenu] && path[1] !== this.props.menu.data[activeMenu].id && (path[2] !== 'program' && path[2] !== 'video') && !this.props.profiles.activeProfile.isTvFocus) {
                let componentPushed = this.props.history.location.pathname.split('/')
                if (componentPushed[1] !== this.props.menu.data[activeMenu].id) {
                    this.props.history.push({
                        pathname: '/' + this.props.menu.data[activeMenu].id
                    })
                }
            } else if ((path[1] === 'livetv' && path[2] !== 'play' && this.props.sections.data_livetv && this.props.sections.data_livetv.data) || ((path[1] === 'kids' || path[1] === 'tvshows' || path[1] === 'movies' || path[1] === 'sport') && this.props.sections.data_catchup && this.props.sections.data_catchup.data && this.props.sections.data_catchup.data[0]) || (path[1] === 'channelgroups' && this.props.sections.data_channelgroups && this.props.sections.data_channelgroups.data && this.props.sections.data_channelgroups.data[0])) {
                if (path[1] === 'kids' || path[1] === 'tvshows' || path[1] === 'movies'
                    || path[1] === 'sport' || path[1] === 'channelgroups') {
                    catchupURL = (path[1] === 'channelgroups') ? '' : 'catchup'
                }
                this.changeSubMenuRoute(path, catchupURL)
            }
        }
    }
    
    changeSubMenuRoute = (path, catchupURL) => {
        let activeSubMenu = getFocusedIndex(this.props.sections[`data_${catchupURL ? catchupURL : path[1]}`].data[0].items, 'isTvFocus')
        if (activeSubMenu != -1 && this.props.sections[`data_${catchupURL ? catchupURL : path[1]}`].data[0].items[activeSubMenu].title !== decodeURIComponent(path[path.length - 1])) {
            let subRoutePath = `/${path[1]}/${this.props.sections[`data_${catchupURL ? catchupURL : path[1]}`].data[0].items[activeSubMenu].title}`
            if (!activeSubMenu) {
                subRoutePath = `/${path[1]}`
                if (!path[2])
                    subRoutePath = ''
            }
            if (subRoutePath) {
                this.props.history.push({
                    pathname: subRoutePath
                })
            }
        }
    }

    getHistoryProps = () => {
        return { location: this.props.location, history: this.props.history }
    }

    checkComponentFocused = (data) => {
        return (data && data.filter(item => item.isTvFocus).pop()) || null
    }

    componentDidCatch = (error, info) => {
        console.log(error, info, "!!!!!!!!!!! COMPONENT ERROR ********");
    }

    render = () => {
        let toastMessage = this.props.toastMessage && this.props.toastMessage.showToast ? <React.Fragment><ToastMessage poster={this.props.toastMessage.poster} animate={this.props.toastMessage.animate} closeDispatch={() => this.props.showOrHideToastMessage({ type: 'HIDE_TOAST_MESSAGE', body: { status: false } })} /></React.Fragment> : ""
        var params = this.props.location.pathname.split('/')
        return (<React.Fragment>
            {toastMessage}
            {this.state.isLoading ? <Loader fromSmartTv={true} isLoading={true} /> : (
                <Switch>
                    <Route path="/profiles">
                        <Profile {...this.getHistoryProps()} page={params} />
                    </Route>
                    <Route path="/search">
                        <Search {...this.getHistoryProps()} />
                    </Route>
                    <Route exact path={["/", "/home"]}>
                        <Home {...this.getHistoryProps()} />
                    </Route>
                    <Route path="/livetv">
                        <Channel {...this.getHistoryProps()} />
                    </Route>
                    <Route path={["/tvshows", "/movies", "/sport", "/kids", "/catchup", "/channelgroups"]}>
                        <React.Fragment>
                            <Catchup {...this.getHistoryProps()} />
                        </React.Fragment>
                    </Route>
                    <Route path="/mylist">
                        <WatchList {...this.getHistoryProps()} />
                    </Route>
                    <Route path="/apps">
                        <StreamaAppList {...this.getHistoryProps()} />
                    </Route>
                    <Route path="/settings">
                        <StreamaSettings {...this.getHistoryProps()} page={params} />
                    </Route>
                </Switch>
            )}
        </React.Fragment>)
    }
}
const mapStateToProps = state => {
    return {
        catchUp: state.catchUp,
        editorial: state.editorial,
        menu: state.menu,
        profiles: state.profiles,
        search: state.search,
        sections: state.sections,
        showpage: state.showpage,
        streamaapplist: state.streamaapplist,
        streamasettings: state.streamasettings,
        toastMessage: state.toastMessage,
        user: state.user
    }
};
const mapDispatchToProps = dispatch => ({
    getUserInfo: () => dispatch({ type: GET_USER_INFO }),
    updateMenu: (payload, activeMenu, path) => dispatch({ type: UPDATE_MENU_ON_CHANGE, payload, menuIndex: activeMenu, path: path }),
    setMainComponentFocus: (props, path) => dispatch({ type: (path === 'profiles' ? PROFILE_FOCUS : MAIN_COMPONENT_FOCUS), payload: { props, key: path } }),
    showOrHideToastMessage: (type, body) => dispatch({ type: type.type, payload: type.body }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
