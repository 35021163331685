import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TvProfileName } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { KeyCode } from '../../../keyCodeMapping';
import { profile } from '@connected-video-web/dstv-frontend-services/dist/constants/resource'
import { pushSaveScreen, pushProfileListScreen, NAME_MAX_LIMIT } from './helper'
import { scroll } from '../../../events';
import dstvLogo from '@connected-video-web/dstv-frontend-components/dist/Assets/Images/dstv-logo.svg';


class ProfileName extends Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.navigation);
        window.addEventListener('wheel', this.scroll);
        this.state = {
            name: window.profileHistory && window.profileHistory[0].title || '',
            column: 6,
            capsLockOn: false,
            isOkActive: (window.profileHistory && window.profileHistory[0].title) ? true : false,
            isOkFocused: false,
            isGridFocused: true,
            focusedGridItem: {},
            focusedActionItem: {},
        }

    }

    componentWillMount() {
        this.prepareData()
    }

    prepareData = () => {
        this.prepareGridData()
        this.prepareActionData()
        this.setState({ ...this.state, focusedGridItem: this.gridData[0].items[0] })
    }

    prepareGridData = () => {
        this.gridData = []
        let result = []
        let row = 0
        profile.profileName.keypad.forEach((item, index) => {
            row = Math.floor(index / this.state.column)
            result.push({
                value: this.state.capsLockOn ? item.toUpperCase() : item,
                row: row,
                column: Math.floor(index - (this.state.column * row))
            })
        })
        this.gridData.push({
            items: result
        })
    }

    prepareActionData = () => {
        let result = []
        profile.profileName.actionButton.forEach((item, index) => {
            result.push({
                value: item,
                row: index,
                isImg: item !== profile.profileName.actionButton[profile.profileName.actionButton.length - 1]
            })
        })
        this.actionData = {
            items: result
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.navigation);
        window.removeEventListener('wheel', this.scroll);
    }

    scroll = (e) => {
        scroll(this.navigation)(e)
    }

    navigation = (e) => {
        switch (KeyCode(e.keyCode)) {
            case 'UP':
                this.moveFocusUp()
                break;
            case 'DOWN':
                this.moveFocusDown()
                break;
            case 'LEFT':
                this.moveFocusLeft()
                break;
            case 'RIGHT':
                this.moveFocusRight()
                break;
            case 'SELECT':
                this.onSelect()
                break;
            case 'BACK':
                this.onBack()
        }
    }

    moveFocusUp = () => {
        let item = this.gridData[0].items.filter(item => (
            item.row === this.state.focusedGridItem.row - 1 &&
            item.column === this.state.focusedGridItem.column)).pop()
        if (item && !this.state.isOkFocused) {
            //moving within grid
            this.setState({ ...this.state, focusedGridItem: item })
        } else if (this.state.focusedActionItem.value) {
            item = this.actionData.items.filter(item => item.row === this.state.focusedActionItem.row - 1).pop()
            if (item) {
                //moving within action list
                this.setState({ ...this.state, focusedActionItem: item })
            } else if (this.state.isOkActive) {
                //moving to ok from action
                this.setState({ ...this.state, focusedActionItem: {}, isOkFocused: true })
            } else {
                //moving to last row within action list
                this.setState({ ...this.state, focusedActionItem: this.actionData.items[this.actionData.items.length - 1] })
            }
        } else if (this.state.isOkFocused) {
                //moving to last row of action list from OK
            this.setState({ ...this.state, focusedGridItem: {}, focusedActionItem: this.actionData.items[this.actionData.items.length - 1], isOkFocused: false })
        } else {
            // moving to last row of action list from OK 
            this.setState({ ...this.state, focusedGridItem: this.gridData[0].items[this.state.focusedGridItem.column + (this.state.column * this.gridData[0].items[this.gridData[0].items.length - 1].row)] })
        }
    }

    moveFocusDown = () => {
        let item = this.gridData[0].items.filter(item => (
            item.row === this.state.focusedGridItem.row + 1 &&
            item.column === this.state.focusedGridItem.column)).pop()
        if (item && !this.state.isOkFocused) {
            //moving within grid
            this.setState({ ...this.state, focusedGridItem: item })
        } else if (this.state.focusedActionItem.value) {
            item = this.actionData.items.filter(item => item.row === this.state.focusedActionItem.row + 1).pop()
            if (item) {
                //moving within action list
                this.setState({ ...this.state, focusedActionItem: item })
            } else if (this.state.isOkActive) {
                //moving to ok from action
                this.setState({ ...this.state, focusedActionItem: {}, isOkFocused: true })
            } else {
                //moving to first row within action list
                this.setState({ ...this.state, focusedActionItem: this.actionData.items[0] })
            }
        } else if (this.state.isOkFocused) {
            if (this.state.focusedGridItem.value) {
                //moving to first row of grid from OK
                this.setState({ ...this.state, isGridFocused: true, focusedGridItem: this.gridData[0].items[this.state.focusedGridItem.column], isOkFocused: false })
            } else {
                //moving to first row of action list from OK
                this.setState({ ...this.state, isGridFocused: true, focusedActionItem: this.actionData.items[0], isOkFocused: false })
            }
        } else {
            // moving to first row of action list from OK 
            this.setState({ ...this.state, focusedGridItem: this.gridData[0].items[this.state.focusedGridItem.column] })
        }
    }

    moveFocusLeft = () => {
        if (!this.state.isOkFocused) {
            let item = this.gridData[0].items.filter(item => (
                item.row === this.state.focusedGridItem.row &&
                item.column === this.state.focusedGridItem.column - 1)).pop()
            if (item) {
                //moving within grid
                this.setState({ ...this.state, focusedGridItem: item })
            } else if (this.state.focusedActionItem.value) {
                //moving to grid from action button
                this.setState({
                    ...this.state,
                    isGridFocused:true,
                    focusedGridItem: this.gridData[0].items[this.state.focusedActionItem.row + (this.state.focusedActionItem.row + 1) * (this.state.column - 1)],
                    focusedActionItem: {}
                })
            } else {
                item = this.actionData.items.filter(item => item.row === this.state.focusedGridItem.row).pop()
                if (item) {
                    //moving to action button from first column
                    this.setState({ ...this.state, focusedGridItem: {}, focusedActionItem: item })
                } else {                    
                    //moving to last column of grid
                    this.setState({ ...this.state, focusedGridItem: this.gridData[0].items[this.state.focusedGridItem.row + (this.state.focusedGridItem.row + 1) * (this.state.column - 1)], focusedActionItem: {} })
                }
            }
        } else{
            this.setState({ ...this.state, isGridFocused: true, isOkFocused:false, focusedGridItem: this.gridData[0].items[this.gridData[0].items.length - 1] })
        }
    }

    moveFocusRight = () => {
        if (!this.state.isOkFocused) {
            let item = this.gridData[0].items.filter(item => (
                item.row === this.state.focusedGridItem.row &&
                item.column === this.state.focusedGridItem.column + 1)).pop()
            if (item) {
                //moving within grid
                this.setState({ ...this.state, focusedGridItem: item })
            } else if (this.state.focusedActionItem.value) {
                //moving to 1st column of grid from action button
                this.setState({ ...this.state, focusedGridItem: this.gridData[0].items[this.state.focusedActionItem.row * (this.state.column)], focusedActionItem: {} })
            } else {
                item = this.actionData.items.filter(item => item.row === this.state.focusedGridItem.row).pop()
                if (item) {
                    //moving to action button
                    this.setState({ ...this.state, focusedGridItem: {}, focusedActionItem: item })
                } else {
                    if (this.state.isOkActive) {
                        this.setState({ ...this.state, isGridFocused: false, isOkFocused: true })
                    } else {
                        //moving to 1st column of grid from last column 
                        this.setState({ ...this.state, focusedGridItem: this.gridData[0].items[(this.state.focusedGridItem.row) * (this.state.column)], focusedActionItem: {} })
                    }
                }
            }
        } else {
            this.setState({ ...this.state, isGridFocused: true, isOkFocused:false, focusedGridItem: this.gridData[0].items[this.gridData[0].items.length - this.state.column] })
        }
    }

    onSelect = () => {
        if (this.state.isOkFocused) {
            let data = window.profileHistory[0]
            window.profileHistory.unshift({
                ...data,
                title: this.state.name
            })
            pushSaveScreen(this.props.history, window.profileHistory[0])
        } else
            if (this.state.focusedGridItem.value && this.state.name.length < NAME_MAX_LIMIT) {
                this.setState({ ...this.state, name: this.state.name + this.state.focusedGridItem.value, isOkActive: true })
            } else if (this.state.focusedActionItem.value) {
                if (this.state.name.length) {
                    if (this.state.focusedActionItem.value === 'space' && this.state.name.length < NAME_MAX_LIMIT) {
                        this.setState({ ...this.state, name: this.state.name + ' ' })
                    } else if (this.state.focusedActionItem.value === 'clear') {
                        let name = this.state.name.slice(0, -1)
                        this.setState({ ...this.state, name: name, isOkActive: name.length ? true : false })
                    }
                }
                if (this.state.focusedActionItem.value === 'abc') {
                    this.setState({ ...this.state, capsLockOn: !this.state.capsLockOn })
                }
            }
    }

    onBack = () => {
        if (!window.profileHistory[0].isDefault && !window.profileHistory[0].isNewProfile) { //non default edit
            pushProfileListScreen(this.props.history)
        } else {
            window.profileHistory.shift()
            this.props.history.goBack()
        }
    }

    onClick = (item) => {
        if (profile.profileName.actionButton.indexOf(item.value) === -1 &&
            item.value !== profile.profileName.okButton) {
            // grid items clicked
            this.setState({ ...this.state, focusedGridItem: item }, this.onSelect)
        } else if (item.value === profile.profileName.okButton) {
            // ok clicked
            if (this.state.isOkActive)
                this.setState({ ...this.state, focusedGridItem: {}, focusedActionItem: {}, isOkFocused: true }, this.onSelect)
        } else {
            // action button clicked
            item = this.actionData.items.filter(e => e.row === item.row).pop()
            this.setState({ ...this.state, focusedGridItem: {}, focusedActionItem: item }, this.onSelect)
        }
    }

    render() {
        this.prepareGridData()
        let infoData = {
            title: profile.profileName.title[window.profileHistory[0].isNewProfile ? 'new' : 'edit'],
            avatar: window.profileHistory && window.profileHistory[0].avatar.uri,
            name: this.state.name,
            placeholder: profile.profileName.placeHolder
        }
        return (
            <TvProfileName
                gridData={this.gridData[0]}
                actionButton={this.actionData}
                infoData={infoData}
                {...this.state}
                focusedActionValue={this.state.focusedActionItem.value}
                focusedGridValue={this.state.focusedGridItem.value}
                capsLockOn={this.state.capsLockOn}
                okButton={profile.profileName.okButton}
                onClick={this.onClick}
                logo={dstvLogo}
            />
        )
    }
}

export default connect(null, null)(ProfileName);

