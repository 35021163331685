import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GET_PROFILES_LIST, PROFILE_FOCUS, PROFILE_ICON_FOCUS, PROFILE_CLEAR, FLAGR_ERROR, GET_PROFILES_LIST_ERROR, SELECT_PROFILE, PROFILES_ERROR_CLEAR, EXIT_APP_SUCCESS, PROXIMITY_SET_NOW_PRIMARY_SUCCESS } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { TvProfileList, TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { KeyCode } from '../../../keyCodeMapping';
import { styledEditorial } from '../../styledComponents';
import { pushProfileAvatarScreen } from './helper'
import dstvLogo from '@connected-video-web/dstv-frontend-components/dist/Assets/Images/dstv-logo.svg';
import { scroll } from '../../../events';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../../helper';
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { profile } from '@connected-video-web/dstv-frontend-services/dist/constants/resource';
import { setCurrentFocusedComponent } from '@connected-video-web/dstv-frontend-services/dist/Utils/spatialNavigation/helper';
import { pageType } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { talkToChannel } from '@connected-video-web/streama-system-manager-service/dist/services/talkToChannel';
import { TvToast } from '@connected-video-web/dstv-frontend-components/dist/Components/Core';
let productType = localStorage.getItem('PRODUCT_TYPE');
class ProfileList extends Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.navigation);
        window.addEventListener('wheel', this.scroll);
        if (this.props.profiles.activeProfile && !this.props.profiles.activeProfile.isTvFocus) {
            setCurrentFocusedComponent({ key: pageType.profiles })
        }
        this.getProfileList(window.profileHistory && window.profileHistory[0])
        this.state = {
            addButtonFocussed: this.checkAddFocus(),
            addButtonActive: false,
            editButtonFocussed: false,
            initialFocusSet: this.checkAddFocus()
        }
        window.profileHistory = []
    }

    getProfileList = (payload) => {
        if (internetIsConnected()) {
            if (!this.props.profiles.data) {
                this.props.getProfiles(payload)
            }
        } else {
            this.props.throwNetworkError({
                error:
                    networkPayload({
                        callback: () => { this.getProfileList(payload) },
                        toggleText: !this.props.profiles.activeProfile
                    })
            })
        }
    }

    checkAddFocus = () => {
        return (window.profileHistory && window.profileHistory[0] && window.profileHistory[0].addButtonClicked && window.profileHistory && window.profileHistory[0] && !window.profileHistory[0].title) || false
    }

    scroll = (e) => {
        scroll(this.navigation)(e)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.navigation);
        window.removeEventListener('wheel', this.scroll);
        this.props.clearProfiles();
    }

    navigation = (e) => {
        switch (KeyCode(e.keyCode)) {
            case 'UP':
                this.moveFocusUp()
                break;
            case 'DOWN':
                this.moveFocusDown()
                break;
            case 'LB':
            case 'LEFT':
                this.moveFocusLeft()
                break;
            case 'RB':
            case 'RIGHT':
                this.moveFocusRight()
                break;
            case 'SELECT':
                this.onSelect()
                break;
            case 'BACK':
                this.onBack()
                break;
        }
    }

    moveFocusUp = () => {
        if (this.state.editButtonFocussed) {
            this.setState({ ...this.state, editButtonFocussed: false });
            this.props.focusProfile('UP');
        } else if (this.state.addButtonFocussed && this.props.profiles.data && this.props.profiles.activeProfile) {
            this.setState({ ...this.state, addButtonFocussed: false });
            this.props.focusProfileIcon();
        }
    }

    moveFocusDown = () => {
        if (!this.state.addButtonFocussed &&
            this.props.profiles.data &&
            (!this.props.profiles.data[0].isTvFocus &&
                (!this.props.profiles.activeProfile || this.props.profiles.activeProfile && !this.props.profiles.activeProfile.isTvFocus)) &&
            !this.state.editButtonFocussed && !this.props.profiles.exitConfirmation) {
            this.setState({ ...this.state, editButtonFocussed: true });
        } else if (this.state.addButtonActive && !this.state.addButtonFocussed) {
            this.setState({ ...this.state, addButtonFocussed: true });
            this.props.focusProfile('DOWN');
        }
    }

    moveFocusLeft = () => {
        if (this.state.addButtonFocussed) {
            this.setState({ ...this.state, addButtonFocussed: false, addButtonActive: false });
            this.props.focusProfile('LEFT');
        }
    }

    moveFocusRight = () => {
        if (this.props.profiles.data &&
            (!this.props.profiles.data[0].isTvFocus &&
                (!this.props.profiles.activeProfile || this.props.profiles.activeProfile && !this.props.profiles.activeProfile.isTvFocus) &&
                this.props.profiles.data[0].canAddProfile) &&
            !this.state.editButtonFocussed &&
            !this.state.addButtonFocussed)
            this.setState({ ...this.state, addButtonFocussed: true, addButtonActive: true });
    }

    onSelect = () => {
        let payload
        if (this.state.addButtonFocussed && this.props.profiles.data) {
            pushProfileAvatarScreen(this.props.history, { isNewProfile: true, addButtonClicked: true })
        } else if (this.state.editButtonFocussed) {
            postSegmentData('Edit', profile.profileList.title)
            payload = this.props.profiles.data[0].items.filter(item => item.isTvFocus).pop()
            pushProfileAvatarScreen(this.props.history, { ...payload, title: payload.alias, isDefault: !payload.canDelete })
        }
        else {
            if (this.props.common.errorOccured) {
                this.props.history.push({
                    pathname: '/home'
                })
            }
        }
    }

    onBack = () => {
        if (this.props.profiles.activeProfile) {
            if (this.props.profiles.data || this.props.profiles.error) {
                this.props.focusProfileIcon();
                this.setState({ ...this.state, addButtonFocussed: false, editButtonFocussed: false });
            }
        }
        else if (this.state.addButtonFocussed || this.state.editButtonFocussed) {
            this.props.exitApplication();
        }
    }

    componentDidUpdate() {
        if ((!this.props.menu.data && !this.state.initialFocusSet ||
            (!this.props.profiles.activeProfile || this.props.profiles.activeProfile && !this.props.profiles.activeProfile.isTvFocus)) &&
            (this.props.profiles.data && this.props.profiles.data[0] && !this.props.profiles.data[0].isTvFocus) &&
            !this.state.addButtonFocussed &&
            !this.state.editButtonFocussed &&
            !this.state.initialFocusSet) {
            this.setState({ ...this.state, initialFocusSet: true },
                this.props.focusProfile('UP'))
        }
        if (!this.state.initialFocusSet && this.props.profiles.data && this.props.profiles.data[0] && this.props.profiles.data[0].isTvFocus) {
            this.setState({ ...this.state, initialFocusSet: true })
        }
        if (this.props.proximity.proximityLoader && this.props.profiles.data)
            this.props.setOnboardingSuccess()
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.profiles.data &&
            nextprops.profiles.data[0] &&
            nextprops.profiles.data[0].items &&
            (nextprops.profiles.data[0].items[0] &&
                nextprops.profiles.data[0].items[0].isForceEdit)) {
            pushProfileAvatarScreen(this.props.history, { isNewProfile: true, profileId: nextprops.profiles.data[0].items[0].id })
        } else if (!nextprops.profiles.activeProfile && nextprops.profiles.error && nextprops.profiles.error.isVisible && !nextprops.profiles.error.isNetworkError && !nextprops.profiles.error.isExit) {
            this.props.history.push('/')
            this.props.disableProfile()
        } else if ((nextprops.profiles.activeProfile && this.props.profiles.activeProfile &&
            nextprops.profiles.activeProfile.id !== this.props.profiles.activeProfile.id) || (nextprops.profiles.activeProfile && !this.props.profiles.activeProfile)) {
            this.props.history.push('/')
        }
        if (nextprops && nextprops.profiles && nextprops.profiles.saveProfileerror && (nextProps.nextprops.serverError)) {
            postAPIErrorToSegment(nextprops.profiles.saveProfileerror, { errorMessage: nextprops.profiles.serverError })
        }
    }

    onEditSelected = (e) => {
        this.setState({ ...this.state, editButtonFocussed: true, addButtonFocussed: false, addButtonActive: false }, this.onSelect)
    }

    onAvatarSelected = (item) => {
        let activeProfile = { ...item }
        activeProfile.isTvFocus = false
        this.props.selectProfile({ activeProfile, id: activeProfile.id })
        this.props.history.push('/')
    }

    onAddSelected = (e) => {
        this.setState({ ...this.state, editButtonFocussed: false, addButtonFocussed: true, addButtonActive: true }, this.onSelect)
    }

    onErrorActionSelected = (e) => {
        this.getProfileList(window.profileHistory && window.profileHistory[0])
    }

    onErrorBackSelected = (e) => {
        if (this.props.profiles.activeProfile) {
            this.props.clearProfileError()
        } else {
            // exit logic
        }
    }

    render() {

        let data = {}
        if (this.props.profiles.data) {
            data = this.props.profiles.data[0]
        }
        let error = ''
        if (this.props.profiles && this.props.profiles.error) {
            error = <TvError {...this.props.profiles.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
        }
        let isActive = (data.isTvFocus || this.state.addButtonFocussed || this.state.editButtonFocussed)

        return <React.Fragment>
            <TvToast />
            <TvProfileList
                {...data}
                addButtonFocussed={this.state.addButtonFocussed}
                editButtonFocussed={this.state.editButtonFocussed}
                isActive={isActive}
                isLoading={this.props.profiles.isLoading}
                isParentTvFocus={data.isTvFocus || this.state.addButtonFocussed || this.state.editButtonFocussed}
                logo={dstvLogo}
                onAddSelected={this.onAddSelected}
                onAvatarSelected={this.onAvatarSelected}
                onEditSelected={this.onEditSelected}
                shouldScroll={false}
                stopScroll={true}
                styledCarousel={styledEditorial}
                proximityLoader={this.props.proximity.proximityLoader}
            />
            {error}
        </React.Fragment>
    }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        profiles: state.profiles,
        menu: state.menu,
        common: state.common,
        proximity: state.proximity,
    }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    getProfiles: (payload) => dispatch({ type: GET_PROFILES_LIST, payload }),
    focusProfile: (move) => dispatch({ type: PROFILE_FOCUS, move }),
    focusProfileIcon: () => dispatch({ type: PROFILE_ICON_FOCUS }),
    clearProfiles: () => dispatch({ type: PROFILE_CLEAR }),
    clearProfileError: () => dispatch({ type: PROFILES_ERROR_CLEAR }),
    disableProfile: () => dispatch({ type: FLAGR_ERROR }),
    selectProfile: (payload) => dispatch({ type: SELECT_PROFILE, payload: payload }),
    throwNetworkError: (payload) => dispatch({ type: GET_PROFILES_LIST_ERROR, payload }),
    exitApplication: () => dispatch({ type: EXIT_APP_SUCCESS }),
    setOnboardingSuccess: () => dispatch({ type: PROXIMITY_SET_NOW_PRIMARY_SUCCESS })

})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);