import { applyMiddleware, createStore } from 'redux'
import reducer from '@connected-video-web/dstv-frontend-services/dist/reducers';
import { rootEpic } from '@connected-video-web/dstv-frontend-services/dist/epics';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware } from 'redux-observable';


import { SpatialNavigationMiddleware, ReportMiddleware } from '@connected-video-web/dstv-frontend-middleware/dist';
import { routerMiddleware } from 'react-router-redux';
import { createHashHistory as createHistory } from 'history';

export const history = createHistory();
const myRouterMiddleware = routerMiddleware(history);

const epicMiddleware = createEpicMiddleware();

var getMiddleware = () => {
    return applyMiddleware(SpatialNavigationMiddleware, myRouterMiddleware, epicMiddleware, ReportMiddleware)
};

export var store = createStore(
  reducer// temp.default
  , composeWithDevTools(getMiddleware())
)
epicMiddleware.run(rootEpic);