import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { SECTION_PROPS, SECTION_FOCUS, SCHEDULER, CHANNELS } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { SectionMenu } from '@connected-video-web/dstv-frontend-components/dist/Components/Collection';

class SubMenu extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.pageType = ''
    this.state = {
      scrollWidthSet: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( this.isSection && !this.state.scrollWidthSet) {
      this.setState({ ...this.state, scrollWidthSet: true })
    }
  }
  componentDidUpdate() {
    let arrPath = window.location[window.isSmartTv ? 'hash' : 'pathname'].split("/");
    if (this.isSection && this.state.scrollWidthSet && arrPath[2] !== 'play') {
      if ( (this.pageType === 'livetv' && this.props.sections.data_livetv &&
        this.props.sections.data_livetv.data &&
        this.props.sections.data_livetv.data[0] &&
        this.props.sections.data_livetv.data[0].items &&
        this.props.sections.data_livetv.data[0].items[0].scrollWidth === undefined) || (this.pageType === 'catchup' && this.props.sections.data_catchup &&
        this.props.sections.data_catchup.data &&
        this.props.sections.data_catchup.data[0] &&
        this.props.sections.data_catchup.data[0].items &&
        this.props.sections.data_catchup.data[0].items[0].scrollWidth === undefined)) {
        this.props.setSectionScrollWidth({
          myRef: this.myRef,
          page:this.pageType
        })
      }
    }
  }

  getMenuData = () => {
    this.isSection = false
    return []
  }

  onClick = (data) => {
    let arrPath = window.location[window.isSmartTv ? 'hash' : 'pathname'].split("/");
    let path = arrPath && arrPath.length > 1 ? arrPath[1] : ''
    this.props.sectionFocus({page: path === 'livetv' ? 'livetv' : path === 'channelgroups' ? 'channelgroups' : 'catchup', isOnClick: true, title: data.title, sections: this.props.sections })
    if (path === 'livetv') {
      let activeCategory = this.props.sections.data_livetv.data[0].items.filter(item => {
          return item.isActive;
      });
      let defaultAllCategory = this.props.sections.data_livetv.data[0].items.filter(item => {
          return item.title === 'All Channels';
      });

      if (activeCategory && activeCategory.length && (activeCategory[0].title!== 'Recently watched channels' && activeCategory[0].title!== 'All Channels') && (!this.props.channels.data_all || !this.props.channels.data_all[0].data || !this.props.channels.data_all[0].data.length ))
      this.props.getChannel({
        url: defaultAllCategory[0].url,
        sections: this.props.sections,
        filter: {
            genre: "ALL",
            sortOrder: "ALL",
            pageNo: 0,
            title: defaultAllCategory[0].title,
            filterSection: activeCategory[0].title,
            sections: this.props.sections,
            isOnClick: true,
            forceFocus: false
        },
        from: 'menu'
      }, this.props.scheduler);
    }
  }

  getactiveSectionIndex = () => {
    let index = -1
    if (this.props.sections && this.props.sections.data_channelgroups && this.props.sections.data_channelgroups.data && this.props.sections.data_channelgroups.data[0] && this.props.sections.data_channelgroups.data[0].items && this.props.sections.data_channelgroups.data[0].items.length) {
      index = this.props.sections.data_channelgroups.data[0].items.findIndex(item => item.isActive === true)
    }
    return index
  }

  getScrollWidth = (data) => {
    let ret = 0;

    if (data && data[0] && data[0].items) {
      const itm = (data && data[0] && data[0].items.filter((item, index) => {
        if (item.isActive) {
          return item.isActive;
        }
        return item.isActive;
      }).pop() || {});

      return itm.scrollWidth || 0;
    } else {
      return ret;
    }
  }
  render() {
    this.getMenuData()
    let arrPath = window.location[window.isSmartTv ? 'hash' : 'pathname'].split("/");
    let activeGroupIndex = this.getactiveSectionIndex()
    let path = arrPath && arrPath.length > 1 ? arrPath[1] : ''
    let sectionData;
    let shouldShowSection;
    if (path === 'livetv' || path === 'channelGroups' || this.props && this.props.catchUp && this.props.catchUp.data) shouldShowSection = true
    else shouldShowSection = false

    if (path === 'livetv') {
      this.pageType = 'livetv'
      sectionData = this.props && this.props.sections && this.props.sections.data_livetv && this.props.sections.data_livetv.data
      this.isSection = true
    }
    else if (this.props && this.props.catchUp && this.props.catchUp.data) {
      if (path === 'channelgroups') {
        this.pageType = 'channelgroups'
        if (this.props && this.props.sections && this.props.sections.data_channelgroups && this.props.sections.data_channelgroups.data && this.props.sections.data_channelgroups.data[0] && this.props.sections.data_channelgroups.data[0].items && this.props.sections.data_channelgroups.data[0].items.length) {
          let items = (this.props.sections.data_channelgroups.data[0].items.filter((item, index) => {
            return item.isVisible;
          }));

          sectionData = [{
            isVisible: this.props.sections.data_channelgroups.data[0].isVisible,
            items: items,
            renderType: this.props.sections.data_channelgroups.data[0].renderType
          }]
        }
        this.isSection = true
        shouldShowSection = true
      } else {
        this.pageType = 'catchup'
        this.isSection = true
        sectionData = this.props && this.props.sections && this.props.sections.data_catchup && this.props.sections.data_catchup.data
      }
    }

    return (
      <React.Fragment>
        <SectionMenu
          data={sectionData}
          onClick={this.onClick}
          shouldShowSection={shouldShowSection}
          refProps={this.myRef}
          isSection={this.isSection}
          scrollWidth={this.getScrollWidth(sectionData)}
          page = {this.pageType}
          isLogo = {this.pageType === 'channelgroups' ? true : false}
          activeGroupIndex = {activeGroupIndex}
        />
      </React.Fragment>)
  }
}
/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
  return {
    sections: state.sections,
    catchUp : state.catchUp,
    channels: state.channels,
    menu: state.menu
  }
};

/**
 * 
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => ({
  setSectionScrollWidth: (payload) => dispatch({ type: SECTION_PROPS, payload }),
  sectionFocus: (payload) => dispatch({ type: SECTION_FOCUS, payload }),
  getChannel: (payload, scheduler) => dispatch({ type: CHANNELS, payload, scheduler }),
  scheduler: (payload) => dispatch({ type: SCHEDULER, payload }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubMenu));