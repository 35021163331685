export const GLOBALS = {
    TIME_TO_SHOW_IDLE_MESSAGE: 14400,
    ACTIVATE_TIMER: 3000,
    DEVICE_ID_STORAGE_KEY: 'DEVICE_ID_STORAGE_KEY',
    DEVICE_ID: '',
    SCROLL_DELAY: 100,
    VOD_AUTO_HIDE_DURATION: 8000,
    LIVE_AUTO_HIDE_DURATION: 10000,
    SCRUB_SPEED_JUMP_NUMBER: 30,
    SCRUB_SPEED_LIMIT: 3,
    SEEK_LENGTH: 10,
    FAST_FORWARD_LENGTH: 15,
    REWIND_LENGTH: 15,
    STOP_LIMIT: 4,
    PLAY_DURATION_TIMER: 1000,
    PLAY_SEEK_TIMER: 1000,
    CONCURRENCY_TIMER: 20000,
    GET_PROGRESS_TIMER: 1000,
    GET_CHANNELS_RETRY_TIMER: 1000,
    UPDATE_PROGRESS_BAR_INTERVAL: 1000 * 60,
    RB_LB_SWITCH_DURATION: 1000,
    COUNTER_TIMER: 30,
    SCREEN_INACTIVE_INTERVAL: 3600000, // 1 hourin seconds
    SCREEN_INACTIVE_LIMIT: 8,
    SCREEN_INACTIVE_MAIN_LABEL: "Are you still watching :",
    SCREEN_INACTIVE_BUTTON_LABEL: "Yes, Continue",
    SCREEN_TIMEOUT_INTERVAL: 1000,
    CHANNEL_REFRESH_EVENT_TIMER: 10000
}