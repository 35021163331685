import React, { Component } from "react";
import {
  ENTITLEMENT,
  GET_KEYBOARD,
  LOGIN,
  REMOVE_KEYBOARD_FOCUS,
  KEYBOARD_TOGGLE_OK,
  SET_KEYBOARD_FOCUS,
  SMART_TV_APP_VERSION,
  SWITCH_KEYBOARD,
  UPDATE_ONBOARDING_VALUE,
  SET_CURRENT_STEP,
  SET_ACTIVE_CONTENT,
  RESET_KEYBOARD,
  SET_ERROR_MESSAGE,
  CLEAR_ERROR_MESSAGE,
  EMAIL_SIGN_UP_ERROR,
  SET_TERMS_AND_CONDITIONS,
  SET_CURRENT_STEP_AND_CONTENT,
  GET_STREAMA_PACKAGES,
  GET_SUBSCRIPTION_STATUS,
  SHOW_POLLING_TIMEOUT_MESSAGE,
  CLEAR_PACKAGE,
  START_POLLING_SUBSCRIPTION,
  STOP_POLLING_SUBSCRIPTION
} from "@connected-video-web/dstv-frontend-services/dist/actions";
import { STREAMA_INVALID_EMAIL_ERROR, STREAMA_INVALID_PASSWORD_ERROR, SIGNUP_SUCCESSFUL,
  SIGNUP_ACCOUNT_ALREADY_EXIST, SIGNUP_ERROR, SIGNUP_PASSWORD, SIGNUP_EMAIL, SIGNIN, EMAIL,
  PASSWORD, SIGNIN_SUCCESSFUL, WELCOME, WATCH_OPTIONS, ACCOUNT_ALREADY_EXIST,
  STREAMA_AUNTENTICATION_ERROR_MAPPING, DEFAULT_ERROR_MESSAGE, ACCOUNT_NOT_FOUND, FORGOT_PASSWORD,
  EMAIL_PASSWORD_RESET_ERROR, TEN_MINUTES, THIRTY_SECONDS } from "@connected-video-web/dstv-frontend-services/dist/constants/constants";
import { GLOBALS } from "../../globals";
import { connect } from "react-redux";
import Pusher from "../../libs/pusher";
import { TvOnboarding } from "@connected-video-web/dstv-frontend-components/dist/Components/Pages";
import { TvError } from "@connected-video-web/dstv-frontend-components/dist/Components/Pages";
import {
  ENV,
  PUSHER_KEY,
} from "@connected-video-web/dstv-frontend-services/dist/constants/environment";
import { internetIsConnected } from "@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper";
import { isEmailValid, isPasswordValid } from "@connected-video-web/dstv-frontend-services/dist/Utils/validations";
import { setTokenAndJWT } from "@connected-video-web/dstv-frontend-services/dist/Utils/authHelper";
import { checkNested, getNested } from "@connected-video-web/dstv-frontend-services/dist/Utils/objHelper"
import SessionHelper from "@connected-video-web/dstv-frontend-services/dist/Utils/sessionHelper";
import { EMAIL_SIGN_UP, EMAIL_SIGN_IN, EMAIL_PASSWORD_RESET, HIDE_ONBOARDING_LOADING } from "@connected-video-web/dstv-frontend-services/dist/actions";
import { retriveFromLocalStorage } from "@connected-video-web/dstv-frontend-services/dist/Utils/storageHelpers";
import { postSegmentData, postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';

var ANDROID_JAVASCRIPT_INTERFACE = "android_javascript_interface";
class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.payload = {
      deviceId: localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY),
      appVersion: SMART_TV_APP_VERSION,
      userAgent: navigator.userAgent,
    };
    this.state = {
      background: false,
      showActivation: false,
      code: "",
      isSubscribed:false
    };
  }
  loginSuccess = (data) => {
    setTokenAndJWT(data);
    this.props.history.push("/");
    this.props.connectLogin();
  };
  loginError = (data) => {
    if (this.pusher) {
      this.pusher.unsubscribe(
        localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY)
      );
      this.pusher.disconnect();
    }
  };

  startSocket = () => {
    var self = this;
    Pusher.logToConsole = false;

    var pusher = new Pusher(PUSHER_KEY, {
      cluster: "eu",
      encrypted: true,
    });
    this.channel = pusher.subscribe(
      localStorage.getItem(GLOBALS.DEVICE_ID_STORAGE_KEY)
    );
    this.channel.bind("login-success", function (data) {
      self.loginSuccess(data);
    });
    this.channel.bind("login-error", function (data) {
      self.loginError(data);
    });

    this.channel.bind("login-update-usercode", function (data) {
      console.log("login-update-usercode: " + data.message);
    });

    pusher.connection.bind("unavailable", function () {
      console.log("pusher unavailable");
    });

    pusher.connection.bind("connected", function (data) {
      console.log("pusher connected: " + data.message);
    });

    pusher.connection.bind("error", function (err) {
      if (err.error.data) {
        console.error("Error: " + err.error.data.message);

        // var callback = function () {
        //   self.pusher.connect()
        // }
        return (
          <TvError
            {...this.props.onboarding.error}
            retryAction={{ type: LOGIN }}
            onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]}
          />
        );
      }
    });
    this.pusher = pusher;
  };

  componentDidMount() {
    this.props.getKeyboard();
    this.props.setCurrentStep(WELCOME);
    setTimeout(() => {
      this.getActivationCode();
      this.setState({ ...self.state, showActivation: true });
    }, GLOBALS.ACTIVATE_TIMER);
    this.startSocket();
  }

  onErrorActionSelected = (e) => {
    this.componentDidMount();
  };

  onErrorBackSelected = (e) => {
    this.props.clearError();
  };

  componentWillUnmount() {
    if (this.pusher) {
      this.pusher.disconnect();
    }
    this.clearTimers();
    this.props.removeKeyboardFocus();
  }

  setTargetStepOrContent = () => {
    let targetContent = getNested(
      this.props.onboarding,
      "activeContent",
      "targetContent"
    )
      ? getNested(this.props.onboarding, "activeContent", "targetContent")
      : getNested(this.props.onboarding, "data", "0", "targetContent");

    let targetStep = getNested(
      this.props.onboarding,
      "activeContent",
      "targetStep"
    )
      ? getNested(this.props.onboarding, "activeContent", "targetStep")
      : getNested(this.props.onboarding, "data", "0", "targetStep");

      const isUserLoggedIn = !!(
        this.props.login && this.props.login.streamaLoginData &&
         ((this.props.login.streamaLoginData.action === SIGNIN_SUCCESSFUL) ||
          this.props.login.streamaLoginData.action === SIGNUP_SUCCESSFUL));

    if (
      targetContent &&
      ((!isUserLoggedIn && targetStep !== WATCH_OPTIONS) ||
        (isUserLoggedIn && targetStep === WATCH_OPTIONS))
    ) {
      this.props.setActiveContent(targetContent);
    } else if (
      targetStep &&
      ((!isUserLoggedIn && targetStep !== WATCH_OPTIONS) ||
        (isUserLoggedIn && targetStep === WATCH_OPTIONS))
    ) {
      this.props.setCurrentStep(targetStep);
    }
  };

  streamaLoginSuccess = () => {
    this.loginSuccess(this.props.login.streamaLoginData);
    this.setTargetStepOrContent();
  };

  streamaUserAlreadyExists = () => {
    this.props.setCurrentStep(ACCOUNT_ALREADY_EXIST);
  };

  startPollingSubscription = () => {
    this.clearTimers();
    this.timeOutTimer = setTimeout(() => this.subscriptionStatusTimeOut(), TEN_MINUTES);
    this.pollingTimer = setInterval(
      () =>
        this.props.getSubscriptionStatus({
          email: this.props.onboarding.email,
          password: this.props.onboarding.password,
        }),
      THIRTY_SECONDS
    );
  }

  clearTimers = () => {
    this.pollingTimer && clearInterval(this.pollingTimer);
    this.pollingTimer = null;
    this.timeOutTimer && clearTimeout(this.timeOutTimer);
    this.timeOutTimer = null;
  }

  stopPollingSubscription = () => {
    this.clearTimers();
    this.props.stopPollingSubscription();
  };

  subscriptionStatusTimeOut = () => {
    this.stopPollingSubscription();
    this.props.showPollingTimeOutMessage();
  }

  passwordResetError = () => {
    this.props.setCurrentStep(ACCOUNT_NOT_FOUND);
  };

  getPageTitle = (currentStep) => {
    let pageTitle;
    if (currentStep === SIGNIN) {
      pageTitle = "Sign In";
    } else if (currentStep === ACCOUNT_ALREADY_EXIST) {
      pageTitle = "Account already";
    } else if (currentStep === SIGNUP_EMAIL || currentStep === SIGNUP_PASSWORD) {
      pageTitle = "Sign Up";
    }
    return pageTitle;
  }

  handleSignInAnalytics = () => {
    const { currentStep, email, password } = this.props.onboarding;
    let pageTitle = this.getPageTitle(currentStep);
    let buttonText;
    let focusedItem;

    if (currentStep === SIGNIN) {
      focusedItem =
      this.props.onboarding.data &&
      this.props.onboarding.data.length &&
      this.props.onboarding.data[0].options.length &&
      this.props.onboarding.data[0].options.find((item) => item.isTvFocus);
    }

    if (currentStep === SIGNIN && focusedItem && focusedItem.title) {

      if(currentStep === SIGNIN && !this.singInSelected) {
        this.singInSelected = true;
        let buttonText = 'Sign In'
        postSegmentData(
          buttonText,
          { eventCategory: "STREAMA" },
          pageTitle
        );
      }

      if (!this.isSignInOnMobileSelected || !this.isSignInTVSelected) {

        buttonText = focusedItem && focusedItem.title;

        postSegmentData(
          buttonText,
          { eventCategory: "STREAMA" },
          pageTitle
        );

        if (!this.isSignInTVSelected && buttonText === "Sign in on TV") {
          this.isSignInTVSelected = true;
        } else if (
          !this.isSignInOnMobileSelected &&
          buttonText === "Sign in on mobile"
        ) {
          this.isSignInOnMobileSelected = true;
        }
      }
    }

    if(currentStep === SIGNUP_EMAIL && !this.singUpSelected) {
      this.singUpSelected = true;
      let buttonText = 'Sign Up'
      let pageTitle = 'Sign Up'
      postSegmentData(
        buttonText,
        { eventCategory: "STREAMA" },
        pageTitle
      );
    }

    if (
      (currentStep === SIGNIN ||
        currentStep === SIGNUP_EMAIL ||
        currentStep === SIGNUP_PASSWORD ||
        currentStep === ACCOUNT_ALREADY_EXIST) &&
      this.props.keyboard.isOKClicked
    ) {
      if (isEmailValid(email) && !password) {
        buttonText = "Email OK";
      } else if (isEmailValid(email) && isPasswordValid(password)) {
        buttonText = "Password OK";
      }
      if (!!buttonText)
      postSegmentData(
        buttonText,
        { eventCategory: "STREAMA" },
        pageTitle
      );
    }
  };
  
  handleForgetPwdAnalytics = () => {
    let buttonText = 'Forgot password'
    let pageTitle = this.props.login.streamaLoginData && this.props.login.streamaLoginData.action === "SIGNUP_ACCOUNT_ALREADY_EXIST" ? 'Account already exists' : "Sign In"
    postSegmentData(
      buttonText,
      { eventCategory: "STREAMA" },
      pageTitle
    );
  }

  handleAccExistsAnalytics = () => {
    let buttonText = 'Not your account'
    let pageTitle = 'Account already exists'
    postSegmentData(
      buttonText,
      { eventCategory: "STREAMA" },
      pageTitle
    );
  }

  handleAccLinkingAnalytics = () => {
    let buttonText;
    let pageTitle = "Account Linking";
    if (this.props.packages && this.props.packages.activePackage) {
      buttonText = this.props.packages.activePackage.moreInfoUrl;
      postSegmentData(
        buttonText,
        { eventCategory: "STREAMA_ONBOARDING" },
        pageTitle
      );
    } else if (
      !this.props.packages.activePackage &&
      this.props.packages.seeMorePackages === true
    ) {
      let buttonText = "See more";
      let pageTitle = "Account Linking";
      postSegmentData(
        buttonText,
        { eventCategory: "STREAMA_ONBOARDING" },
        pageTitle
      );
    } else if (this.props.onboarding.activeContent && this.props.onboarding.activeContent.type) {
      let buttonText = this.props.onboarding.activeContent.type === "have_dstv" ? "have_dstv" : this.props.onboarding.activeContent.type === "blank" ? "Skip for now" : "";
      let pageTitle = "Account Linking";
      postSegmentData(
        buttonText,
        { eventCategory: "STREAMA_ONBOARDING" },
        pageTitle
      );
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    const { currentStep, activeContent } = this.props.onboarding;
    if (
      currentStep === SIGNIN ||
      currentStep === SIGNUP_EMAIL ||
      currentStep === SIGNUP_PASSWORD ||
      currentStep === ACCOUNT_ALREADY_EXIST
    ) {
      this.handleSignInAnalytics();
    }

    if (
      this.props.login &&
      this.props.login.streamaLoginData &&
      this.props.login.streamaLoginData.action ===
        SIGNUP_ACCOUNT_ALREADY_EXIST &&
      this.props.onboarding.email &&
      currentStep === WELCOME
    ) {
      this.handleAccExistsAnalytics();
    } else if (
      currentStep === WATCH_OPTIONS &&
      this.props.packages
    ) {
      this.handleAccLinkingAnalytics();
    }

    if ((currentStep !== SIGNIN || currentStep !== SIGNUP_EMAIL) && prevProps.onboarding.currentStep === WELCOME && !!prevProps.keyboard.value) {
      this.props.resetKeyboard();
      this.props.clearErrorMessage();
    } else if (!!prevProps.keyboard.value && currentStep === SIGNUP_EMAIL && !!prevProps.onboarding.password) {
      this.props.resetKeyboard();
    }

    if (prevProps.login !== this.props.login) {
      const userCode =
        this.props &&
        this.props.login &&
        this.props.login.data &&
        this.props.login.data.length > 0 &&
        this.props.login.data[0].userCode;

      if (userCode) {
        this.setState({
          qrCodeUrl: ENV.QR_CODE_REDIRECT_URL.replace("{USER_CODE}", userCode),
        });
      }
    }

    if (
      this.state.isSubscribed &&
      this.props.subscription &&
      this.props.subscription.data &&
      this.props.subscription.data.linkStatus
    ) {
      this.props.setSubscriptionStatus(this.props.subscription.data.linkStatus);
    }

    if (
      prevProps.login.streamaLoginData !== this.props.login.streamaLoginData &&
      !!this.props.login.streamaLoginData &&
      this.props.login.streamaLoginData.action
    ) {
      if (this.props.login.streamaLoginData.action === SIGNIN_SUCCESSFUL) {
        this.streamaLoginSuccess();
      } else if (
        this.props.login.streamaLoginData.action === SIGNUP_SUCCESSFUL
      ) {
        this.isSignupFlow = true;

        this.props.getSubscriptionStatus({
          email: this.props.onboarding.email,
          password: this.props.onboarding.password,
        })
      } else if (
        this.props.login.streamaLoginData.action ===
        SIGNUP_ACCOUNT_ALREADY_EXIST
      ) {
        this.props.hideLoading();
        this.streamaUserAlreadyExists();
      }
    }

    if (
      prevProps.login.streamaLoginError !==
        this.props.login.streamaLoginError &&
      !!this.props.login.streamaLoginError
    ) {
      if (this.props.onboarding.currentStep === SIGNIN) {
        this.props.setTargetStepAndContent({ step: SIGNIN, content: 0 });
      } else if (this.props.onboarding.currentStep === SIGNUP_PASSWORD) {
        this.props.setTargetStep(SIGNUP_EMAIL);
      } else if (this.props.onboarding.currentStep === WATCH_OPTIONS) {
        this.props.setTargetStepAndContent({ step: SIGNIN, content: 0 });
      }
      this.setStremaError(this.props.login.streamaLoginError.action);
    }

    if(this.props.onboarding.currentStep === WATCH_OPTIONS){
      this.checkPollingStatus(prevProps);
    }

    if (!prevProps.onboarding.shouldClearPackage && this.props.onboarding.shouldClearPackage) {
      if (this.props.packages.activePackage) {
        this.props.clearPackage();
      }
    }

    //Call a function when the current step changes
    if (prevProps.onboarding.currentStep !== this.props.onboarding.currentStep) {
      this.onStepChanged();
    }

    //Update the current step state value when the keyboard value changes
    if (prevProps.keyboard.value !== this.props.keyboard.value) {
      this.props.updateValue(this.props.keyboard.value);
    }

    //Remove keyboard focus when focus moves to other elements on the page
    if (this.props.keyboard.hasFocus && prevProps.keyboard.hasFocus) {
      let isOptionFocused, isPasswordShowFocused, isForgotPasswordFocused;

      if (checkNested(this.props, "onboarding", "data", "0", "options")) {
        isOptionFocused = this.props.onboarding.data[0].options
          .filter((item) => item.isTvFocus)
          .pop();
      }
      isPasswordShowFocused =
        getNested(
          this.props.onboarding,
          "activeContent",
          "password_show_btn",
          "isTvFocus"
        ) ||
        getNested(
          this.props.onboarding,
          "data",
          "0",
          "password_show_btn",
          "isTvFocus"
        );
      isForgotPasswordFocused = getNested(
        this.props.onboarding,
        "activeContent",
        "forgot_password_btn",
        "isTvFocus"
      );

      if (
        isOptionFocused ||
        isPasswordShowFocused ||
        isForgotPasswordFocused ||
        this.props.onboarding.isLoading
      ) {
        this.props.removeKeyboardFocus();
        this.props.clearErrorMessage();
        if (currentStep !== SIGNUP_PASSWORD && currentStep !== SIGNIN && currentStep !== ACCOUNT_ALREADY_EXIST) {
          this.props.resetKeyboard();
        }
      }
    }

    //Set focus to keyboard if it's the only option
    if (prevProps.onboarding.data[0] !== this.props.onboarding.data[0]) {
      if (
        this.props.onboarding.data[0].type === "keyboard" &&
        this.props.onboarding.data[0].options === undefined
      ) {
        this.props.setKeyboardFocus();
      }
    }

    //Check for keyboard OK button
    if (prevProps.keyboard.isOKClicked !== this.props.keyboard.isOKClicked) {
      const isValidEmail = isEmailValid(this.props.onboarding.email);
      const isValidPassword = this.props.onboarding.currentStep === ACCOUNT_ALREADY_EXIST ? isPasswordValid(this.props.keyboard.value) : isPasswordValid(this.props.onboarding.password);

      const { currentStep, activeContent } = this.props.onboarding;

      if (this.props.keyboard.isOKClicked) {
        if (
          (currentStep === SIGNUP_EMAIL && isValidEmail) ||
          (currentStep === SIGNUP_PASSWORD && isValidPassword)
        ) {
          this.props.clearErrorMessage();
          this.onKeyboardOK();
        } else if (
          currentStep === SIGNIN &&
          ((activeContent && activeContent.value === EMAIL && isValidEmail) ||
            (activeContent &&
              activeContent.value === PASSWORD &&
              isValidPassword))
        ) {
          this.props.clearErrorMessage();
          this.onKeyboardOK();
        } else if (currentStep === ACCOUNT_ALREADY_EXIST && (activeContent && activeContent.value === PASSWORD) && isValidPassword) {
          this.props.clearErrorMessage();
          this.onKeyboardOK();
        } else {
          this.props.keyboardToggleOK(false);

          const errorMessage =
            (!isValidEmail && STREAMA_INVALID_EMAIL_ERROR) ||
            (!isValidPassword && STREAMA_INVALID_PASSWORD_ERROR);

          this.props.setErrorMessage(errorMessage);
          postAPIErrorToSegment(
            {
              mainHeader: errorMessage,
              subHeader: '',
            },
            { errorMessage: 'Sign In/Sign Up error' }
          );
        }
      }
    }

    if (prevProps.subscription !== this.props.subscription) {
      if (this.isSignupFlow) {
        if (
          !this.isUserSubscribed &&
          this.props.subscription &&
          this.props.subscription.data &&
          this.props.subscription.data.linkStatus === true
        ) {
          this.props.hideLoading();
          this.isUserSubscribed = true;
          //setTokenAndJWT(this.props.subscription.data);
          this.loginSuccess(this.props.subscription.data);
          //this.setState({ isSubscribed: true });
          this.props.setTerms();

          this.props.setSubscriptionStatus("SUBSCRIBED");
        } else if (
          !this.isUserNotSubscribed &&
          this.props.subscription &&
          this.props.subscription.data &&
          this.props.subscription.data.linkStatus === false
        ) {
          this.isUserNotSubscribed = true;
          this.props.hideLoading();

          this.props.getPackageDetails();
          this.streamaLoginSuccess();
          this.props.setTerms();
        }
      }
      if (
        this.isUserNotSubscribed &&
        this.props.subscription &&
        this.props.subscription.data &&
        this.props.subscription.data.linkStatus === true
      ) {
        setTokenAndJWT(this.props.subscription.data);
        this.stopPollingSubscription();
        this.setState({ isSubscribed: true });
      } else if (
        this.isUserNotSubscribed &&
        this.props.subscription.skipped === true
      ) {
        this.props.setSubscriptionStatus("SKIPPED_SUBSCRIPTION");
      }
    }

    if (
      prevProps.login !== this.props.login &&
      this.props.login.streamaStepStatus === EMAIL_SIGN_UP_ERROR
    ) {
      this.props.setKeyboardFocus();
    }
    if (
      prevProps.login.streamaPasswordError &&
      prevProps.login.streamaPasswordError.status === 404 &&
      prevProps.login.streamaStepStatus === EMAIL_PASSWORD_RESET_ERROR &&
      currentStep === "forgot_password"
    ) {
      this.passwordResetError();
    }
  }

  setStremaError = (action) => {
    const errorMessage =
      STREAMA_AUNTENTICATION_ERROR_MAPPING[action] || DEFAULT_ERROR_MESSAGE;
    postAPIErrorToSegment(
      {
        mainHeader: errorMessage,
        subHeader: '',
      },
      { errorMessage: 'Sign In/Sign Up error' }
    );
    this.props.setErrorMessage(errorMessage);
  };

  checkPollingStatus = (prevProps) => {
    if(!this.props.onboarding.error){
      if (!prevProps.onboarding.shouldPollSubscription && this.props.onboarding.shouldPollSubscription) {
        this.startPollingSubscription();
      } else if (prevProps.onboarding.shouldPollSubscription && !this.props.onboarding.shouldPollSubscription) {
        this.clearTimers();
      }
    }

    if(!this.props.packages.activePackage && !this.props.packages.seeMorePackages){
      let currentType = getNested(this.props, "onboarding", "activeContent", "type");

      if(currentType === "have_dstv" && !this.timeOutTimer){
        this.startPollingSubscription();
      } else if (currentType !== "have_dstv" && this.timeOutTimer){
        this.clearTimers();
      }
    }
  }

  onStepChanged = () => {
    //Call APIs here!
    switch (this.props.onboarding.currentStep) {
      case WELCOME: {
        console.log("Welcome!");
        break;
      }
      case FORGOT_PASSWORD: {
        this.handleForgetPwdAnalytics();
        let userDetails = {
          email: this.props.onboarding.email,
        };
        this.props.passwordReset(userDetails);
        break;
      }
      case ACCOUNT_NOT_FOUND: {
        this.props.setKeyboardFocus();
      }
      default:
    }
  };

  onKeyboardOK = () => {
    this.setTargetStepOrContent();
    this.props.resetKeyboard();
    this.props.keyboardToggleOK(false);

    //Set focus to keyboard for Password step
    if (
      getNested(this.props, "onboarding", "activeContent", "type") ===
      "keyboard"
    ) {
      this.props.setKeyboardFocus();
    }

    const { email, password, currentStep, activeContent } =
      this.props.onboarding;

    if (email && password) {
      if (currentStep === SIGNUP_PASSWORD) {
        this.props.signup({
          email,
          password,
        });
      } else if (
        currentStep === SIGNIN &&
        activeContent &&
        activeContent.valueType === PASSWORD
      ) {
        this.props.signin({
          email,
          password,
        });
      } else if (currentStep === ACCOUNT_ALREADY_EXIST && !!this.props.keyboard.value) {
        let password = this.props.keyboard.value;
        this.props.signin({
          email,
          password,
        });
      }
    }
  };

  getActivationCode = () => {
    if (internetIsConnected()) {
      this.props.getLoginCode(this.payload);
    } else {
      this.props.throwNetworkError(
        networkPayload({
          callback: () => {
            this.getActivationCode();
          },
          toggleText: this.props.login.isForgotPin ? false : true,
        })
      );
    }
  };

  render() {
    if (this.props.onboarding.errorMessage && this.props.onboarding.errorMessage.mainHeader) {
      return <TvError {...this.props.onboarding.error} />
    } else {
      return this.state.qrCodeUrl &&
        this.props.onboarding &&
        this.props.onboarding.data &&
        this.props.onboarding.data.length > 0 &&
        this.props.onboarding.data[0] ? (
        <React.Fragment>
          <TvOnboarding
            activeContent={this.props.onboarding.activeContent}
            currentStep={this.props.onboarding.currentStep}
            data={this.props.onboarding.data[0]}
            onboarding={this.props.onboarding}
            keyboard={this.props.keyboard}
            isLoading={this.props.onboarding.isLoading || this.props.login.isLoadingLogin || false}
            packages={this.props.packages.data[0].items}
            activePackage={this.props.packages.activePackage}
            seeMorePackages={this.props.packages.seeMorePackages}
            qrCodeUrl={this.state.qrCodeUrl}
            errorMessage={this.props.onboarding.errorMessage}
          />
        </React.Fragment>
      ) : null
    }
  }
}
/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    onboarding: state.onboarding,
    login: state.login,
    keyboard: state.keyboard,
    packages: state.packages,
    subscription: state.subscription
  };
};

/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  clearError: () => dispatch({ type: "LOGIN_ERROR_CLEAR" }),
  connectLogin: (payload) => dispatch({ type: "CONNECT", payload }),
  DoEntitlement: (payload) => dispatch({ type: ENTITLEMENT, payload }),
  getKeyboard: () => dispatch({ type: GET_KEYBOARD }),
  getLoginCode: (payload) => dispatch({ type: LOGIN, payload }),
  keyboardToggleOK: (payload) => dispatch({ type: KEYBOARD_TOGGLE_OK, payload }),
  removeKeyboardFocus: () => dispatch({ type: REMOVE_KEYBOARD_FOCUS }),
  setActiveContent: (payload) => dispatch({ type: SET_ACTIVE_CONTENT, payload }),
  setCurrentStep: (payload) => dispatch({ type: SET_CURRENT_STEP, payload }),
  setKeyboardFocus: () => dispatch({ type: SET_KEYBOARD_FOCUS }),
  switchKeyboard: (payload, isClicked) => dispatch({ type: SWITCH_KEYBOARD, payload, isClicked }),
  throwNetworkError: (payload) => dispatch({ type: "LOGIN_ERROR", payload }),
  updateValue: (payload) => dispatch({ type: UPDATE_ONBOARDING_VALUE, payload }),
  signup: (payload) => dispatch({ type: EMAIL_SIGN_UP, payload }),
  signin: (payload) => dispatch({ type: EMAIL_SIGN_IN, payload }),
  passwordReset: (payload) => dispatch({ type: EMAIL_PASSWORD_RESET, payload }),
  resetKeyboard: () => dispatch({ type: RESET_KEYBOARD }),
  setErrorMessage: (payload) => dispatch({ type: SET_ERROR_MESSAGE, payload }),
  clearErrorMessage: () => dispatch({ type: CLEAR_ERROR_MESSAGE }),
  setTerms: () => dispatch({ type: SET_TERMS_AND_CONDITIONS }),
  setTargetStepAndContent: (payload) => dispatch({ type: SET_CURRENT_STEP_AND_CONTENT, payload }),
  setTargetStep: (payload) => dispatch({ type: SET_CURRENT_STEP, payload }),
  getPackageDetails: () => dispatch({ type: GET_STREAMA_PACKAGES }),
  getSubscriptionStatus: (payload) => dispatch({ type: GET_SUBSCRIPTION_STATUS, payload }),
  showPollingTimeOutMessage: () => dispatch({ type: SHOW_POLLING_TIMEOUT_MESSAGE }),
  clearPackage: () => dispatch({ type: CLEAR_PACKAGE }),
  startPollingSubscription: () => dispatch({ type: START_POLLING_SUBSCRIPTION }),
  stopPollingSubscription: () => dispatch({ type: STOP_POLLING_SUBSCRIPTION }),
  hideLoading: () => dispatch({type: HIDE_ONBOARDING_LOADING})
});

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
